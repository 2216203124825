import {text} from "../../../config/i18n/text";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {getForm, setFormBlur, setFormFocus, setFormValue} from "../../../redusers/CheckoutReducer";
import fields from "../../models/checkout/BillingFormModel";
import buildForm, {makeFieldBlur, makeFieldFocus, makeValueChanged} from "../../../helpers/form/FormBuild";

export const BillingFormBlock = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const form = useAppSelector(getForm);

    const valueChanged = makeValueChanged(dispatch, setFormValue);

    const fieldFocus = makeFieldFocus(dispatch, setFormFocus);

    const fieldBlur = makeFieldBlur(dispatch, setFormBlur, lang, form);

    const displayForm = buildForm(fields, form, lang, valueChanged, fieldFocus, fieldBlur);


    return <>
        <h6 className="mb-7">{text[lang].checkout.form.billing_details}</h6>
        <div className="row mb-9">
            {displayForm}
        </div>
    </>;
}

export default BillingFormBlock;