import AccountMenu from "../../parts/account/AccountMenu";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import Footer from "../../parts/Footer";
import {text} from "../../../config/i18n/text";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getInitServer, getInitUser, getLang, getServer} from "../../../redusers/InterfaceReducer";
import {getUserToken} from "../../../redusers/UserReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import FullPageLoader from "../../parts/FulPageLoader";
import {getOrderList, getPage, loadList, setOrderPage} from "../../../redusers/OrderReducer";
import OrderListItem from "../../parts/account/orders/OrderListItem";
import Pager from "../../parts/Pager";

export const orderListPageSize = 10;

export const PersonalOrdersPage = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);

    const initServer = useAppSelector(getInitServer);
    const initUser = useAppSelector(getInitUser);
    const orderList = useAppSelector(getOrderList);
    const page = useAppSelector(getPage);

    const [state, setState] = useState(NetworkStatus.loading);

    useEffect(() => {
        if (true === initServer && true === initUser) {

            MakeRequest(getUrl(apiRoutes.ordersList, server), 'get', {}, token, (response) => {
                dispatch(loadList(response.data.list));
                setState(NetworkStatus.idle);

            }, (e) => {
                if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                    setState(NetworkStatus.idle);
                } else {
                    console.error('orders - response error is', e);
                    setState(NetworkStatus.fail);
                }
            });
        }

    }, [initServer, initUser]);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [state]);

    if(state === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Header />
            <FullPageLoader />
            <Footer />
        </>;
    }

    const maxPage = Math.ceil(orderList.length/orderListPageSize);

    const itemsFrom = state === NetworkStatus.idle ? orderListPageSize * (page -1) : 0;
    const itemsTo = state === NetworkStatus.idle ? Math.min(orderListPageSize * page, orderList.length ) : 0;

    const orders = Array.isArray(orderList) ? orderList.slice(itemsFrom, itemsTo).map((item, index) => {
        return <OrderListItem order={item} key={'order-list-item-' + index} />;
    }) : [<h1>No Orders, sry...</h1>];

    const changePage = (page) => {
        return setOrderPage(page);
    }


    return <>
        <Helmet>
            <title>{text[lang].seo.account.orders.title}</title>
            <meta name="description" content={text[lang].seo.account.orders.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header />
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.order_list.title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={0} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        {orders}

                        <Pager page={page} max={maxPage} click={changePage} />
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>;
}

export default PersonalOrdersPage;