import {createBrowserRouter} from "react-router-dom";
import * as routerPath from "./routes";
import ErrorPage from "../components/screens/ErrorPage";
import LoginPage from "../components/screens/LoginPage";
import RegistrationPage from "../components/screens/RegistrationPage";
import MainPage from "../components/screens/MainPage";
import React from "react";
import LogoutPage from "../components/screens/LogoutPage";
import SellPage from "../components/screens/SellPage";
import SellPageDone from "../components/screens/SellPageDone";
import TestPage from "../components/screens/TestPage";
import Category2Page from "../components/screens/Category2Page";
import ProductPage from "../components/screens/ProductPage";
import PersonalInfoPage from "../components/screens/account/PersonalInfoPage";
import PersonalOrdersPage from "../components/screens/account/PersonalOrdersPage";
import PersonalWishlistPage from "../components/screens/account/PersonalWishlistPage";
import PersonalAddressesPage from "../components/screens/account/PersonalAddressesPage";
import PersonalOrderPage from "../components/screens/account/PersonalOrderPage";
import CheckoutPage from "../components/screens/CheckoutPage";
import ArticlePage from "../components/screens/ArticlePage";
import PersonalAddressPage from "../components/screens/account/PersonalAddressPage";
import PersonalAddressCreatePage from "../components/screens/account/PersonalAddressCreatePage";

export const router = createBrowserRouter(
[
        {
            path: routerPath.home,
            element: <MainPage />,
            errorElement: <ErrorPage />,
        },
        {
            path: routerPath.main,
            element: <MainPage />,
        },
        {
            path: routerPath.login,
            element: <LoginPage />,
        },
        {
            path: routerPath.registration,
            element: <RegistrationPage />,
        },
        {
            path: routerPath.logout,
            element: <LogoutPage />,
        },
        {
            path: routerPath.sell,
            element: <SellPage />,
        },
        {
            path: routerPath.category,
            element: <Category2Page />,
        },
        {
            path: routerPath.item,
            element: <ProductPage />,
        },
        {
            path: routerPath.sellDone,
            element: <SellPageDone />,
        },
        {
            path: routerPath.test,
            element: <TestPage />,
        },
        {
            path: routerPath.accountInfo,
            element: <PersonalInfoPage />,
        },
        {
            path: routerPath.accountOrders,
            element: <PersonalOrdersPage />,
        },
        {
            path: routerPath.accountWishlist,
            element: <PersonalWishlistPage />,
        },
        {
            path: routerPath.accountAddresses,
            element: <PersonalAddressesPage />,
        },
        {
            path: routerPath.accountAddress,
            element: <PersonalAddressPage />,
        },
        {
            path: routerPath.accountAddressesCreate,
            element: <PersonalAddressCreatePage />,
        },
        {
            path: routerPath.accountOrder,
            element: <PersonalOrderPage />,
        },
        {
            path: routerPath.checkout,
            element: <CheckoutPage />,
        },
        {
            path: routerPath.article,
            element: <ArticlePage />,
        },
    ]
);