import {createSlice} from '@reduxjs/toolkit';
import {NetworkStatus} from "../helpers/network/NetworkStatus";


const initialState = {
    info: {},
    reviews: {},
    reviewsTotal: 0,
    reviewsCount: 5,
    reviewsOffset: 5,
    score: 0,
    yourScore: null,
    placed: false,
    productStatus: NetworkStatus.loading
};

export const userSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setStatus: (state, payload) => {
            state.productStatus = payload.payload;
        },
        setProduct: (state, payload) => {
            state.productStatus = NetworkStatus.idle;
            state.reviews = payload?.payload?.reviews?.list ?? [];
            state.reviewsTotal = payload?.payload?.reviews?.total ?? 0;
            state.reviewsCount = payload?.payload?.reviews?.count ?? 0;
            state.reviewsOffset = payload?.payload?.reviews?.offset ?? 0;
            state.yourScore = payload?.payload?.reviews?.yourScore;
            state.score = payload?.payload?.info?.avgScore ?? 0;
            state.info = payload?.payload?.info ?? [];
        },
        clearProduct: (state) => {
            state.productStatus = NetworkStatus.loading;
            state.reviews = {};
            state.reviewsTotal = 0;
            state.reviewsCount = 0;
            state.reviewsOffset = 0;
            state.yourScore = 0;
            state.score = 0;
            state.info = {};
        },
        setIsLiked: (state, payload) => {
            state.info.isLiked = payload.payload;
        },
        addReviews: (state, payload) => {
            state.reviews = [
                ...state.reviews,
                ...payload.payload.reviews,
            ];

            state.reviewsCount += payload.payload.reviews.length;
            state.reviewsOffset += payload.payload.reviews.length;
        },
        createReview: (state, payload) => {
            state.reviews = [
                payload.payload.review,
                ...state.reviews,
            ];
            state.reviewsCount += 1;
            state.reviewsOffset += 1;
            state.reviewsTotal += 1;

            state.reviewsTotal = payload.payload.total;
            state.score = payload.payload.avgScore;
            state.yourScore = payload.payload.yourScore;
        },
        updateReviewLikes: (state, payload) => {
            for (let i in state.reviews) {
                if (state.reviews[i].id === payload.payload.itemId) {
                    state.reviews[i].isDisLiked = payload.payload.isDisLiked;
                    state.reviews[i].isLiked = payload.payload.isLiked;
                    state.reviews[i].negativeScore = payload.payload.negativeScore;
                    state.reviews[i].positiveScore = payload.payload.positiveScore;
                }
            }
        },
    },
});

export const {
    setStatus,
    setProduct,
    addReviews,
    createReview,
    setIsLiked,
    updateReviewLikes,
    clearProduct,
} = userSlice.actions;

export const getProductPageStatus = (state) => state.product.productStatus;
export const getProductInfo = (state) => state.product.info;
export const isProductPlaced = (state) => state.product.placed;
export const getProductReviews = (state) => state.product.reviews;
export const getProductReviewsTotal = (state) => state.product.reviewsTotal;
export const getProductAvgScore = (state) => state.product.score;
export const getProductYourScore = (state) => state.product.yourScore;
export const getProductReviewPagination = (state) => [
    state.product.reviewsTotal,
    state.product.reviewsCount,
    state.product.reviewsOffset,
];
export default userSlice.reducer;