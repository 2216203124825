import React from "react";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import sorting from "../../../config/sorting";
import {getCurrentSort, setSortBy} from "../../../redusers/ItemsListReducer";

export const CategoryHeader = (data) => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);

    const sortBy = useItemListSelector(getCurrentSort);

    const sortByList = sorting(lang);

    const sortOptions = sortByList.map((item, index) => {
        return <option key={'sort-option-' + index} value={item.name}>{item.title}</option>;
    });

    return <>
        <div className="row align-items-center mb-7">
            <div className="col-12 col-md">
                {/*<h3 className="mb-1">Womens' Clothing</h3>*/}
                <ol className="breadcrumb mb-md-0 fs-xs text-gray-400 d-none d-sm-none d-md-block">
                    {data.breadCrumb}
                </ol>
            </div>
            <div className="col-12 col-md-auto">
                <select
                    name="orderby"
                    onChange={(item) => { dispatch(setSortBy(item.target.value)); }}
                    value={sortBy}
                    className="form-select form-select-xs mt-4"
                >
                    {sortOptions}
                </select>
            </div>
        </div>
    </>;
}

export default CategoryHeader;