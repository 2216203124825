import cardImage from "../../../assets/dekbed/payments/cards.svg";
import paypalImage from "../../../assets/dekbed/payments/paypal.svg";
import iDealImage from "../../../assets/dekbed/payments/ideal.svg";

export const paymentVariants = [
    {
        img: iDealImage,
        title: '',
        value: 'PAYMENT_IDEAL',
    },
    {
        img: cardImage,
        title: 'Credit Card',
        value: 'PAYMENT_CARD',
    },
    {
        img: paypalImage,
        title: '',
        value: 'PAYMENT_PAYPAL',
    },
];

export default paymentVariants;