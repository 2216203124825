import {createSlice} from '@reduxjs/toolkit';
import {NetworkStatus} from "../helpers/network/NetworkStatus";


const initialState = {
    list: [],
    page: 1,
    status: NetworkStatus.loading,
    current: null,
};

export const userSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        loadList: (state, action) => {
            state.list = action.payload;
        },
        loadOrder: (state, action) => { state.order = action.payload },
        setOrderPage: (state, action) => { state.page = action.payload },
    },
});

export const {
    loadList,
    loadOrder,
    setOrderPage,
} = userSlice.actions;

export const getOrderList = (state) => state.order.list;
export const getOrderDetail = (state) => state.order.order;
export const getPage = (state) => state.order.page;
export default userSlice.reducer;