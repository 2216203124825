import {text} from "../../../config/i18n/text";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import Cost from "../Cost";
import img1 from "../../../assets/img/products/product-6.jpg";
import {Link} from "react-router-dom";
import {makeItem} from "../../../routes/routes";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken, removeFromWishList} from "../../../redusers/UserReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";

export const AccountWishListItem = (data) => {

    const params = {
        title: '',
        price: 0.0,
        id: '',
        images: [],
        ...data,
    };

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);

    const link = makeItem(params.id);

    const [state, setState] = useState(NetworkStatus.idle);

    const removeClick = () => {
        setState(NetworkStatus.loading);
        MakeRequest(getUrl(apiRoutes.itemLike, server)+ '?item=' + params.id, 'POST',{}, token, () => {
            dispatch(removeFromWishList(params.id));
            setState(NetworkStatus.idle);
        }, (e) => {console.error('response error is', e); setState(NetworkStatus.fail); });
    }

    console.log('img', data?.images[0] ?? '');

    return <div className="col-6 col-md-4">
        <div className="card mb-7">
            <div className="card-img">
                <button
                    className="btn btn-xs btn-circle btn-white-primary card-action card-action-end"
                    onClick={state === NetworkStatus.loading ? null : removeClick}
                >
                    { state !== NetworkStatus.loading
                        ? <i className="fe fe-x"></i>
                        : <div className="spinner-grow text-danger mb-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }

                </button>
                <Link className="btn btn-xs w-100 btn-dark card-btn" to={link}>
                    <i className="fe fe-eye me-2 mb-1"></i> {text[lang].account.wishlist.quick_view}
                </Link>
                <img className="card-img-top" src={data?.images[0]?.thumb ?? img1} alt={''}/>
            </div>
            <div className="card-body fw-bold text-center">
                <Link className="text-body" to={link}>{params.title}</Link> <br/>
                <span className="text-muted">
                    <Cost money={params.price} />
                </span>
            </div>
        </div>
    </div>;
}

export default AccountWishListItem;