import Header from "../parts/Header";
import image from "../../assets/hw-404.svg";
import {Link} from "react-router-dom";
import * as routerPath from "./../../routes/routes";
import { useRouteError } from "react-router-dom";
import {texts} from "../../config/config";
import {ArrowLeft} from "react-bootstrap-icons";

export const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);
    return (
        <>
            <Header />
            <div className='container-404'>
                <img src={image} alt='404 page not found'/>
            </div>
            <div className='container-text'>
                <h1>{texts.page} {error.statusText || error.message}</h1>
                <Link className='hvr-grow hvr-underline-from-center' to={routerPath.main}><ArrowLeft size={24} /> {texts.goMainPage}</Link>
            </div>
        </>
    );
}

export default ErrorPage;