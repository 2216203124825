import Header from "../parts/Header";
import {Helmet} from "react-helmet";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as routers from "../../routes/routes";
import validateEmail from "../../helpers/validators/EmailValidator";
import validatePassword from "../../helpers/validators/PasswordValidator";
import {Alert} from "react-bootstrap";
import axios from "axios";
import {authKeyName, getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {getLang, getServer, setAuth} from "../../redusers/InterfaceReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {AuthUser} from "../../redusers/UserReducer";
import {text} from "../../config/i18n/text";
import Footer from "../parts/Footer";

export const LoginPage = () => {
    const [responseError, setResponseError] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [emailChanged, setEmailChanged] = useState(false);

    const [emailRestore, setEmailRestore] = useState('');
    const [emailRestoreValid, setEmailRestoreValid] = useState(false);
    const [emailRestoreErrorText, setEmailRestoreErrorText] = useState('');
    const [emailRestoreChanged, setEmailRestoreChanged] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [loginInPored, setLoginProgress] = useState(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const server = useAppSelector(getServer);

    const lang = useAppSelector(getLang);

    function changeEmail(e){
        setEmail(e.currentTarget.value);
        setResponseError('');
    }
    function checkEmail(){
        let answer = validateEmail(email, lang);
        setEmailValid(answer.valid);
        setEmailChanged(true);
        setEmailErrorText(answer.error)
        setResponseError('');
    }
    function changeEmailRestore(e){
        setEmailRestore(e.currentTarget.value);
    }
    function checkEmailRestore(){
        let answer = validateEmail(emailRestore, lang);
        setEmailRestoreValid(answer.valid);
        setEmailRestoreChanged(true);
        setEmailRestoreErrorText(answer.error)
    }
    function changePassword(e){
        setPassword(e.currentTarget.value);
        setResponseError('');
    }
    function checkPassword(){
        let answer = validatePassword(password, lang);
        setPasswordValid(answer.valid);
        setPasswordChanged(true);
        setPasswordErrorText(answer.error)
        setResponseError('');
    }

    async function login() {
        let formValid = true;
        if(!emailValid){
            setEmailChanged(true);
            formValid = false;
        }
        if(!passwordValid){
            setPasswordChanged(true);
            formValid = false;
        }
        if(formValid){
            setLoginProgress(true);
            const body = {
                login: email,
                password: password,
                type: 'TYPE_WEB',
            };
            axios.post(
                getUrl(apiRoutes.login, server), body
            )
                .then(async function (response) {
                    setLoginProgress(false);

                    const newState = {
                        userId: response.data.data.id,
                        userToken: response.data.data.token,
                        userName: response.data.data.username,
                        firstName: response.data.data.firstName,
                        lastName: response.data.data.lastName,
                        email: response.data.data.email,
                        phone: response.data.data.phone,
                        sex: response.data.data.sex,
                        birth: response.data.data.birth,
                    };
                    dispatch(AuthUser(newState));
                    const serializedState = JSON.stringify(newState);
                    await localStorage.setItem(authKeyName + 'user', serializedState);
                    dispatch(setAuth())
                    navigate(routers.home);

                })
                .catch(function (error) {
                    setLoginProgress(false);
                    console.error('NETWORK ERROR!!!!');
                    console.error(error);
                    if(false === error.response.data.status){
                        setResponseError(error.response.data.message);
                    }
                });
        }
    }
    let passwordError = passwordValid ? null : <div className='invalid-feedback'>{passwordErrorText}</div>

    let passwordFieldClass = 'form-control';
    if(passwordChanged){
        passwordFieldClass = passwordValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    let emailError = emailValid ? null : <div className='invalid-feedback'>{emailErrorText}</div>

    let emailFiledClass = 'form-control';
    if(emailChanged){
        emailFiledClass = emailValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }
    let emailRestoreFiledClass = 'form-control';
    if(emailRestoreChanged){
        emailRestoreFiledClass = emailRestoreValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    return (
        <div className="happy-app bg-light">
            <Helmet>
                <title>Sign In</title>
                <meta name="description" content="Happy Wardrobe" />
            </Helmet>
            <Header/>
            <section className="py-12">
                <div className="modal fade" id="modalPasswordReset" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fe fe-x" aria-hidden="true"></i>
                            </button>
                            <div className="modal-header lh-fixed fs-lg">
                                <strong className="mx-auto">{text[lang].restore.title}</strong>
                            </div>
                            <div className="modal-body text-center">
                                <p className="mb-7 fs-sm text-gray-500">
                                    {text[lang].restore.text}
                                </p>
                                <form>
                                    <div className="form-group">
                                        <label className="visually-hidden" htmlFor="modalPasswordResetEmail">
                                            {text[lang].restore.email}
                                        </label>
                                        <input
                                            id="modalPasswordResetEmail"
                                            type="email"
                                            placeholder={text[lang].restore.email}
                                            onInput={changeEmailRestore}
                                            onBlur={checkEmailRestore}
                                            className={emailRestoreFiledClass}
                                            value={emailRestore}
                                            required
                                        />
                                        {emailRestoreErrorText}
                                    </div>
                                    <button className="btn btn-sm btn-dark" type='button'>
                                        {text[lang].restore.button_title}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className='col-md-3'></div>
                        <div className="col-12 col-md-6">
                            <div className="card card-lg mb-10 mb-md-0">
                                {'' !== responseError && <Alert variant='danger'>
                                    {responseError}
                                </Alert>}
                                <div className="card-body">
                                    <h6 className="mb-7">{text[lang].login.title}</h6>
                                    <p className="lead fs-12">{text[lang].login.dont_have_account} <Link to={routers.registration}>{text[lang].login.create_free_account}</Link></p>
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">

                                                    <label className="visually-hidden" htmlFor="loginEmail">
                                                        {text[lang].login.email}
                                                    </label>
                                                    <input
                                                        id="loginEmail"
                                                        type="email"
                                                        onInput={changeEmail}
                                                        onBlur={checkEmail}
                                                        className={emailFiledClass}
                                                        value={email}
                                                        placeholder={text[lang].login.email}
                                                        required
                                                        disabled={false}
                                                    />
                                                    {emailError}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="visually-hidden" htmlFor="loginPassword">
                                                        {text[lang].login.password}
                                                    </label>
                                                    <input
                                                        className={passwordFieldClass}
                                                        id="loginPassword"
                                                        type="password"
                                                        placeholder={text[lang].login.password}
                                                        value={password}
                                                        onInput={changePassword}
                                                        onBlur={checkPassword}
                                                        required
                                                    />
                                                    {passwordError}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            id="loginRemember"
                                                            type="checkbox"
                                                            defaultChecked={true}
                                                        />
                                                            <label className="form-check-label" htmlFor="loginRemember">
                                                                {text[lang].login.remember}
                                                            </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-auto">
                                                <div className="form-group">
                                                    <a className="fs-sm text-reset" data-bs-toggle="modal"
                                                       href="#modalPasswordReset">{text[lang].login.forgot_password}</a>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-sm btn-dark btn-login"
                                                    disabled={loginInPored}
                                                    onClick={login}
                                                    type="button"
                                                >
                                                    {text[lang].login.sign_in}
                                                    { loginInPored && <span
                                                        className="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span> }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div style={{height: 140}}></div>
            <Footer />
        </div>
    );
}

export default LoginPage;