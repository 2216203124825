import {Link} from "react-router-dom";
import * as routers from "../../../routes/routes";
import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";

export const AccountMenu = (data) => {

    const lang = useAppSelector(getLang);
    //{text[lang].header.shipping}

    const params = {
        active: -1,
        ...data
    }

    const menu = [
        {
            title: text[lang].account.menu.order,
            url: routers.accountOrders,
        },
        {
            title: text[lang].account.menu.wishlist,
            url: routers.accountWishlist,
        },
        {
            title: text[lang].account.menu.info,
            url: routers.accountInfo,
        },
        {
            title: text[lang].account.menu.address,
            url: routers.accountAddresses,
        },
        {
            title: text[lang].account.menu.logout,
            url: routers.logout,
        }
    ];

    const style = "list-group-item list-group-item-action dropend-toggle ";

    const menuItems = menu.map((item, index) => {
        return <Link
            key={'account-menu-line-' + index}
            className={style + (index === params.active ? 'active' : '')}
            to={item.url}
        >{item.title}</Link>;
    });

    return <nav className="mb-10 mb-md-0">
        <div className="list-group list-group-sm list-group-strong list-group-flush-x">
            {menuItems}
        </div>
    </nav>
}

export default AccountMenu;