import React, {useState} from "react";
import {Link} from "react-router-dom";
import {makeAddress} from "../../../../routes/routes";
import {NetworkStatus} from "../../../../helpers/network/NetworkStatus";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../../app/hooks";
import {getUserToken} from "../../../../redusers/UserReducer";
import {getServer} from "../../../../redusers/InterfaceReducer";
import MakeRequest from "../../../../helpers/network/MakeRequest";
import {getUrl} from "../../../../config/config";
import {apiRoutes} from "../../../../config/apiRoutes";
import {setList} from "../../../../redusers/AddressReducer";
import MakeGetParams from "../../../../helpers/network/MakeGetParams";

export const AccountAddressBlock = (data) => {

    const token = useItemListSelector(getUserToken);

    const server = useAppSelector(getServer);

    const dispatch = useAppDispatch();

    const [state, setState] = useState(NetworkStatus.idle);

    const params = {
        id: '',
        name: '',
        companyName: '',
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        zip: '',
        line1: '',
        line2: '',
        ...data
    };

    const removeClickAction = () => {
        MakeRequest(
            getUrl(apiRoutes.addressRemove, server) + MakeGetParams({id: params.id}),
            'POST',
            {},
            token,
            (response) => {
                dispatch(setList([
                    ...response.data.data.billing,
                    ...response.data.data.shipping,
                ]));
                setState(NetworkStatus.idle);
            },
            (e) => {
                setState(NetworkStatus.fail);
                console.error('response error is', e);
            });
    }

    return <div className="col-12 col-lg-6">
        <div className="card card-lg bg-light mb-8">
            <div className="card-body">
                <h6 className="mb-3">{params.name}</h6>
                <p className="text-muted mb-0">
                    {params.companyName} <br/>
                    {params.line1} {params.line2} <br/>
                    {params.zip} {params.city} <br/>
                    {params.country} <br/>
                </p>
                <div className="card-action card-action-end">
                    <Link className="btn btn-xs btn-circle btn-white-primary"
                       to={makeAddress(params.id)}>
                        <i className="fe fe-edit-2"></i>
                    </Link>&nbsp;
                    <button
                        className="btn btn-xs btn-circle btn-white-primary text-center"
                        onClick={removeClickAction}
                        disabled={state === NetworkStatus.loading}
                    >{state === NetworkStatus.loading
                        ? <span
                            className="spinner-border spinner-border text-danger m-1"
                            role="status"
                            aria-hidden="true"></span>
                        : <i className="fe fe-x"></i>}
                    </button>
                </div>
            </div>
        </div>
    </div>;
}

export default AccountAddressBlock;