import React from "react";
import {promoInfo} from "../../../config/config";
import Money from "../money/Money";
import {useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";

export const InfoBlock = () => {

    const lang = useAppSelector(getLang);

    return <section className="bg-light py-9">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="d-flex mb-6 mb-lg-0">
                        <i className="fe fe-truck fs-lg text-primary"></i>
                        <div className="ms-6">
                            <h6 className="heading-xxs mb-1">
                                {text[lang].promo.free_shipping}
                            </h6>
                            <p className="mb-0 fs-sm text-muted">
                                {text[lang].promo.free_shipping_from} <Money amount={promoInfo.free_shipping_from}/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="d-flex mb-6 mb-lg-0">
                        <i className="fe fe-repeat fs-lg text-primary"></i>
                        <div className="ms-6">
                            <h6 className="mb-1 heading-xxs">
                                {text[lang].promo.free_returns}
                            </h6>
                            <p className="mb-0 fs-sm text-muted">
                                {text[lang].promo.free_returns_text.replace('%period%', promoInfo.return_period)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="d-flex mb-6 mb-md-0">
                        <i className="fe fe-lock fs-lg text-primary"></i>
                        <div className="ms-6">
                            <h6 className="mb-1 heading-xxs">
                                {text[lang].promo.secure_shopping}
                            </h6>
                            <p className="mb-0 fs-sm text-muted">
                                {text[lang].promo.secure_shopping_text}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="d-flex">
                        <i className="fe fe-tag fs-lg text-primary"></i>
                        <div className="ms-6">
                            <h6 className="mb-1 heading-xxs">
                                {text[lang].promo.styles_title.replace('%amount%', promoInfo.items_amount)}
                            </h6>
                            <p className="mb-0 fs-sm text-muted">
                                {text[lang].promo.styles_text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default InfoBlock;