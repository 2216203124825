import React, {useState} from "react";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {
    getForm, getShippingDifferentAddress,
    getShippingPosition, setFormBlur,
    setFormFocus,
    setFormValue, setShippingDifferentAddress,
    shippingMethodChange
} from "../../../redusers/CheckoutReducer";
import Money from "../money/Money";
import fields from "../../models/checkout/ShippingFormModel";
import variants from '../../models/checkout/ShippingVariantsModel';
import buildForm, {makeFieldBlur, makeFieldFocus, makeValueChanged} from "../../../helpers/form/FormBuild";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken} from "../../../redusers/UserReducer";
import AddressType from "../../models/checkout/AddressType";

export const ShippingFormBlock = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const form = useAppSelector(getForm);

    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);

    const shippingPosition = useAppSelector(getShippingPosition);
    const shippingDifferentAddress = useAppSelector(getShippingDifferentAddress);


    const [state, setState] = useState(NetworkStatus.idle);
    const [saved, setSaved] = useState(false);

    const inputChange = (e) => {
        const index = parseInt(e.target.value);
        dispatch(shippingMethodChange({
            position: index,
            cost: variants[index].cost,
            value: variants[index].value,
        }));
    }

    const valueChanged = makeValueChanged(dispatch, setFormValue);

    const fieldFocus = makeFieldFocus(dispatch, setFormFocus);

    const fieldBlur = makeFieldBlur(dispatch, setFormBlur, lang, form);

    const saveShippingAddress = () => {
        setState(NetworkStatus.loading);
        const data = {
            company: form.values.shippingCompany,
            country: form.values.shippingCountry,
            city: form.values.shippingCity,
            zip: form.values.shippingZip,
            line1: form.values.shippingAddress1,
            line2: form.values.shippingAddress2,
            type: AddressType.SHIPPING,
            defaultShipping: false,
            defaultBilling: false,
        };
        MakeRequest(getUrl(apiRoutes.addressCreate, server), 'post', data, token, (response) => {
            console.info('data from server', response.data.data);
            setState(NetworkStatus.idle);
            setSaved(true);

        }, (e) => {
            if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                setState(NetworkStatus.idle);
            } else {
                console.error('response error is', e);
                setState(NetworkStatus.fail);
            }
        });
    }

    const displayForm = buildForm(fields, form, lang, valueChanged, fieldFocus, fieldBlur, state !== NetworkStatus.idle);

    const tableData = variants.map((item, index)=>{
        return <tr key={'shipping-variants-row-' + index}>
            <td>
                <div className="form-check custom-radio">
                    <input
                        className="form-check-input"
                        id={'shipping-input-' + index}
                        name={'shipping-input'}
                        value={index}
                        type="radio"
                        checked={shippingPosition === index}
                        onChange={inputChange}
                    />
                    <label
                        className="form-check-label text-body text-nowrap"
                        htmlFor={'shipping-input-' + index}
                    >{item.title}
                    </label>
                </div>
            </td>
            <td>{item.text}</td>
            <td><Money amount={item.cost}/></td>
        </tr>;
    });

    const saveButtonClassName = saved ? 'btn btn-outline-success btn-underline col-12 col-md-4' : 'btn btn-sm btn-outline-dark col-12 col-md-4';

    return <>
        <h6 className="mb-7">Shipping Details</h6>
        <div className="table-responsive mb-6">
            <table className="table table-bordered table-sm table-hover mb-0">
                <tbody>
                {tableData}
                </tbody>
            </table>
        </div>
        <div className="mb-9">
            <div className="form-check">
                <input
                    className="form-check-input"
                    id="checkoutShippingAddress"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkoutShippingAddressCollapse"
                    type="checkbox"
                    onChange={() => { dispatch(setShippingDifferentAddress(!shippingDifferentAddress)) }}
                    checked={shippingDifferentAddress}
                />
                <label
                    className="form-check-label fs-sm"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkoutShippingAddressCollapse"
                    htmlFor="checkoutShippingAddress"
                >
                    Ship to a different address?
                </label>
            </div>
            <div className="collapse" id="checkoutShippingAddressCollapse">
                <div className="row mt-6">
                    {displayForm}
                    <div className="col-12">
                        <button
                            className={saveButtonClassName}
                            type="button"
                            onClick={saveShippingAddress}
                            disabled={state !== NetworkStatus.idle || true === saved}
                        >Save Address {state !== NetworkStatus.idle && <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>}{saved && <i className="fe fe-check"></i>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default ShippingFormBlock;