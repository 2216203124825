import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getPaymentMethod, setPaymentMethod} from "../../../redusers/CheckoutReducer";
import variants from "../../models/checkout/PaymentTypeModel";

export const PaymentFormBlock = () => {

    const dispatch = useAppDispatch();

    const method = useAppSelector(getPaymentMethod);

    const paymentChanged = (e) => {
        dispatch(setPaymentMethod(e.target.value));
    }

    const paymentsBlocks = variants.map((item, index) => {
        const size = item.value === 'ideal' ? 30 : 18;
        return <div className="list-group-item" key={'payment-input-' + index}>
            <div className="form-check custom-radio">
                <input
                    className="form-check-input"
                    id={'payment-input-' + index}
                    name="payment"
                    type="radio"
                    onChange={paymentChanged}
                    checked={method === item.value}
                    value={item.value}
                />
                <label
                    className="form-check-label fs-sm text-body text-nowrap"
                    htmlFor={'payment-input-' + index}
                >{item.title}
                    <img
                        src={item.img}
                        style={{height: size, maxHeight: size }}
                        alt="..."
                    />
                </label>
            </div>

        </div>
    });

    return <>
        <h6 className="mb-7">Payment</h6>
        <div className="list-group list-group-sm mb-7">
            {paymentsBlocks}

        </div>
    </>;
}

export default PaymentFormBlock;