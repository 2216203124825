import React from "react";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import MainSliderBlock from "../parts/block/main/MainSliderBlock";
import MainPopularCategoriesBlock from "../parts/block/main/MainPopularCategoriesBlock";
import InfoBlock from "../parts/block/InfoBlock";
import MainLatestItemsBlock from "../parts/block/main/MainLatestItemsBlock";
import MainPopularsItemsBlock from "../parts/block/main/MainPopularsItemsBlock";
import {Helmet} from "react-helmet";
import {useAppSelector} from "../../app/hooks";
import {getLang} from "../../redusers/InterfaceReducer";
import {text} from "../../config/i18n/text";

export const MainPage = () => {

    const lang = useAppSelector(getLang);

    return (
        <>
            <Helmet>
                <title>{text[lang].seo.main_page.title}</title>
                <meta name="description" content={text[lang].seo.main_page.description} />
            </Helmet>
            <Header />
            <MainSliderBlock />
            <MainPopularCategoriesBlock />
            {/*<MainCategoriesBlock />*/}
            <MainLatestItemsBlock />
            <MainPopularsItemsBlock />
            <InfoBlock />
            <Footer />
        </>
    );
}

export default MainPage;