import React from "react";
import ranges from "../../../../config/FilterPriceRanges";
import {text} from "../../../../config/i18n/text";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";
import {
    getCost, updateCostFilter,
    updateMaxCostFilter,
    updateMinCostFilter
} from "../../../../redusers/ItemsListReducer";

export const PriceFilter = () => {

    const dispatch = useAppDispatch();
    const cost = useAppSelector(getCost);

    function ChangeMin(e) {
        dispatch(updateMinCostFilter(e.target.value));
    }
    function ChangeMax(e) {
        dispatch(updateMaxCostFilter(e.target.value));
    }

    function ChangeMinMax(min, max){
        dispatch(updateCostFilter({min: min, max: max}));
    }

    const lang = useAppSelector(getLang);

    const displayRanges = ranges.map((item, index) => {
        const min = item.min !== null ? (item.max === null ? text[lang].category.filters.less_then : '') + '€' + item.min + '.00 ' + (item.max !== null ? '‒ ' : '') : text[lang].category.filters.greater_then;
        const max = item.max !== null ? '€' + item.max + '.00' : '';
        return <div className="form-check mb-3" key={'price-options-' + index}>
            <label className="form-check-label" htmlFor="priceOne" onClick={() => {ChangeMinMax(item.min ?? null, item.max ?? null) }}>
                {min}{max}
            </label>
        </div>;
    });
    return <li className="nav-item">
        <a className="nav-link dropdown-toggle fs-lg text-reset border-bottom mb-6"
           data-bs-toggle="collapse" href="#priceCollapse">
            {text[lang].category.filters.price}
        </a>
        <div className="collapse" id="priceCollapse"
             data-simplebar-collapse="#priceGroup">
            <div className="form-group form-group-overflow mb-6" id="priceGroup">
                {displayRanges}
            </div>
            <div className="d-flex align-items-center">
                <input type="number" className="form-control form-control-xs"
                       placeholder="€0.00" min="10" value={cost.min} onChange={ChangeMin}/>
                <div className="text-gray-350 mx-2">‒</div>
                <input type="number" className="form-control form-control-xs" onChange={ChangeMax}
                       placeholder="MAX" max="350" value={cost.max}/>
            </div>
            <br/>
        </div>
    </li>;
}

export default PriceFilter;