export const apiRoutes = {
    login: '/api/v1/auth/login',
    registration: '/api/v1/registration/',
    logout: '/api/v1/auth/logout',
    profile: '/api/v1/profile',
    category_info: '/api/v1/category/info/slug',
    itemsList: '/api/v1/item/search',
    itemsInfo: '/api/v1/item/',
    itemLike: '/api/v1/item/like',
    itemCreate: '/api/v1/item/',
    imageUpload: '/api/v1/file/upload',
    imageResolve: '/api/v1/image/resolve',
    itemSuggests: '/api/v1/item/suggest',
    itemReviewLike: '/api/v1/item/review/like',
    itemReviewDisLike: '/api/v1/item/review/dislike',
    itemReviewAdd: '/api/v1/item/review/add',
    itemReviewList: '/api/v1/item/review/list',
    myWishList: '/api/v1/item/wish',
    updateProfile: '/api/v1/profile/update',
    latestItemsByCategories: '/api/v1/item/category/latest',
    latestItems: '/api/v1/item/latest',
    popularsItems: '/api/v1/item/latest',
    article: '/api/v1/content/',
    orderCreate: '/api/v1/order/create',
    orderPlace: '/api/v1/order/place',
    ordersList: '/api/v1/order/list',
    ordersDetail: '/api/v1/order/read',
    orderPromoActivate: '/api/v1/order/promo/activate',
    addressRead: '/api/v1/client/address/read',
    addressCreate: '/api/v1/client/address/create',
    addressList: '/api/v1/client/address/list',
    addressRemove: '/api/v1/client/address/remove',
    addressUpdate: '/api/v1/client/address/update',
    clientSubscribe: '/api/v1/client/subscription/',
}