import {text} from "../../config/i18n/text";

export function SelectValidator(value, lang, fieldName, validValues)
{
    for(let i in validValues) {
        if(value !== null && value !== undefined && value !== '' && validValues[i].code === value){
            return {
                valid: true,
                error: '',
            };
        }
    }

    return {
        valid: false,
        error: text[lang].validation.wrong_select.replace(':field:', fieldName),
    };
}

export default SelectValidator;