import Header from "../parts/Header";
import {Helmet} from "react-helmet";
import React from "react";
import {Link} from "react-router-dom";
import * as routers from "../../routes/routes";
export const SellPageDone = () => {

    return (
        <div className="Happy-app">
            <Helmet>
                <title>Your item was added</title>
                <meta name="description" content="Happy Wardrobe" />
            </Helmet>
            <Header/>
            <div className="Happy-app-body">
                <section className="page-404 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-3">
                                <div className="error-content">
                                    <h2 className="mb-4">Your Item was added to database.</h2>
                                    <Link to={routers.main} className="btn btn-main">View my orders</Link>&nbsp;
                                    <Link to={routers.main} className="btn btn-main">Go Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default SellPageDone;