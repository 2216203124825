import img1 from "./../../../../assets/img/dek-bed/main-slider-1.png";
import img2 from "./../../../../assets/img/dek-bed/main-slider-2.png";
import img3 from "./../../../../assets/img/dek-bed/main-slider-3.png";
import {makeCategory} from "../../../../routes/routes";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";
import {Link} from "react-router-dom";
import {text} from "../../../../config/i18n/text";
export const MainPopularCategoriesBlock = (data) => {
    const params = {
        minHeight: 280,
        items: [
            {
                title: {
                    en: 'Dekbedovertrek',
                    fr: 'Dekbedovertrek',
                    nl: 'Dekbedovertrek',
                },
                link: makeCategory('dekbedovertrek'),
                img: img1
            },
            {
                title: {
                    en: 'Hoeslaken',
                    fr: 'Hoeslaken',
                    nl: 'Hoeslaken',
                },
                link: makeCategory('hoeslaken'),
                img: img2
            },
            {
                title: {
                    en: 'Laken',
                    fr: 'Laken',
                    nl: 'Laken',
                },
                link: makeCategory('laken'),
                img: img3
            },
        ],
        ...data
    };

    const lang = useAppSelector(getLang);

    const items = params.items.map((item, index) => {
        return <div
            className="col-12 col-sm-4 d-flex flex-column px-3"
            key={'main-popular-category-' + index}
        >
            <div className="card card-xl mb-3 mb-sm-0" style={{minHeight: params.minHeight}}>
                <div className="card-bg">
                    <div className="card-bg-img bg-cover"
                         style={{backgroundImage: `url(${item.img})`}}
                    ></div>
                </div>
                <div className="card-body my-auto">
                    <h5 className="mb-0">{item.title[lang]}</h5>
                    <Link className="btn btn-link stretched-link px-0 text-reset" to={item.link}>
                        {text[lang].main_page.shop_now}<i className="fe fe-arrow-right ms-2"></i>
                    </Link>
                </div>
            </div>
        </div>
    });
    return <section className="pt-6">
        <div className="container-fluid px-3 px-md-6">
            <div className="row mx-n3">
                {items}
            </div>
        </div>
    </section>;
}

export default MainPopularCategoriesBlock;