import React, {useEffect, useState} from "react";
import {useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken} from "../../../redusers/UserReducer";
import CategoryItem from "../listParts/CategoryItem";

export const SuggestItemList = (data) => {

    const server = useAppSelector(getServer);
    const lang = useAppSelector(getLang);
    const token = useItemListSelector(getUserToken);

    const [status, setStatus] = useState(NetworkStatus.loading);
    const [items, setItems] = useState([]);

    useEffect(() => {
        MakeRequest(
            getUrl(apiRoutes.itemSuggests, server) + '?id='+ data.id,
            'GET',
            {},
            token,
            (response) => {
            setStatus(NetworkStatus.idle);
            setItems(response.data);
        }, () => {
            setStatus(NetworkStatus.fail);
        });
    }, [data.id]);

    if(status === NetworkStatus.loading){
        return <div className="container">
            <div className="row">
                <div className="col-12 text-center mt-10">
                    <div className="spinner-grow text-primary mb-1 text-center card" role="status">
                        <span className="visually-hidden text-center">Loading...</span>
                    </div>
                </div>
            </div>
        </div>;
    } else if(status === NetworkStatus.fail){
        return <div className="container">
            <div className="row">
                <div className="alert alert-primary mt-10" role="alert">
                    Fail to load suggested items
                </div>
            </div>
        </div>;
    }

    if(items.length < 1){
        return null;
    }

    const itemsList = items.map((item, index) => {
        return <CategoryItem
            key={'item-' + index + '-id-' + item.id}
            id={item.id}
            title={item.title}
            titles={item.titles}
            cost={item.cost}
            discount={null}
            category={item.category}
            images={item.images}
            new={false}
            sale={false}
            col={6}
            mdCol={4}
        />
    });


    return <section className="pt-11">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h4 className="mb-10 text-center">{text[lang].product.suggest}</h4>
                    <div className="row">
                        {itemsList}
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default SuggestItemList;