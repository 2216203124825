import React from "react";
import {text} from "../../../config/i18n/text";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {getForm, setFormValue} from "../../../redusers/CheckoutReducer";

export const OrderNoteFormBlock = () => {
    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const form = useAppSelector(getForm);

    const valueChanged = (field, value) => {
        dispatch(setFormValue({
            field: field,
            value: value,
        }));
    }

    return <>
        <label
            className="form-check-label fs-sm text-body text-nowrap"
            htmlFor='textarea-note'
        >{text[lang].forms.note.title}
        </label>
        <textarea
            id='textarea-note'
            className="form-control form-control-sm mb-9 mb-md-0 fs-xs"
            rows="5"
            value={form.values.note}
            placeholder={text[lang].forms.note.placeholder}
            onChange={(e) => {
                valueChanged('note', e.target.value);
            }}
        />
    </>;
}

export default OrderNoteFormBlock;