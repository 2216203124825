import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    newestByCategory: [],
    newest: [],
    populars: [],
};

export const userSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setNewestCategories: (state, payload) => {
            state.newestByCategory = payload.payload;
        },
        setNewest: (state, payload) => {
            state.newest = payload.payload;
        },
        setPopulars: (state, payload) => {
            state.populars = payload.payload;
        },
    },
});

export const {
    setNewestCategories,
    setNewest,
    setPopulars,
} = userSlice.actions;

export const getNewestByCategory = (state) => state.main.newestByCategory;
export const getNewestItems = (state) => state.main.newest;
export const getPopulars = (state) => state.main.populars;
export default userSlice.reducer;