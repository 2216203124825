export const AvailableAddressTypes = [
    {
        code: 'billing',
        title: 'Billing Address'
    },
    {
        code: 'shipping',
        title: 'Shipping Address'
    }
];

export default AvailableAddressTypes;