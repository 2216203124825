import React from "react";
import Select from 'react-select';
import chroma from 'chroma-js';

export const FilterColorListInput = (filterData) => {

    const options = [
        { value: null, label: 'Select an color', color: '#000', isFixed: true, isDisabled: true },
        ...filterData.item.values.map((item) => {
            const prvStyle = item.value === ':mixed:'
                ? {background: "conic-gradient(from 315deg, rgba(255,0,0,1), rgba(9,121,12,1), rgba(6,43,148,1), rgba(239,255,0,1), rgba(189,3,177,1))"}
                : {backgroundColor: item.value} ;
            return {
                value: item.id,
                label: <div className='color-line'><div className='color-line-prv' style={prvStyle}></div><div className='color-line-name'>{item.title}</div></div>,
                color: item.value
            };
            // === ':mixed:' ? '#000' : item.value
        })
    ];

    const colourStyles = {
        //control: (styles) => ({ ...styles, backgroundColor: 'white', height: '20px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color === ':mixed:' ? chroma('#000') : chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        // multiValueLabel: (styles, { data }) => ({
        //     ...styles,
        //     color: 'white',
        // }),
        // multiValueRemove: (styles, { data }) => ({
        //     ...styles,
        //     color: 'white',
        //     ':hover': {
        //         color: '#ccc',
        //     },
        // }),
    };

    return <div  key={filterData.item.id} className="mb-3">
            <label htmlFor={"filter" + filterData.item.name} className="form-label">{filterData.item.title}</label>
        <Select
            className='color-select-item'
            classNames={{
                multiValue: () => 'color-multi-option',
                option: () => 'color-option',
            }}
            isMulti
            placeholder={'Select an Color'}
            blurInputOnSelect={false}
            aria-invalid={true}

            onChange={(e) => {console.warn('color changed', e); filterData.onChange(e); }}
            isSearchable={false}
            defaultValue={null}
            options={options}
            styles={colourStyles}
        />
        </div>;
}

export default FilterColorListInput