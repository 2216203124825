import React, {useState} from "react";
import Header from "../parts/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../parts/Footer";
import Pager from "../parts/Pager";

export const TestPage = () => {
    const [page, setPage] = useState(1);

    return (
        <div className='object-profile'>
            <Header />
            <Pager page={page} max={10} callback={() => { setPage(5) }}/>
            <Footer />
        </div>
    );
}

export default TestPage;