import AvailableCountries from "./AvailableCountries";
import AvailableAddressTypes from "./AvailableAddressTypes";

export const AddressFormModel = [
    {
        name: 'addressType',
        type: 'select',
        values: AvailableAddressTypes,
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'select', validValues: AvailableAddressTypes}],
        meta: {},
        disabled: false,
    },
    {
        name: 'name',
        type: 'text',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 1}],
        meta: {},
        disabled: null,
    },
    {
        name: 'company',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'country',
        type: 'select',
        values: AvailableCountries,
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'select', validValues: AvailableCountries}],
        meta: {},
        disabled: null,
    },
    {
        name: 'address1',
        type: 'text',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
    {
        name: 'address2',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'city',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 2}],
        meta: {},
        disabled: null,
    },
    {
        name: 'zip',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
    {
        name: 'defaultShipping',
        type: 'checkbox',
        required: false,
        size: 12,
        size_md: 12,
        validators: [],
        meta: {},
        disabled: null,
    },
    {
        name: 'defaultBilling',
        type: 'checkbox',
        required: false,
        size: 12,
        size_md: 12,
        validators: [],
        meta: {},
        disabled: null,
    }
];

export default AddressFormModel