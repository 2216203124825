import {Link} from "react-router-dom";
import {makeItem} from "../../../../routes/routes";
import React from "react";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";

export const GroupDisplayInList = (data) => {

    const lang = useAppSelector(getLang);

    return data.connections.map(function (listItem, listIndex) {
        return <div className="form-check form-check-inline form-check-size mb-2"
                    key={'group-' + data.index + '-list-item-' + listIndex}>
            <Link
                to={makeItem(listItem.itemId)}
                className={['form-check-label', (data.current === listItem.value) && 'active'].join(' ')}
            >{listItem.titles[lang] ?? listItem.titles['en'] ?? listItem.title}</Link>
        </div>;
    });
}

export default GroupDisplayInList;