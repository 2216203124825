import React from 'react';

export function FullScreenLoader() {
    return <div className='fullscreen-loader'>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
}

export default FullScreenLoader;