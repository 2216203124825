import {text} from "../../../config/i18n/text";
import {Link} from "react-router-dom";
import * as routers from "../../../routes/routes";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {updateTick} from "../../../redusers/ArticleReducer";

export const ArticleNotFoundBlock = () => {

    const lang = useAppSelector(getLang);

    const dispatch = useAppDispatch();

    const refreshClick = () => {
        dispatch(updateTick());
    }

    return <div>
        <section className="py-12">
            <div className="container">
                <div className="row">
                    <div className='col-3'></div>
                    <div className="col-6">
                        <div className="card card-lg mb-10 mb-md-0">
                            <div className="card-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="error-content text-center">
                                                <h1 className="mb-8 text-danger">{text[lang].article.not_found.title}</h1>
                                                <p>{text[lang].article.not_found.text}</p>
                                                <button
                                                    className="btn btn-outline-dark m-2"
                                                    onClick={refreshClick}
                                                >{text[lang].article.not_found.refresh}</button>
                                                <Link to={routers.main} className="btn btn-outline-dark">{text[lang].article.not_found.go_home}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}
export default ArticleNotFoundBlock;