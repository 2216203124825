import {useAppDispatch} from "../../app/hooks";

function Pager(e) {
    const dispatch = useAppDispatch();

    const params = {
        max: 1,
        page: 1,
        ...e,
    };

    if(params.max <= 1){
        return null;
    }

    function click(page){
        document.body.scrollTo({ top: 0 });
        dispatch(params.click(page));
    }

    let pager = [];
    for(let i = 1; i <= params.max; i++){
        if(i >= params.page - 5 && i <= params.page + 5){
            pager.push(
                <li className={["page-item", i === params.page && 'active'].join(' ')} key={'pager-item-' + i}>
                    <span className="page-link c-pointer" onClick={() => {click(i)}}>{i}</span>
                </li>
            );
        }
    }
    return (
        <nav className="d-flex justify-content-center justify-content-md-end">
            <ul className="pagination pagination-sm text-gray-400">
                <li className={["page-item", params.page <= 1 && "disabled"].join(' ')}>
                    <span className="page-link page-link-arrow" onClick={() => {click(params.page - 1)}}>
                        <i className="fa fa-caret-left"></i>
                    </span>
                </li>
                {pager}
                <li className={["page-item", params.page >= params.max && "disabled"].join(' ')}>
                    <span className="page-link page-link-arrow" onClick={() => {click(params.page + 1)}}>
                        <i className="fa fa-caret-right"></i>
                    </span>
                </li>
            </ul>
        </nav>
    );
}

export default Pager;