import wNumb from "wnumb";
export const Cost = (data) => {
    const Format = wNumb({
        mark: data?.mark ?? '.',
        thousand: data?.thousand ?? ',',
        decimals: data?.decimals ?? 2,
        prefix: data?.prefix ?? '€',
    });

    return <div className={data?.class ?? null}>{Format.to( data.money )}</div>;

}

export default Cost;