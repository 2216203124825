import React from "react";
import Select from 'react-select';

export const FilterListInput = (filterData) => {
    const options = filterData.item.values.map((item) => {
        return { value: item.id, label: item.title, isFixed: true, isDisabled: false };
    });
    const colourStyles = {
        input: (styles) => ({
            ...styles,
            height: '20px',
        }),
        //control: (styles) => ({ ...styles, backgroundColor: 'white', height: '20px' }),
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                color: isDisabled
                    ? '#ccc'
                    : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        // multiValueLabel: (styles, { data }) => ({
        //     ...styles,
        //     color: 'white',
        // }),
        // multiValueRemove: (styles, { data }) => ({
        //     ...styles,
        //     color: 'white',
        //     ':hover': {
        //         color: '#ccc',
        //     },
        // }),
    };
    return <div  key={filterData.item.id} className="mb-3">

        <label htmlFor={"filter" + filterData.item.name} className="form-label">{filterData.item.title}</label>

        <Select
            className=''
            classNames={{
                multiValue: () => '',
                option: () => '',
                input: () => 'filter-list-input'
            }}
            isMulti={filterData.item.maxCount > 1}
            placeholder={'Select an ' + filterData.item.name}

            onChange={(e) => {console.warn(filterData.item.name + ' changed', e); filterData.onChange(e);}}
            isSearchable={true}
            defaultValue={null}
            options={options}
            styles={colourStyles}
        />
        </div>;
}

export default FilterListInput