import {text} from "../../../../config/i18n/text";
import {Link} from "react-router-dom";
import {makeAccountOrder} from "../../../../routes/routes";
import React from "react";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";
import image from "../../../../assets/img/products/product-6.jpg";
import OrderTitleBlock from "./OrderTitleBlock";

export const OrderListItem = (data) => {

    const lang = useAppSelector(getLang);

    let itemBlocks = [];
    const itemImages = Math.min(3, data?.order?.count);
    for(let i = 1; i<= itemImages; i++){
        itemBlocks.push(<div className="col-3" key={'item-' + data?.order?.orderId + '-' + i}>
            <div className="ratio ratio-1x1 bg-cover" style={{backgroundImage: `url(${image})`}}
            ></div>
        </div>);
    }

    const more = Math.max(0, data?.order?.count - 3);

    return <div className="card card-lg mb-5 border">
        <OrderTitleBlock
            displayId={data.order.orderId}
            id={data.order.id}
            date={data.order.updatedAt}
            status={{
                title: data.order.status.title,
                class: data.order.status.color,
            }}
            amount={data.order.amount}
        />
        <div className="card-footer">
            <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                    <div className="row gx-5 mb-4 mb-lg-0">
                        {itemBlocks}
                        {more > 0 && <div className="col-3">
                            <div className="ratio ratio-1x1 bg-light">
                                <Link
                                    className="ratio-item ratio-item-text text-reset"
                                    to={makeAccountOrder(data?.order?.id)}
                                ><div className="fs-xxs fw-bold">
                                    +{more} <br/> {text[lang].account.order_list.order_more}
                                </div></Link>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="row gx-">
                        <div className="col-6">
                            <Link
                                className="btn btn-sm w-200 btn-outline-dark"
                                to={makeAccountOrder(data?.order?.id)}
                            >
                                {text[lang].account.order_list.order_details}
                            </Link>
                        </div>
                        {/*<div className="col-6">*/}
                        {/*    <a className="btn btn-sm w-100 btn-outline-dark" href="#!">*/}
                        {/*        {text[lang].account.order_list.order_track}*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default OrderListItem;