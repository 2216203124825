
export const availableLang = {
    en: 'en',
    fr: 'fr',
    nl: 'nl',
    de: 'de',
};

export const text = {
    en: {
        name: 'DEKBED BAZAAR',
        seo:{
            loading: {
                title: 'DEKBED BAZAAR | loading',
                description: 'loading',
            },
            not_found: {
                title: 'DEKBEDBAZAAR | Page not found',
                description: 'Page not found',
            },
            main_page: {
                title: 'DEKBED BAZAAR | we selling your sleep',
                description: 'We selling your sleep',
            },
            category: {
                title: 'DEKBED BAZAAR | list items of ',
                description: 'list items of ',
            },
            product: {
                title: 'DEKBED BAZAAR | ',
                description: 'item description ',
            },
            article: {
                title: 'DEKBED BAZAAR | ',
                description: 'article ',
            },
            account:{
                personal_info: {
                    title: 'DEKBED BAZAAR | Account | personal information',
                    description: 'personal information',
                },
                wishlist: {
                    title: 'DEKBED BAZAAR | Account | wishlist',
                    description: 'wishlist',
                },
                orders: {
                    title: 'DEKBED BAZAAR | Account | order list',
                    description: 'order list',
                },
                order: {
                    title: 'DEKBED BAZAAR | Account | order detail',
                    description: 'order detail',
                },
                address: {
                    title: 'DEKBED BAZAAR | Account | address list',
                    description: 'address list',
                },
                address_form: {
                    title: 'DEKBED BAZAAR | Account | address  edit',
                    description: 'address edit',
                },
                address_new: {
                    title: 'DEKBED BAZAAR | Account | add new address',
                    description: 'add new address',
                },
                address_edit: {
                    title: 'DEKBED BAZAAR | Account | edit address',
                    description: 'edit address',
                },
                address_add: {
                    title: 'DEKBED BAZAAR | Account | add address',
                    description: 'add address',
                },
            },
        },
        validation:{
            field_short: 'Field ":field:" must be more then ":digits:" length',
            field_long:  'Field ":field:" must be less then ":digits:" length',
            wrong_select:  'Field ":field:" not valid',
        },
        header: {
            shipping: 'Shipping Netherlands and Belgium',
            lang: {
                en: 'English',
                fr: 'French',
                nl: 'Dutch',
                de: 'German',
            },
            short_lang: {
                en: 'En',
                fr: 'Fr',
                nl: 'Nl',
                de: 'De',
            },
        },
        subscribe: {
            title: 'Want style Ideas and Treats?',
            button_title: 'Subscribe',
            placed: 'you have successfully subscribed to the newsletter',
        },
        logout: {
            title: 'You logout success.',
            button_title: 'Go Home',
            wrong: 'Something went wrong.',
            try_again: 'Try Again.',
        },
        login: {
            title: 'Returning Customer',
            email: 'Email Address *',
            password: 'Password *',
            remember: 'Remember me',
            forgot_password: 'Forgot Password?',
            sign_in: 'Sign In',
            dont_have_account: 'Don’t have an account?',
            create_free_account: 'Create a free account',
            errors: {
                email: {
                    short: 'e-mail is to short',
                    long: 'e-mail is to long',
                    invalid: 'e-mail not valid',
                },
                password: {
                    short: 'password is to short',
                    long: 'password is to long',
                    invalid: 'password not valid',
                },
                username: {
                    short: 'username must have at least 5 characters',
                    long: 'username is to long',
                },
            }
        },

        registration: {
            new_customer: 'New Customer',
            have_account: 'Already have an account?',
            login_now: 'Login now',
            first_name: 'First Name *',
            last_name: 'Last Name *',
            email: 'Email Address *',
            password: 'Password *',
            text1: 'By registering your details, you agree with our ',
            text2: ', and ',
            term_and_conditions: 'Terms & Conditions',
            privacy_policy: 'Privacy and Cookie Policy.',
            newsletter: 'Sign me up for the Newsletter!',
            register: 'Register',
        },
        restore: {
            title: 'Forgot Password?',
            text: 'Please enter your Email Address. You will receive a link to create a new password via Email.',
            email: 'Email Address *',
            button_title: 'Reset Password',
        },
        footer: {
            support: 'Support',
            shop: 'Shop',
            company: 'Company',
            contact: 'Contact',
            all_rights: 'All rights reserved.',
        },
        links: {
            shipping: 'Shipping',
            faq: 'FAQ',
            contacts: 'Contact',
            contact_us: 'Contact Us',
            faqs: 'FAQs',
            size: 'Size Guide',
            returns: 'Shipping & Returns',
            our_story: 'Our Story',
            careers: 'Careers',
            terms_and_conditions: 'Terms & Conditions',
            privacy_cookie_policy: 'Privacy & Cookie policy',
        },
        promo: {
            free_shipping: 'Free shipping',
            free_shipping_from: 'From all orders over',
            free_returns: 'Free returns',
            free_returns_text: 'Return money within %period% days',
            secure_shopping: 'Secure shopping',
            secure_shopping_text: 'You\'re in safe hands',
            styles_title: 'Over %amount% Styles',
            styles_text: 'We have everything you need',
        },
        category: {
            sorting: {
                default: 'Default sorting',
                popular: 'Sort by popularity',
                rating: 'Sort by average rating',
                latest: 'Sort by latest',
                pricea: 'Sort by price: low to high',
                priced: 'Sort by price: high to low',
            },
            filters: {
                price: 'Price',
                less_then: 'Less then ',
                or_less: 'or less',
                greater_then: 'Greater then ',
                category: 'Category',
                filter: 'Filter',
                clear: 'Clear',
            },
            no_items: {
                title: 'Items not found',
                text: 'try to search with different filters or in other category',
                button_home_title: 'Go home',
            },
            filter_button: {
                show_button: 'Show Filters',
                hide_button: 'Hide Filters',
            }
        },
        product: {
            reviews: 'Reviews',
            in_stock: 'In Stock',
            out_of_stock: 'Out of Stock',
            add_to_cart: 'Add to Cart',
            product_details: 'Product Details',
            size_fit: 'Size & Fit',
            shipping_returns: 'Shipping & Returns',
            wishlist: 'Wishlist',
            suggest: 'You might also like',
            sale: 'Sale',
            new: 'New',
            not_found: {
                title: '404. Product not found.',
                text1: 'Sorry, we couldn\'t find the product you where looking for.',
                text2: 'We suggest that you return to home page.',
                action: 'Go to Homepage',
            }
        },
        review: {
            title: 'Customer Reviews',
            text: 'No reviews have been published.\nPlace an order first now to write a review.',
            smile: '🙁',
            title_button: 'Write Review',
            score: 'Score',
            input_title_placeholder: 'Review Title',
            input_text_placeholder: 'Review',
            title_submit_button: 'Post Review',
            helpful: 'Was this review helpful?',
            already_placed: 'You already placed you review',
            load_more: 'Load more...',
            error: {
                score_error: 'Score value must be in range [1,5]',
                title_error: 'Title value must be in range [5,255]',
                text_error: 'Text value must be in range [5,4000]',
            },
        },
        cart: {
            title: 'Your Cart',
            subtotal: 'Subtotal',
            button_title_continue_to_checkout: 'Continue to Checkout',
            button_title_back_to_shopping: 'Back to shopping',
            empty: {
                text: 'Your cart is empty 😞',
                button_title_continue: 'Continue Shopping',
            },
            item: {
                image_placeholder: 'item preview image',
                remove: 'Remove',
            }
        },
        account: {
            menu: {
                order: 'Orders',
                wishlist: 'Wishlist',
                info: 'Personal Info',
                address: 'Addresses',
                payment: 'Payment Methods',
                logout: 'Logout',
            },
            wishlist: {
                title: 'DEKBEDBAZAR | My Wishlist',
                description: 'some text with description',
                page_title: 'My Wishlist',
                quick_view: 'Quick View',
                no_items: 'Sorry, you dont have items in your wish list',
            },
            order_list: {
                title: 'My Account',
                order_number: 'Order ID:',
                order_date: 'Shipped date:',
                order_status: 'Status:',
                order_amount: 'Amount:',
                order_more: 'more',
                order_details: 'Order Details',
                order_track: 'Track order',
            },
            order_detail: {
                title: 'My Order #',
                item_list_title: 'Order Items',
                item_payment_title: 'Order Total',
                order_subtotal: 'Subtotal',
                order_discount: 'Discount',
                order_shipping: 'Shipping',
                order_total: 'Total',
                order_info_title: 'Billing & Shipping Details',
                order_billing_address: 'Billing Address',
                order_shipping_address: 'Shipping Address',
                order_shipping_method: 'Shipping Method',
                order_payment_method: 'Payment Method',
            },
            info: {
                title: 'DEKBEDBAZAR | Personal Information',
                description: 'some text with description',
                page_title: 'My Account',
                success: 'Your Personal information updated successfully',
                field_title: {
                    first_name: 'First Name *',
                    last_name: 'Last Name *',
                    username: 'Display Name',
                    phone: 'Phone number *',
                    email: 'Email Address',
                    email_read_only: 'You can\'t change email',
                    birth: 'Date of Birth',
                    gender: 'Gender',
                    male: 'Male',
                    female: 'Female',
                    day: 'Day',
                    month: 'Month',
                    year: 'Year',
                    button: 'Save Changes'
                },
            },
            address_list: {
                title: 'My Address list',
                add_address_title: 'Add Address',
            },
            address_form: {
                title: 'Edit Address',
                button_title: 'Save Address',
            },
            address_create_form: {
                title: 'Create Address',
                button_title: 'Create Address',
            },
        },
        main_page: {
            shop_now: 'Shop Now ',
            newest_by_category: 'Newest from categories ',
            newest_items: 'Newest Items ',
            populars_items: 'Populars Items ',
        },
        article: {
            not_found: {
                title: 'Article not found',
                text: 'Article you request not found or was disabled or removed',
                go_home: 'Go Home',
                refresh: 'Refresh Page',
            },
            last_updated: 'Last updated at',
        },
        checkout: {
            breadcrumb: {
                home: 'Home',
                checkout: 'Checkout'
            },
            form: {
                title: 'Checkout',
                you_have_account: 'Already have an account?',
                click_to_login: 'Click here to login',
                billing_details: 'Billing Details',
            },
            order_items: 'Order Items',
            subtotal: 'Subtotal (inc VAT)',
            discount: 'Discount',
            shipping: 'Shipping',
            total: 'Total',
            order_text_info: 'Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.',
            order_button_title: 'Place Order',
            promo_code_title: 'Promo Code',
            promo_code_placeholder: 'Promo Code',
            completed: {
                emoji: '❤️',
                title: 'Your Order has been Placed!',
                text1: 'Your order',
                text2: 'has been\n' +
                    ' placed. Your order details\n' +
                    ' are shown for your personal account.',
                button_title: 'View My Orders',
            },
        },
        month: {
            january : 'January',
            february : 'February',
            march : 'March',
            april : 'April',
            may : 'May',
            june : 'June',
            july : 'July',
            august : 'August',
            september : 'September',
            october : 'October',
            november : 'November',
            december : 'December',
        },
        forms: {
            billingFirstName: {
                title: 'First Name *',
                placeholder: 'First Name',
            },
            billingLastName: {
                title: 'Last Name *',
                placeholder: 'Last Name',
            },
            billingEmail: {
                title: 'Email *',
                placeholder: 'Email',
            },
            billingCompany: {
                title: 'Company name',
                placeholder: 'Company name (optional)',
            },
            billingCountry: {
                title: 'Country',
                placeholder: 'Country *',
            },
            billingAddress1: {
                title: 'Address Line 1 *',
                placeholder: 'Address Line 1',
            },
            billingAddress2: {
                title: 'Address Line 2',
                placeholder: 'Address Line 2',
            },
            billingCity: {
                title: 'Town / City *',
                placeholder: 'Town / City',
            },
            billingZip: {
                title: 'ZIP / Postcode *',
                placeholder: 'ZIP / Postcode',
            },
            billingMobile: {
                title: 'Mobile Phone *',
                placeholder: 'Mobile Phone',
            },
            shippingCompany: {
                title: 'Company name',
                placeholder: 'Company name (optional)',
            },
            shippingCountry: {
                title: 'Country',
                placeholder: 'Country *',
            },
            shippingAddress1: {
                title: 'Address Line 1 *',
                placeholder: 'Address Line 1',
            },
            shippingAddress2: {
                title: 'Address Line 2',
                placeholder: 'Address Line 2',
            },
            shippingCity: {
                title: 'Town / City *',
                placeholder: 'Town / City',
            },
            shippingZip: {
                title: 'ZIP / Postcode *',
                placeholder: 'ZIP / Postcode',
            },
            note: {
                title: 'Order Notes (optional)',
                placeholder: 'Order Notes (optional)',
            },
            email: {
                title: 'Email *',
                placeholder: 'Email',
            },
            company: {
                title: 'Company name',
                placeholder: 'Company name (optional)',
            },
            country: {
                title: 'Country',
                placeholder: 'Country *',
            },
            addressType: {
                title: 'Address type',
                placeholder: 'Address Type *',
            },
            address1: {
                title: 'Address Line 1 *',
                placeholder: 'Address Line 1',
            },
            address2: {
                title: 'Address Line 2 *',
                placeholder: 'Address Line 2',
            },
            city: {
                title: 'Town / City *',
                placeholder: 'Town / City',
            },
            zip: {
                title: 'ZIP / Postcode *',
                placeholder: 'ZIP / Postcode',
            },
            defaultShipping: {
                title: 'Default shipping address',
                placeholder: 'Default shipping address',
            },
            defaultBilling: {
                title: 'Default billing address',
                placeholder: 'Default billing address',
            },
            name: {
                title: 'Name',
                placeholder: 'Name',
            },
        }
    },
    fr: {
        name: 'DEKBED BAZAAR',
        seo:{
            loading: {
                title: 'DEKBED BAZAAR | Chargement',
                description: 'Chargement',
            },
            not_found: {
                title: 'DEKBEDBAZAAR | Page introuvable',
                description: 'Page introuvable',
            },
            main_page: {
                title: 'DEKBED BAZAAR | Votre nuit est notre pursuit!',
                description: 'Votre nuit est notre pursuit!',
            },
            category: {
                title: 'DEKBED BAZAAR |  liste des produits ',
                description: ' liste des produits ',
            },
            product: {
                title: 'DEKBED BAZAAR | ',
                description: 'description du produit ',
            },
            article: {
                title: 'DEKBED BAZAAR | ',
                description: 'produit ',
            },
            account:{
                personal_info: {
                    title: 'DEKBED BAZAAR | Account | Informations personnelle',
                    description: 'Informations personnelle',
                },
                wishlist: {
                    title: 'DEKBED BAZAAR | Account | Liste des souhaits',
                    description: 'Liste de souhaits',
                },
                orders: {
                    title: 'DEKBED BAZAAR | Account | Liste des commandes',
                    description: 'Liste de commandes',
                },
                order: {
                    title: 'DEKBED BAZAAR | Account | Détails de la commande',
                    description: 'Détails de la commande',
                },
                address: {
                    title: 'DEKBED BAZAAR | Account | Liste des adresses',
                    description: 'Liste des adresses',
                },
                address_form: {
                    title: 'DEKBED BAZAAR | Account | Modifier les adresses',
                    description: 'Modifier les adresses',
                },
                address_new: {
                    title: 'DEKBED BAZAAR | Account | Ajouter une nouvelle adresse',
                    description: 'Ajouter une nouvelle adresse',
                },
                address_edit: {
                    title: 'DEKBED BAZAAR | Account | Modifier les adresses',
                    description: 'Modifier les adresses',
                },
                address_add: {
                    title: 'DEKBED BAZAAR | Account | Ajouter un adresse',
                    description: 'Ajouter un adresse',
                },
            },
        },
        validation:{
            field_short: 'Le champ ":field:" doit contenir plus de ":digits:" caractères',
            field_long:  'Field ":Le champ ":field:" doit contenir moins de ":digits:" caractères',
            wrong_select:  'Le champ ":field:" n est pas valide.',
        },
        header: {
            shipping: 'Livraison aux Pays-Bas, Belgique et UE',
            lang: {
                en: 'English',
                fr: 'Francais',
                nl: 'Nederlands',
                de: 'Germany',
            },
            short_lang: {
                en: 'En',
                fr: 'Fr',
                nl: 'Nl',
                de: 'De',
            },
        },
        subscribe: {
            title: 'Besoin des idées de style et de gâteries?',
            button_title: 'S\' abonner',
            placed: 'vous vous êtes abonné avec succès à la newsletter',
        },
        logout: {
            title: 'Vous êtes déconnecté(e) avec succès.',
            button_title: 'Retourner à la page d\' accueil.',
            wrong: 'Il y a une erreur.',
            try_again: 'Réessayez.',
        },
        login: {
            title: 'Client existant',
            email: 'Adresse e-mail *',
            password: 'Mot de passe *',
            remember: 'Souvien de moi',
            forgot_password: 'Mot de passe oublié?',
            sign_in: 'Se connecter',
            dont_have_account: 'Pas de compte?',
            create_free_account: 'Créer un compte gratuit',
            errors: {
                email: {
                    short: 'L\' adresse e-mail est trop courte',
                    long: 'L\' adresse e-mail est trop longue',
                    invalid: 'L\' adresse e-mail nest pas valide',
                },
                password: {
                    short: 'Mot de passe est trop courte',
                    long: 'Mot de passe est trop longue',
                    invalid: 'Mot de passe n\' est pas valide',
                },
                username: {
                    short: 'Le nom d utilisateur doit comporter au moins 5 caractères',
                    long: 'Le nom d utilisateur est trop long',
                },
            }
        },

        registration: {
            new_customer: 'Nouveau client',
            have_account: 'Vous avez déjà un compte?',
            login_now: 'Connectez-vous maintenant',
            first_name: 'Prénom *',
            last_name: 'Nom de famille *',
            email: 'Adresse e-mail *',
            password: 'Mot de passe *',
            text1: 'En enregistrant vos coordonnées, vous acceptez notre politique de confidentialité ',
            text2: ', et ',
            term_and_conditions: 'notre terme et conditions',
            privacy_policy: 'Politique de confidentialité et de cookies.',
            newsletter: 'Envoi-moi à la newsletter !!',
            register: 'S\' inscrire',
        },
        restore: {
            title: 'Mot de passe oublié?',
            text: 'Veuillez saisir votre adresse e-mail. Vous recevrez un lien pour créer un nouveau mot de passe par e-mail.',
            email: 'Adresse e-mail *',
            button_title: 'Réinitialiser le mot de passe.',
        },
        footer: {
            support: 'Support',
            shop: 'Notre Produits',
            company: 'Entreprise',
            contact: 'Contact',
            all_rights: 'Tous droits réservés',
        },
        links: {
            shipping: 'Livraison',
            faq: 'FAQ',
            contacts: 'Contact',
            contact_us: 'Contactez-nous',
            faqs: 'FAQs',
            size: 'Guide des tailles',
            returns: 'Livraison et retours',
            our_story: 'Notre histoire',
            careers: 'Carrières',
            terms_and_conditions: 'Conditions générales',
            privacy_cookie_policy: 'Politique de confidentialité et de cookies',
        },
        promo: {
            free_shipping: 'Livraison gratuite',
            free_shipping_from: 'À partir de toutes les commandes de plus de',
            free_returns: 'Retours gratuits',
            free_returns_text: 'Remboursement dans %period% jours',
            secure_shopping: 'Achat sécurisé',
            secure_shopping_text: 'Vous êtes entre de bonnes mains',
            styles_title: 'Plus de %amount% styles',
            styles_text: 'Nous avons tout ce dont vous avez besoin',
        },
        category: {
            sorting: {
                default: 'Trier par standard',
                popular: 'Trier par popularité',
                rating: 'Trier par note moyenne',
                latest: 'Trier par les plus récents',
                pricea: 'Trier par prix : du moins cher au plus cher',
                priced: 'Trier par prix : du plus cher au moins cher',
            },
            filters: {
                price: 'Prix',
                less_then: 'Moins que ',
                or_less: 'ou moins',
                greater_then: 'Plus grand que ',
                category: 'Catégorie',
                filter: 'Filtrer',
                clear: 'Clair',
            },
            no_items: {
                title: 'Produits pas trouvés',
                text: 'Essayez de rechercher avec des filtres différents ou dans une autre catégorie',
                button_home_title: 'Retourner à la page d\' accueil.',
            },

            filter_button: {
                show_button: 'Afficher les Filtres',
                hide_button: 'Masquer les Filtres',
            }
        },
        product: {
            reviews: 'Avis',
            in_stock: 'En stock',
            out_of_stock: 'Épuisé',
            add_to_cart: 'Ajouter au panier',
            product_details: 'Détails du produit',
            size_fit: 'Taille et ajustement',
            shipping_returns: 'Livraison et retours',
            wishlist: 'Liste de souhaits',
            suggest: 'Vous pourriez également aimer',
            sale: 'Vente',
            new: 'Nouveau',
            not_found: {
                title: '404. Produit non trouvé.',
                text1: 'Sorry, we couldn\'t find the product you where looking for.',
                text2: 'Désolé, nous n\'avons pas trouvé le produit que vous recherchiez.',
                action: 'Aller à la page d\'accueil',
            }
        },
        review: {
            title: 'Avis des clients',
            text: 'Aucun avis n\'a été publié. Passez d\'abord une commande maintenant pour ecrir un avis.',
            smile: '🙁',
            title_button: 'Ecri un avis',
            score: 'Score',
            input_title_placeholder: 'Titre de l\'avis',
            input_text_placeholder: 'Avis',
            title_submit_button: 'Publier l\'avis',
            helpful: 'Cet avis vous a-t-il été utile ?',
            already_placed: 'Vous avez déjà publié votre avis',
            load_more: 'Charger plus..',
            error: {
                score_error: 'La valeur du score doit être dans la plage spécifiée [1,5]',
                title_error: 'La valeur du titre doit être dans la plage spécifiée [5,255]',
                text_error: 'La valeur du texte doit être dans la plage spécifiée [5,4000]',
            },
        },
        cart: {
            title: 'Votre panier',
            subtotal: 'Sous-total',
            button_title_continue_to_checkout: 'Continuer vers le paiement',
            button_title_back_to_shopping: 'Retourner aux achats',
            empty: {
                text: 'Votre panier est vide 😞',
                button_title_continue: 'Continuer les achats',
            },
            item: {
                image_placeholder: 'Aperçu de l\'article',
                remove: 'Supprimer',
            }
        },
        account: {
            menu: {
                order: 'Commandes',
                wishlist: 'Liste de souhaits',
                info: 'Informations personnelles',
                address: 'Adresses',
                payment: 'Méthodes de paiement',
                logout: 'Déconnecter',
            },
            wishlist: {
                title: 'DEKBEDBAZAR | Ma liste de souhaits',
                description: 'quelques textes avec une description',
                page_title: 'Ma liste de souhaits',
                quick_view: 'Aperçu rapide',
                no_items: 'Désolé, vous n\'avez aucun article dans votre liste de souhaits',
            },
            order_list: {
                title: 'Mon compte',
                order_number: 'Numéro de commande :',
                order_date: 'Date d\'expédition:',
                order_status: 'Statut:',
                order_amount: 'Montant:',
                order_more: 'plus',
                order_details: 'Détails de la commande',
                order_track: 'Suivre la commande',
            },
            order_detail: {
                title: 'Ma commande #',
                item_list_title: 'Articles de la commande',
                item_payment_title: 'Total de la commande',
                order_subtotal: 'Sous-total',
                order_Remise: 'Remise',
                order_shipping: 'Livraison',
                order_total: 'Total',
                order_info_title: 'Détails de facturation et de livraison',
                order_billing_address: 'Adresse de facturation',
                order_shipping_address: 'Adresse de livraison',
                order_shipping_method: 'Méthode de livraison',
                order_payment_method: 'Méthode de paiement',
            },
            info: {
                title: 'DEKBEDBAZAR | Informations personnelles',
                description: 'quelques textes avec une description',
                page_title: 'Mon compte',
                success: 'Vos informations personnelles ont été mises à jour avec succès',
                field_title: {
                    first_name: 'Prénom *',
                    last_name: 'Nom de famille *',
                    username: 'Nom d\'utilisateur',
                    phone: 'Numéro de téléphone *',
                    email: 'Adresse e-mail',
                    email_read_only: 'Vous ne pouvez pas changer l\'adresse e-mail',
                    birth: 'Date de naissance',
                    gender: 'Genre',
                    male: 'Homme',
                    female: 'Femme',
                    day: 'Jour',
                    month: 'Mois',
                    year: 'Année',
                    button: 'Enregistrer les modifications'
                },
            },
            address_list: {
                title: 'Ma liste des adresses',
                add_address_title: 'Ajouter une adresse',
            },
            address_form: {
                title: 'Modifier l\'adresse',
                button_title: 'Enregistrer l\'adresse',
            },
            address_create_form: {
                title: 'Créer une adresse',
                button_title: 'Créer une adresse',
            },
        },
        main_page: {
            shop_now: 'Acheter maintenant ',
            newest_by_category: 'Les plus récents par catégories ',
            newest_items: 'Les plus récents ',
            populars_items: 'Les plus populaires ',
        },
        article: {
            not_found: {
                title: 'Article introuvable',
                text: 'L\'article que vous demandez est introuvable, a été désactivé ou supprimé',
                go_home: 'Retourner à la page d\'accueil.',
                refresh: 'Actualiser la page',
            },
            last_updated: 'Dernière mise à jour le',
        },
        checkout: {
            breadcrumb: {
                home: 'Accueil',
                checkout: 'Paiement'
            },
            form: {
                title: 'Paiement',
                you_have_account: 'Vous avez déjà un compte?',
                click_to_login: 'Click here to login',
                billing_details: 'Détails de facturation',
            },
            order_items: 'Articles de la commande',
            subtotal: 'Sous-total (TVA incluse)',
            discount: 'Remise',
            shipping: 'Livraison',
            total: 'Total',
            order_text_info: 'Vos données personnelles seront utilisées pour traiter votre commande, soutenir votre expérience sur ce site Web et à d\'autres fins décrites dans notre politique de confidentialité.',
            order_button_title: 'Passer la commande',
            promo_code_title: 'Code Promo',
            promo_code_placeholder: 'Code Promo',
            completed: {
                emoji: '❤️',
                title: 'Votre commande a été passée!',
                text1: 'Votre commande',
                text2: 'a été\n' +
                    ' passée. Votre détails de la commande\n' +
                    ' sont affichés dans votre compte personnel.',
                button_title: 'Voir mes commandes',
            },
        },
        month: {
            january : 'Janvier',
            february : 'Février',
            march : 'Mars',
            april : 'Avril',
            may : 'Mai',
            june : 'Juin',
            july : 'Juillet',
            august : 'Août',
            september : 'Septembre',
            october : 'Octobre',
            november : 'Novembre',
            december : 'Décembre',
        },
        forms: {
            billingFirstName: {
                title: 'Prénom *',
                placeholder: 'Prénom',
            },
            billingLastName: {
                title: 'Nom de famille *',
                placeholder: 'Nom de famille',
            },
            billingEmail: {
                title: 'Email *',
                placeholder: 'Email',
            },
            billingCompany: {
                title: 'Nom de l\'entreprise',
                placeholder: 'Nom de l\'entreprise (facultatif)',
            },
            billingCountry: {
                title: 'Pays',
                placeholder: 'Pays *',
            },
            billingAddress1: {
                title: 'Adresse ligne 1 *',
                placeholder: 'Adresse ligne 1',
            },
            billingAddress2: {
                title: 'Adresse ligne 2',
                placeholder: 'Adresse ligne 2',
            },
            billingCity: {
                title: 'Ville*',
                placeholder: 'Ville',
            },
            billingZip: {
                title: 'Code postal *',
                placeholder: 'Code postal',
            },
            billingMobile: {
                title: 'Téléphone portable *',
                placeholder: 'Téléphone portable *',
            },
            shippingCompany: {
                title: 'Nom de l\'entreprise ',
                placeholder: 'Nom de l\'entreprise (facultatif)',
            },
            shippingCountry: {
                title: 'Pays',
                placeholder: 'Pays *',
            },
            shippingAddress1: {
                title: 'Adresse ligne 1 *',
                placeholder: 'Adresse ligne 1',
            },
            shippingAddress2: {
                title: 'Adresse ligne 2',
                placeholder: 'Adresse ligne 2',
            },
            shippingCity: {
                title: 'Ville *',
                placeholder: 'Ville',
            },
            shippingZip: {
                title: 'Code postal *',
                placeholder: 'Code postal',
            },
            note: {
                title: 'Notes de commande (facultatif',
                placeholder: 'Notes de commande (facultatif)',
            },
            email: {
                title: 'Email *',
                placeholder: 'Email',
            },
            company: {
                title: 'Nom de l\'entreprise',
                placeholder: 'Nom de l\'entreprise (optional)',
            },
            country: {
                title: 'Pays',
                placeholder: 'Pays *',
            },
            addressType: {
                title: 'Type d\'adresse',
                placeholder: 'Type d\'adresse *',
            },
            address1: {
                title: 'Adresse ligne 1 *',
                placeholder: 'Adresse ligne 1',
            },
            address2: {
                title: 'Adresse ligne 2 *',
                placeholder: 'Adresse ligne 2',
            },
            city: {
                title: 'Ville *',
                placeholder: 'Ville',
            },
            zip: {
                title: 'Code postal *',
                placeholder: 'Code postal',
            },
            defaultShipping: {
                title: 'Adresse de livraison par défaut',
                placeholder: 'Adresse de livraison par défaut',
            },
            defaultBilling: {
                title: 'Adresse de facturation par défaut',
                placeholder: 'Adresse de facturation par défaut',
            },
            name: {
                title: 'Nom',
                placeholder: 'Nom',
            },
        }
    },
    nl: {
        name: 'DEKBED BAZAAR',
        seo:{
            loading: {
                title: 'DEKBED BAZAAR | laden',
                description: 'laden',
            },
            not_found: {
                title: 'DEKBED BAZAAR | Pagina niet gevonden',
                description: 'Pagina niet gevonden',
            },
            main_page: {
                title: 'DEKBED BAZAAR | Uw slaapkwaliteit is onze prioriteit!',
                description: 'Uw slaapkwaliteit is onze prioriteit!',
            },
            category: {
                title: 'DEKBED BAZAAR | lijst van items ',
                description: 'lijst van items ',
            },
            product: {
                title: 'DEKBED BAZAAR | ',
                description: 'item omschrijving ',
            },
            article: {
                title: 'DEKBED BAZAAR | ',
                description: 'artikel ',
            },
            account:{
                personal_info: {
                    title: 'DEKBED BAZAAR | Account | persoonlijk informatie',
                    description: 'persoonlijk informatie',
                },
                wishlist: {
                    title: 'DEKBED BAZAAR | Account | verlanglijstje',
                    description: 'verlanglijstje',
                },
                orders: {
                    title: 'DEKBED BAZAAR | Account | orderlijst',
                    description: 'orderlijst',
                },
                order: {
                    title: 'DEKBED BAZAAR | Account | order detail',
                    description: 'order detail',
                },
                address: {
                    title: 'DEKBED BAZAAR | Account | adres lijst',
                    description: 'adres lijst',
                },
                address_form: {
                    title: 'DEKBED BAZAAR | Account | adres bewerken',
                    description: 'adres bewerken',
                },
                address_new: {
                    title: 'DEKBED BAZAAR | Account | add nieuwe adres',
                    description: 'add nieuw adres',
                },
                address_edit: {
                    title: 'DEKBED BAZAAR | Account | bewerk adres',
                    description: 'bewerk adres',
                },
                address_add: {
                    title: 'DEKBED BAZAAR | Account | voeg adres toe',
                    description: 'voeg adres toe',
                },
            },
        },
        validation:{
            field_short: 'Veld ":Veld:" moet meer zijn dan ":cijfers:" lengte',
            field_long:  'Veld ":Veld:" moet minder zijn dan ":cijfers:" lengte',
            wrong_select:  'Veld ":Veld:" niet geldig',
        },
        header: {
            shipping: 'Verzending naar Nederland, Belgie en EU',
            lang: {
                en: 'English',
                fr: 'Francais',
                nl: 'Nederlands',
                de: 'Germany',
            },
            short_lang: {
                en: 'En',
                fr: 'Fr',
                nl: 'Nl',
                de: 'De',
            },
        },
        subscribe: {
            title: 'Op de hoogte blijven van trends, adviezen en aanbiedingen??',
            button_title: 'Schrijf je in',
        },
        logout: {
            title: 'Succesvol uitgelogd.',
            button_title: 'Ga naar Home',
            wrong: 'Er is iets mis gegaan.',
            try_again: 'Probeer nogmaals..',
        },
        login: {
            title: 'Terugkerende klant',
            email: 'Email adres *',
            password: 'Wachtwoord *',
            remember: 'Onthou mij',
            forgot_password: 'Wachtwoord Vergeten?',
            sign_in: 'Log In',
            dont_have_account: 'Nog geen klant account?',
            create_free_account: 'Maak een gratis account aan',
            errors: {
                email: {
                    short: 'e-mail is te kort',
                    long: 'e-mail is te lang',
                    invalid: 'e-mail is niet geldig',
                },
                password: {
                    short: 'Wachtwoord is te kort',
                    long: 'Wachtwoord is te lang',
                    invalid: 'Wachtwoord is niet geldig',
                },
                username: {
                    short: 'gebruikersnaam moet minimaal 5 caracters bevatten',
                    long: 'gebruikersnaam is te lang',
                },
            }
        },

        registration: {
            new_customer: 'Nieuwe Klant',
            have_account: 'Bent u al klant??',
            login_now: 'Log in nu',
            first_name: 'Voornaam *',
            last_name: 'Achternaam *',
            email: 'Email adres *',
            password: 'Wachtwoord *',
            text1: 'Door te registreren gaat u akkoord met onze ',
            text2: ', en ',
            term_and_conditions: 'Gebruiksvoorwaarden',
            privacy_policy: 'Privacy en Cookie Beleid.',
            newsletter: 'Schrijf me in voor de nieuwsbrief!',
            register: 'Registreren',
        },
        restore: {
            title: 'Wachtwoord Vergeten?',
            text: 'Voer alstublieft uw e-mailadres in. U ontvangt een link via e-mail om een nieuw wachtwoord aan te maken.',
            email: 'Email adres *',
            button_title: 'Reset Wachtwoord',
        },
        footer: {
            support: 'Support',
            shop: 'Shop',
            company: 'Bedrijf',
            contact: 'Contact',
            all_rights: 'Alle rechten voorbehouden.',
        },
        links: {
            shipping: 'Verzending',
            faq: 'FAQ',
            contacts: 'Contact',
            contact_us: 'Contacteer Ons',
            faqs: 'FAQs',
            size: 'Maten Tabel',
            returns: 'Verzending & Retouren',
            our_story: 'Ons Verhaal',
            careers: 'Werken bij ons',
            terms_and_conditions: 'Gebruiksvoorwaarden',
            privacy_cookie_policy: 'Privacy & Cookie beleid',
        },
        promo: {
            free_shipping: 'Gratis Verzending',
            free_shipping_from: 'Voor alle bestellingen boven',
            free_returns: 'Gratis Retourneren',
            free_returns_text: 'Geld terug binnen %period% dagen',
            secure_shopping: 'Veilig Winkelen',
            secure_shopping_text: 'U bent in veilige handen',
            styles_title: 'Meer dan %amount% kleuren en dessins',
            styles_text: 'We hebben alles wat u nodig heeft',
        },
        category: {
            sorting: {
                default: 'Standaard sorteren',
                popular: 'Sorteren op populariteit',
                rating: 'Sorteren op gemiddelde reviews',
                latest: 'Sorteren op meeste recent',
                pricea: 'Sorteren op prijs: laag naar hoog',
                priced: 'Sorteren op prijs: hoog naar laag',
            },
            filters: {
                price: 'Prijs',
                less_then: 'Minder dan ',
                or_less: 'of minder',
                greater_then: 'Groter dan ',
                category: 'Categorie',
                filter: 'Filter',
                clear: 'Duidelijk',
            },
            no_items: {
                title: 'Items niet gevonden',
                text: 'Probeer te zoeken met verschillende filters of in een andere categorie',
                button_home_title: 'Ga home',
            },
            filter_button: {
                show_button: 'Filters Tonen',
                hide_button: 'Filters Verbergen',
            }
        },
        product: {
            reviews: 'Reviews',
            in_stock: 'Op Voorraad',
            out_of_stock: 'Geen Voorraad',
            add_to_cart: 'In winkelwagen',
            product_details: 'Product Details',
            size_fit: 'Maten',
            shipping_returns: 'Verzending & Retourneren',
            wishlist: 'Verlanglijstje',
            suggest: 'Soortgelijke Producten',
            sale: 'Sale',
            new: 'Nieuw',
            not_found: {
                title: '404. Product niet gevonden.',
                text1: 'Sorry, we konden het product dat u zocht niet vinden.',
                text2: 'Wij raden u aan terug te keren naar de homepage.',
                action: 'Ga naar de Homepage',
            }
        },
        review: {
            title: 'Klant Reviews',
            text: 'Nog Geen Reviews.\nPlaats eerst een bestelling en schrijf dan een review.',
            smile: '🙁',
            title_button: 'Schrijf een review',
            score: 'Score',
            input_title_placeholder: 'Review Titel',
            input_text_placeholder: 'Review',
            title_submit_button: 'Review Plaatsen',
            helpful: 'Was deze review behulpzaam?',
            already_placed: 'U heeft al een review geplaatst',
            load_more: 'Meer laden...',
            error: {
                score_error: 'De waarde moet binnen het bereik liggen [1,5]',
                title_error: 'De waarde van de titel moet binnen het bereik liggen [5,255]',
                text_error: 'De waarde van de tekst moet binnen het bereik liggen [5,4000]',
            },
        },
        cart: {
            title: 'Winkelwagentje',
            subtotal: 'Subtotaal',
            button_title_continue_to_checkout: 'Verder naar bestellen',
            button_title_back_to_shopping: 'Verder winkelen',
            empty: {
                text: 'Je winkelwagentje is leeg 😞',
                button_title_continue: 'Verder winkelen',
            },
            item: {
                image_placeholder: 'item preview Afbeelding',
                remove: 'Verwijder',
            }
        },
        account: {
            menu: {
                order: 'Bestellingen',
                wishlist: 'Mijn verlanglijstje',
                info: 'Persoonlijke informatie',
                address: 'Adressen',
                payment: 'Betaalopties',
                logout: 'Uitloggen',
            },
            wishlist: {
                title: 'DEKBEDBAZAR | Mijn verlanglijstje',
                description: 'Alle producten dat u nog wilt overwegen',
                page_title: 'Mijn verlanglijstje',
                quick_view: 'Quick View',
                no_items: 'Sorry, je hebt nog geen producten op je verlanglijstje staan',
            },
            order_list: {
                title: 'Mijn Account',
                order_number: 'Order ID:',
                order_date: 'Verzend datum:',
                order_status: 'Status:',
                order_amount: 'Bedrag:',
                order_more: 'Meer',
                order_details: 'Order Details',
                order_track: 'Track mijn Bestelling',
            },
            order_detail: {
                title: 'Mijn Order #',
                item_list_title: 'Order Items',
                item_payment_title: 'Order Totaal',
                order_subtotal: 'Subtotaal',
                order_discount: 'Korting',
                order_shipping: 'Verzending',
                order_total: 'Totaal',
                order_info_title: 'Factuur & Verzending Details',
                order_billing_address: 'Factuur Adres',
                order_shipping_address: 'Verzend Adres',
                order_shipping_method: 'Verzend Methode',
                order_payment_method: 'Betaalwijze',
            },
            info: {
                title: 'DEKBEDBAZAR | persoonlijk informatie',
                description: 'Mijn Persoonlijk informatie',
                page_title: 'Mijn Account',
                success: 'Uw persoonlijk informatie is succesvol bijgewerkt',
                field_title: {
                    first_name: 'Voornaam *',
                    last_name: 'Achternaam *',
                    username: 'Geruikersnaam',
                    phone: 'Telefoon nummer *',
                    email: 'Email adres',
                    email_read_only: 'U kunt\' uw mail niet veranderen',
                    birth: 'Geboortedatum',
                    gender: 'Geslacht',
                    male: 'Man',
                    female: 'Vrouw',
                    day: 'Dag',
                    month: 'Maand',
                    year: 'Jaar',
                    button: 'Opslaan'
                },
            },
            address_list: {
                title: 'Mijn adressenlijst',
                add_address_title: 'Voeg adres toe',
            },
            address_form: {
                title: 'Bewerk adres',
                button_title: 'Adres Opslaan',
            },
            address_create_form: {
                title: 'Adres maken',
                button_title: 'Adres maken',
            },
        },
        main_page: {
            shop_now: 'Bekijk hier ',
            newest_by_category: 'Nieuwste van de categorieën ',
            newest_items: 'Nieuwste Producten ',
            populars_items: 'Populaire Producten ',
        },
        article: {
            not_found: {
                title: 'artikel niet gevonden',
                text: 'Opgevraagde artikel, is niet gevonden of is uitgeschakeld of verwijderd',
                go_home: 'Ga naar Home',
                refresh: 'Pagina Vernieuwen ',
            },
            last_updated: 'Laatst bijgewerkt op',
        },
        checkout: {
            breadcrumb: {
                home: 'Home',
                checkout: 'Afrekenen'
            },
            form: {
                title: 'Afrekenen',
                you_have_account: 'Bent u al klant??',
                click_to_login: 'Klik hier om in te loggen',
                billing_details: 'Factuurgegevens',
            },
            order_items: 'Bestelde Producten',
            subtotal: 'Subtotaal (inc BTW)',
            discount: 'Korting',
            shipping: 'Verzending',
            total: 'Totaal',
            order_text_info: 'Je persoonlijke gegevens worden gebruikt om je bestelling te verwerken, je ervaring op deze website te ondersteunen en voor andere doeleinden zoals beschreven in ons privacybeleid..',
            order_button_title: 'Bestellen en betalen',
            promo_code_title: 'Kortingscode',
            promo_code_placeholder: 'Kortingscode',
            completed: {
                emoji: '❤️',
                title: 'Uw bestelling is geplaatst!',
                text1: 'Uw bestelling',
                text2: 'is\n' +
                    ' geplaatst. Uw order details\n' +
                    ' zijn te vinden in uw persoonlijke account .',
                button_title: 'Bekijk Mijn Bestellingen',
            },
        },
        month: {
            january : 'Januari',
            february : 'Februari',
            march : 'Maart',
            april : 'April',
            may : 'Mei',
            june : 'Juni',
            july : 'Juli',
            august : 'Augustus',
            september : 'September',
            october : 'Oktober',
            november : 'November',
            december : 'December',
        },
        forms: {
            billingFirstName: {
                title: 'Voornaam *',
                placeholder: 'Voornaam',
            },
            billingLastName: {
                title: 'Achternaam *',
                placeholder: 'Achternaam',
            },
            billingEmail: {
                title: 'Email *',
                placeholder: 'Email',
            },
            billingCompany: {
                title: 'Bedrijfsnaam',
                placeholder: 'Bedrijfsnaam (optioneel)',
            },
            billingCountry: {
                title: 'Land',
                placeholder: 'Land *',
            },
            billingAddress1: {
                title: 'Adres lijn 1 *',
                placeholder: 'Adres lijn 1',
            },
            billingAddress2: {
                title: 'adres lijn 2',
                placeholder: 'adres lijn 2',
            },
            billingCity: {
                title: 'Stad / Stad *',
                placeholder: 'Stad / Stad',
            },
            billingZip: {
                title: 'Postcode / Postcode *',
                placeholder: 'Postcode / Postcode',
            },
            billingMobile: {
                title: 'Mobiele nummer *',
                placeholder: 'Mobiele nummer',
            },
            shippingCompany: {
                title: 'Bedrijfsnaam',
                placeholder: 'Bedrijfsnaam (optional)',
            },
            shippingCountry: {
                title: 'Land',
                placeholder: 'Land *',
            },
            shippingAddress1: {
                title: 'adres Lijn 1 *',
                placeholder: 'adres Lijn 1',
            },
            shippingAddress2: {
                title: 'adres Lijn 2',
                placeholder: 'adres Lijn 2',
            },
            shippingCity: {
                title: 'Stad / Stad *',
                placeholder: 'Stad / Stad',
            },
            shippingZip: {
                title: 'Postcode / Postcode *',
                placeholder: 'Postcode / Postcode',
            },
            note: {
                title: 'Order Notes (optional)',
                placeholder: 'Order Notes (optional)',
            },
            email: {
                title: 'Email *',
                placeholder: 'Email',
            },
            company: {
                title: 'Bedrijfsnaam',
                placeholder: 'Bedrijfsnaam (optional)',
            },
            country: {
                title: 'Land',
                placeholder: 'Land *',
            },
            addressType: {
                title: 'adres type',
                placeholder: 'adres Type *',
            },
            address1: {
                title: 'adres Lijn 1 *',
                placeholder: 'adres Lijn 1',
            },
            address2: {
                title: 'adres Lijn 2 *',
                placeholder: 'adres Lijn 2',
            },
            city: {
                title: 'Stad / Stad *',
                placeholder: 'Stad / Stad',
            },
            zip: {
                title: 'Postcode / Postcode *',
                placeholder: 'Postcode / Postcode',
            },
            defaultShipping: {
                title: 'Standaard Bezorgadres',
                placeholder: 'Standaard Bezorgadres',
            },
            defaultBilling: {
                title: 'Standaard factuuradres',
                placeholder: 'Standaard factuuradres',
            },
            name: {
                title: 'Naam',
                placeholder: 'Naam',
            },
        }
    },
    de: {
        name: '',
        seo:{
            loading: {
                title: 'DEKBED BAZAAR | wird geladen',
                description: 'wird geladen',
            },
            not_found: {
                title: 'DEKBEDBAZAAR | Seite nicht gefunden',
                description: 'Seite nicht gefunden',
            },
            main_page: {
                title: 'DEKBED BAZAAR | Ihre Schlafqualität hat für uns oberste Priorität',
                description: 'Ihre Schlafqualität hat für uns oberste Priorität',
            },
            category: {
                title: 'DEKBED BAZAAR | Auflistung von Artikeln von ',
                description: 'Auflistung von Artikeln von ',
            },
            product: {
                title: 'DEKBED BAZAAR | ',
                description: 'Artikelbeschreibung ',
            },
            article: {
                title: 'DEKBED BAZAAR | ',
                description: 'Artikel ',
            },
            account:{
                personal_info: {
                    title: 'DEKBED BAZAAR | Konto | persönliche Informationen',
                    description: 'persönliche Informationen',
                },
                wishlist: {
                    title: 'DEKBED BAZAAR | Konto | Wunschliste',
                    description: 'Wunschliste',
                },
                orders: {
                    title: 'DEKBED BAZAAR | Konto | Bestellliste',
                    description: 'Bestellliste',
                },
                order: {
                    title: 'DEKBED BAZAAR | Konto | Bestelldetails',
                    description: 'Bestelldetails',
                },
                address: {
                    title: 'DEKBED BAZAAR | Konto | Adressliste',
                    description: 'Adressliste',
                },
                address_form: {
                    title: 'DEKBED BAZAAR | Konto | Adressbearbeitung',
                    description: 'Adressbearbeitung',
                },
                address_new: {
                    title: 'DEKBED BAZAAR | Konto | Neue Adresse hinzufügen',
                    description: 'Neue Adresse hinzufügen',
                },
                address_edit: {
                    title: 'DEKBED BAZAAR | Konto | Adressbearbeitung',
                    description: 'Adressbearbeitung',
                },
                address_add: {
                    title: 'DEKBED BAZAAR | Konto | Adresse hinzufügen',
                    description: 'Adresse hinzufügen',
                },
            },
        },
        validation:{
            field_short: 'Feld ":field:" muss dann größer sein ":digits:" Länge',
            field_long:  'Feld ":field:" muss dann kleiner sein ":digits:" Länge',
            wrong_select:  'Feld ":field:" ungültig',
        },
        header: {
            shipping: 'Versand in die Niederlande, Belgien und die EU',
            lang: {
                en: 'English',
                fr: 'French',
                nl: 'Dutch',
                de: 'Germany',
            },
            short_lang: {
                en: 'En',
                fr: 'Fr',
                nl: 'Nl',
                de: 'De',
            },
        },
        subscribe: {
            title: 'Möchten Sie Stilideen und Angebote erhalten?',
            button_title: 'Abonnieren',
        },
        logout: {
            title: 'Abmeldung erfolgreich.',
            button_title: 'Zur Startseite',
            wrong: 'Etwas ist schiefgegangen.',
            try_again: 'Erneut versuchen.',
        },
        login: {
            title: 'Zurückkehrender Kunde',
            email: 'E-Mail-Adresse *',
            password: 'Passwort *',
            remember: 'An mich erinnern',
            forgot_password: 'Passwort vergessen?',
            sign_in: 'Anmelden',
            dont_have_account: 'Sie haben noch kein Konto?',
            create_free_account: 'Kostenloses Konto erstellen',
            errors: {
                email: {
                    short: 'E-Mail ist zu kurz',
                    long: 'E-Mail ist zu lang',
                    invalid: 'E-Mail ist ungültig',
                },
                password: {
                    short: 'Passwort ist zu kurz',
                    long: 'Passwort ist zu lang',
                    invalid: 'Passwort ist ungültig',
                },
                username: {
                    short: 'Benutzername muss mindestens 5 Zeichen enthalten',
                    long: 'Benutzername ist zu lang',
                },
            }
        },

        registration: {
            new_customer: 'Neuer Kunde',
            have_account: 'Sie haben bereits ein Konto?',
            login_now: 'Jetzt anmelden',
            first_name: 'Vorname *',
            last_name: 'Nachname *',
            email: 'E-Mail-Adresse *',
            password: 'Passwort *',
            text1: 'Durch die Registrierung Ihrer Daten stimmen Sie unseren ',
            text2: ', und ',
            term_and_conditions: 'Allgemeine Geschäftsbedingungen',
            privacy_policy: 'Datenschutz- und Cookie-Richtlinien',
            newsletter: 'Melden Sie sich für den Newsletter an!',
            register: 'Registrieren',
        },
        restore: {
            title: 'Passwort vergessen?',
            text: 'Bitte geben Sie Ihre E-Mail-Adresse ein. Sie erhalten einen Link, um ein neues Passwort per E-Mail zu erstellen.',
            email: 'E-Mail-Adresse *',
            button_title: 'Passwort zurücksetzen',
        },
        footer: {
            support: 'Support',
            shop: 'Shop',
            company: 'Unternehmen',
            contact: 'Kontakt',
            all_rights: 'Alle Rechte vorbehalten.',
        },
        links: {
            shipping: 'Versand',
            faq: 'FAQ',
            contacts: 'Kontakt',
            contact_us: 'Kontaktiere uns',
            faqs: 'FAQs',
            size: 'Größenberatung',
            returns: 'Versand & Rücksendung',
            our_story: 'Unsere Geschichte',
            careers: 'Karriere',
            terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
            privacy_cookie_policy: 'Datenschutz- & Cookie-Richtlinie',
        },
        promo: {
            free_shipping: 'Kostenloser Versand',
            free_shipping_from: 'Bei allen Bestellungen über',
            free_returns: 'Kostenlose Rücksendungen',
            free_returns_text: 'Geld innerhalb von %period% Tagen zurückerstatten',
            secure_shopping: 'Sicher einkaufen',
            secure_shopping_text: 'Sie sind in sicheren Händen',
            styles_title: 'Über %amount% Stile',
            styles_text: 'Wir haben alles, was Sie brauchen',
        },
        category: {
            sorting: {
                default: 'Standard-Sortierung',
                popular: 'Nach Beliebtheit sortieren',
                rating: 'Nach Durchschnittsbewertung sortieren',
                latest: 'Nach Neuesten sortieren',
                pricea: 'Nach Preis sortieren: von niedrig nach hoch',
                priced: 'Nach Preis sortieren: von hoch nach niedrig',
            },
            filters: {
                price: 'Preis',
                less_then: 'Weniger als ',
                or_less: 'oder weniger',
                greater_then: 'Größer als ',
                category: 'Kategorie',
                filter: 'Filter',
                clear: 'Klar',
            },
            no_items: {
                title: 'Artikel nicht gefunden',
                text: 'Versuchen Sie es mit anderen Filtern oder in einer anderen Kategorie',
                button_home_title: 'Zur Startseite',
            },
            filter_button: {
                show_button: 'Filter anzeigen',
                hide_button: 'Filter ausblenden',
            }
        },
        product: {
            reviews: 'Bewertungen',
            in_stock: 'Auf Lager',
            out_of_stock: 'Nicht vorrätig',
            add_to_cart: 'In den Warenkorb',
            product_details: 'Produktdetails',
            size_fit: 'Größe & Passform',
            shipping_returns: 'Versand & Rücksendung',
            wishlist: 'Wunschliste',
            suggest: 'Vielleicht gefällt Ihnen auch',
            sale: 'Sale',
            new: 'Neu',
            not_found: {
                title: '404. Produkt nicht gefunden.',
                text1: 'Entschuldigung, wir konnten das gesuchte Produkt nicht finden.',
                text2: 'Wir empfehlen Ihnen, zur Startseite zurückzukehren.',
                action: 'Zur Homepage gehen',
            }
        },
        review: {
            title: 'Kundenbewertungen',
            text: 'Es wurden noch keine Bewertungen veröffentlicht. Platzieren Sie jetzt eine Bestellung, um eine Bewertung abzugeben.',
            smile: '🙁',
            title_button: 'Bewertung schreiben',
            score: 'Bewertung',
            input_title_placeholder: 'Titel der Bewertung',
            input_text_placeholder: 'Bewertung',
            title_submit_button: 'Bewertung veröffentlichen',
            helpful: 'War diese Bewertung hilfreich?',
            already_placed: 'Sie haben bereits eine Bewertung abgegeben',
            load_more: 'Mehr laden...',
            error: {
                score_error: 'Bewertung muss im Bereich von [1,5] liegen',
                title_error: 'Titel muss im Bereich von [5,255] liegen',
                text_error: 'Text muss im Bereich von [5,4000] liegen',
            },
        },
        cart: {
            title: 'Ihr Warenkorb',
            subtotal: 'Zwischensumme',
            button_title_continue_to_checkout: 'Weiter zur Kasse',
            button_title_back_to_shopping: 'Zurück zum Einkaufen',
            empty: {
                text: 'Ihr Warenkorb ist leer 😞',
                button_title_continue: 'Weiter einkaufen',
            },
            item: {
                image_placeholder: 'Artikel-Vorschau-Bild',
                remove: 'Entfernen',
            }
        },
        account: {
            menu: {
                order: 'Bestellungen',
                wishlist: 'Wunschliste',
                info: 'Persönliche Informationen',
                address: 'Adressen',
                payment: 'Zahlungsmethoden',
                logout: 'Abmelden',
            },
            wishlist: {
                title: 'DEKBEDBAZAAR | Meine Wunschliste',
                description: 'einige Texte mit Beschreibung',
                page_title: 'Meine Wunschliste',
                quick_view: 'Schnellansicht',
                no_items: 'Es tut uns leid, aber Sie haben keine Artikel auf Ihrer Wunschliste',
            },
            order_list: {
                title: 'Mein Konto',
                order_number: 'Bestellnummer:',
                order_date: 'Versanddatum:',
                order_status: 'Status:',
                order_amount: 'Betrag:',
                order_more: 'mehr',
                order_details: 'Bestelldetails',
                order_track: 'Bestellung verfolgen',
            },
            order_detail: {
                title: 'Meine Bestellnummer #',
                item_list_title: 'Bestellte Artikel',
                item_payment_title: 'Bestellsumme',
                order_subtotal: 'Zwischensumme',
                order_discount: 'Rabatt',
                order_shipping: 'Versand',
                order_total: 'Gesamt',
                order_info_title: 'Rechnungs- und Lieferdetails',
                order_billing_address: 'Rechnungsadresse',
                order_shipping_address: 'Lieferadresse',
                order_shipping_method: 'Versandmethode',
                order_payment_method: 'Zahlungsmethode',
            },
            info: {
                title: 'DEKBEDBAZAAR | Persönliche Informationen',
                description: 'einige Texte mit Beschreibung',
                page_title: 'Mein Konto',
                success: 'Ihre persönlichen Informationen wurden erfolgreich aktualisiert',
                field_title: {
                    first_name: 'Vorname *',
                    last_name: 'Nachname *',
                    username: 'Anzeigename',
                    phone: 'Telefonnummer *',
                    email: 'E-Mail-Adresse',
                    email_read_only: 'Sie können die E-Mail nicht ändern',
                    birth: 'Geburtsdatum',
                    gender: 'Geschlecht',
                    male: 'Männlich',
                    female: 'Weiblich',
                    day: 'Tag',
                    month: 'Monat',
                    year: 'Jahr',
                    button: 'Änderungen speichern',
                },
            },
            address_list: {
                title: 'Meine Adressliste',
                add_address_title: 'Adresse hinzufügen',
            },
            address_form: {
                title: 'Adresse bearbeiten',
                button_title: 'Adresse speichern',
            },
            address_create_form: {
                title: 'Adresse erstellen',
                button_title: 'Adresse erstellen',
            },
        },
        main_page: {
            shop_now: 'Jetzt einkaufen ',
            newest_by_category: 'Neuestes aus den Kategorien ',
            newest_items: 'Neueste Artikel ',
            populars_items: 'Beliebte Artikel ',
        },
        article: {
            not_found: {
                title: 'Artikel nicht gefunden',
                text: 'Der von Ihnen angeforderte Artikel wurde nicht gefunden, deaktiviert oder entfernt',
                go_home: 'Zur Startseite',
                refresh: 'Seite aktualisieren',
            },
            last_updated: 'Zuletzt aktualisiert am',
        },
        checkout: {
            breadcrumb: {
                home: 'Startseite',
                checkout: 'Kasse',
            },
            form: {
                title: 'Kasse',
                you_have_account: 'Sie haben bereits ein Konto?',
                click_to_login: 'Hier klicken, um sich anzumelden',
                billing_details: 'Rechnungsdetails',
            },
            order_items: 'Bestellte Artikel',
            subtotal: 'Zwischensumme (inkl. MwSt.)',
            discount: 'Rabatt',
            shipping: 'Versand',
            total: 'Gesamt',
            order_text_info: 'Ihre persönlichen Daten werden verwendet, um Ihre Bestellung zu bearbeiten, Ihre Erfahrung auf dieser Website zu unterstützen und für andere in unserer Datenschutzerklärung beschriebene Zwecke.',
            order_button_title: 'Bestellung aufgeben',
            promo_code_title: 'Rabattcode',
            promo_code_placeholder: 'Rabattcode',
            completed: {
                emoji: '❤️',
                title: 'Ihre Bestellung wurde aufgegeben!',
                text1: 'Ihre Bestellung',
                text2: 'wurde\n' +
                    'aufgegeben. Die Details Ihrer Bestellung\n' +
                    'werden in Ihrem persönlichen Konto angezeigt.',
                button_title: 'Meine Bestellungen anzeigen',
            },
        },
        month: {
            january : 'Januar',
            february : 'Februar',
            march : 'März',
            april : 'April',
            may : 'Mai',
            june : 'Juni',
            july : 'Juli',
            august : 'August',
            september : 'September',
            october : 'Oktober',
            november : 'November',
            december : 'Dezember',
        },
        forms: {
            billingFirstName: {
                title: 'Vorname *',
                placeholder: 'Vorname',
            },
            billingLastName: {
                title: 'Nachname *',
                placeholder: 'Nachname',
            },
            billingEmail: {
                title: 'E-Mail *',
                placeholder: 'E-Mail',
            },
            billingCompany: {
                title: 'Firmenname',
                placeholder: 'Firmenname (optional)',
            },
            billingCountry: {
                title: 'Land',
                placeholder: 'Land *',
            },
            billingAddress1: {
                title: 'Adresse Zeile 1 *',
                placeholder: 'Adresse Zeile 1',
            },
            billingAddress2: {
                title: 'Adresse Zeile 2',
                placeholder: 'Adresse Zeile 2',
            },
            billingCity: {
                title: 'Stadt *',
                placeholder: 'Stadt',
            },
            billingZip: {
                title: 'PLZ *',
                placeholder: 'PLZ',
            },
            billingMobile: {
                title: 'Mobiltelefon *',
                placeholder: 'Mobiltelefon',
            },
            shippingCompany: {
                title: 'Firmenname',
                placeholder: 'Firmenname (optional)',
            },
            shippingCountry: {
                title: 'Land',
                placeholder: 'Land *',
            },
            shippingAddress1: {
                title: 'Adresse Zeile 1 *',
                placeholder: 'Adresse Zeile 1',
            },
            shippingAddress2: {
                title: 'Adresse Zeile 2',
                placeholder: 'Adresse Zeile 2',
            },
            shippingCity: {
                title: 'Stadt *',
                placeholder: 'Stadt',
            },
            shippingZip: {
                title: 'PLZ *',
                placeholder: 'PLZ',
            },
            note: {
                title: 'Bestellnotizen (optional)',
                placeholder: 'Bestellnotizen (optional)',
            },
            email: {
                title: 'E-Mail *',
                placeholder: 'E-Mail',
            },
            company: {
                title: 'Firmenname',
                placeholder: 'Firmenname (optional)',
            },
            country: {
                title: 'Land',
                placeholder: 'Land *',
            },
            addressType: {
                title: 'Adresstyp',
                placeholder: 'Adresstyp *',
            },
            address1: {
                title: 'Adresse Zeile 1 *',
                placeholder: 'Adresse Zeile 1',
            },
            address2: {
                title: 'Adresse Zeile 2 *',
                placeholder: 'Adresse Zeile 2',
            },
            city: {
                title: 'Stadt *',
                placeholder: 'Stadt',
            },
            zip: {
                title: 'PLZ *',
                placeholder: 'PLZ',
            },
            defaultShipping: {
                title: 'Standard-Versandadresse',
                placeholder: 'Standard-Versandadresse',
            },
            defaultBilling: {
                title: 'Standard-Rechnungsadresse',
                placeholder: 'Standard-Rechnungsadresse',
            },
            name: {
                title: 'Name',
                placeholder: 'Name',
            },
        }
    },
};