import {text} from "../../config/i18n/text";
import React from "react";
import {FieldValidate} from "../validators/FieldValidate";

export const buildForm = (fields, form, lang, valueChanged, fieldFocus, fieldBlur, disabled = false) => {
    return fields.map((item, index) => {
        switch (item.type) {
            case 'checkbox':
                const errorTextCheckbox = '' === form.errors[item.name]
                    ? null
                    : <div className='text-danger'>{form.errors[item.name]}</div>
                const key = 'form-field-checkbox' + index + '-' + item.name;

                return <div className="form-group"  key={key}>
                    <div className="form-check">
                        <div className="form-check mb-0">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={key}
                                required={item.required}
                                checked={true === form.values[item.name]}
                                onChange={(e) => {
                                    valueChanged(item.name, e.target.checked)
                                }}
                                onFocus={() => {
                                    fieldFocus(item.name)
                                }}
                                onBlur={() => {
                                    fieldBlur(item.name, item.validators)
                                }}
                                disabled={disabled === true ? disabled : item.disabled === true}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={key}
                            >{text[lang].forms[item.name].title}</label>&nbsp; {errorTextCheckbox}
                        </div>
                    </div>
                </div>
            case 'select':
                const options = item.values.map((op, index2) => {
                    return <option
                        value={op.code}
                        key={'form-field' + index + '-' + item.name + '-option-' + index2 + '-' + op.code}
                    >{op.title}</option>;
                });

                const DefaultSelectClass = null === form.valid[item.name]
                    ? "form-control form-control-sm mb-2"
                    : (form.valid.hasOwnProperty(item.name) && true === form.valid[item.name])
                        ? "form-control form-control-sm is-valid mb-2"
                        : "form-control form-control-sm is-invalid mb-2"
                ;
                const DefaultSelectLabelClass = null === form.valid[item.name]
                    ? "form-label"
                    : (true === form.valid[item.name])
                        ? "form-label is-valid"
                        : "form-label is-invalid"
                ;

                const errorSelectText = '' === form.errors[item.name]
                    ? null
                    : <div className='text-danger'>{form.errors[item.name]}</div>


                return <div
                    className={'pb-4 col-' + item.size + ' col-md-' + item.size_md}
                    key={'form-field' + index + '-' + item.name}
                >
                    <label
                        className={DefaultSelectLabelClass}
                        htmlFor={'col-' + item.size + ' col-md-' + item.size_md}
                    >{text[lang].forms[item.name].title}
                    </label>
                    <select
                        value={form.values[item.name]}
                        id={'col-' + item.size + ' col-md-' + item.size_md}
                        onChange={(e) => {
                            valueChanged(item.name, e.target.value);
                        }}
                        onFocus={() => {
                            fieldFocus(item.name)
                        }}
                        onBlur={() => {
                            fieldBlur(item.name, item.validators)
                        }}
                        className={DefaultSelectClass}
                        disabled={disabled === true ? disabled : item.disabled === true}
                    >
                        <option value="-">{text[lang].forms[item.name].placeholder}</option>
                        {options}
                    </select>
                    {errorSelectText}
                </div>;
            default:
                const DefaultFieldClass = null === form.valid[item.name]
                    ? "form-control form-control-sm"
                    : (form.valid.hasOwnProperty(item.name) && true === form.valid[item.name])
                        ? "form-control form-control-sm is-valid"
                        : "form-control form-control-sm is-invalid"
                ;
                const DefaultLabelClass = null === form.valid[item.name]
                    ? "form-label"
                    : (true === form.valid[item.name])
                        ? "form-label is-valid"
                        : "form-label is-invalid"
                ;

                const errorText = '' === form.errors[item.name]
                    ? null
                    : <div className='text-danger'>{form.errors[item.name]}</div>
                return <div className={'col-' + item.size + ' col-md-' + item.size_md}
                            key={'form-field' + index + '-' + item.name}>
                    <div className="form-group">
                        <label
                            className={DefaultLabelClass}
                            htmlFor={item.name}
                        >{text[lang].forms[item.name].title}
                        </label>
                        <input
                            className={DefaultFieldClass}
                            id={item.name}
                            type={item.type}
                            value={form.values[item.name] ?? ''}
                            placeholder={text[lang].forms[item.name].placeholder}
                            required={item.required}
                            onChange={(e) => {
                                valueChanged(item.name, e.target.value)
                            }}
                            onFocus={() => {
                                fieldFocus(item.name)
                            }}
                            onBlur={() => {
                                fieldBlur(item.name, item.validators)
                            }}
                            disabled={disabled === true ? disabled : item.disabled === true}
                        />
                        {errorText}
                    </div>
                </div>;
        }
    });
}

export const makeValueChanged = (dispatch, action) => {
    //setFormValue
    return (field, value) => {
        dispatch(action({
            field: field,
            value: value,
        }));
    }
}

export const makeFieldFocus = (dispatch, action) => {
    //setFormFocus
    return (field) => {
        dispatch(action(field));
    }
}

export const makeFieldBlur = (dispatch, action, lang, form) => {
    //setFormBlur
    return (field, filters) => {
        const answerName = FieldValidate(field, filters, lang, form);
        dispatch(action({
            field: field,
            isValid: answerName.valid,
            error: answerName.error,
        }));
    }
}

export const triggerAllValidators = (dispatch, action, lang, form, fields) => {
    let allValid = true;
    for(let i in form) {
        const validationAnswer = FieldValidate(form[i].name, form[i].validators, lang, fields);
        if(validationAnswer !== undefined){
            if(false === validationAnswer.valid){
                allValid = false;
            }
            dispatch(action({
                field: form[i].name,
                isValid: validationAnswer.valid,
                error: validationAnswer.error,
            }));
        }
    }
    return allValid;
}

export default buildForm;