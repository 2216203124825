import React, {useState} from "react";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import moment from "moment";
import StarRatingDisplay from "../StarRatingDisplay";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserData, getUserToken} from "../../../redusers/UserReducer";
import {updateReviewLikes} from "../../../redusers/ProductReducer";

export const ReviewElementBlock = (data) => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const user = useAppSelector(getUserData);
    const token = useItemListSelector(getUserToken);
    const server = useAppSelector(getServer);
    moment.locale(lang ?? 'nl');

    const [positivePredictive, setPositivePredictive] = useState(0);
    const [negativePredictive, setNegativePredictive] = useState(0);
    const [status, setStatus] = useState(NetworkStatus.idle);

    function like() {
        if (user.userId !== data.info.author.id && status === NetworkStatus.idle) {
            setStatus(NetworkStatus.loading);
            req(+1);
            if (true === data.info.isDisLiked) {
                setNegativePredictive(0);
                if (true === data.info.isLiked) {
                    setPositivePredictive(-1);
                } else {
                    setPositivePredictive(1)
                }
            } else {
                if (true === data.info.isLiked) {
                    setPositivePredictive(-1);
                } else {
                    setPositivePredictive(1)
                }
            }
        }
    }
    function dislike() {
        if (user.userId !== data.info.author.id && status === NetworkStatus.idle) {
            setStatus(NetworkStatus.loading);
            req(-1);
            if (true === data.info.isLiked) {
                setPositivePredictive(0);
                if (true === data.info.isDisLiked) {
                    setNegativePredictive(-1);
                } else {
                    setNegativePredictive(1)
                }
            } else {
                if (true === data.info.isDisLiked) {
                    setNegativePredictive(-1);
                } else {
                    setNegativePredictive(1)
                }
            }
        }
    }

    let likeButtonClass = ['fe', 'fe-thumbs-up'];

    if (status === NetworkStatus.loading) {
        likeButtonClass.push('alert-light')
    } else {
        if(data.info.isLiked || positivePredictive > 0) {
            likeButtonClass.push('text-success')
        }
    }

    let dislikeButtonClass = ['fe', 'fe-thumbs-down'];

    if (status === NetworkStatus.loading) {
        dislikeButtonClass.push('alert-light')
    } else {
        if (data.info.isDisLiked || negativePredictive > 0) {
            dislikeButtonClass.push('text-primary')
        }
    }

    function req(score){
        const url = score > 0 ? apiRoutes.itemReviewLike : apiRoutes.itemReviewDisLike;
        MakeRequest(getUrl(url, server) + '?id=' + data.info.id,
            'GET',
            {},
            token,
            (response) => {
                console.info(response.data.data);
                setStatus(NetworkStatus.idle);
                dispatch(updateReviewLikes({
                    itemId: data.info.id,
                    isDisLiked: response.data.data.isDisLiked,
                    isLiked: response.data.data.isLiked,
                    negativeScore: response.data.data.dislikes,
                    positiveScore: response.data.data.likes,
                }));
                setPositivePredictive(0);
                setNegativePredictive(0);
            },
            (e) => { console.error('req error', e); setStatus(NetworkStatus.fail); });
    }

    const classes = ['review', 'text-start', 'alert', user.userId === data.info.author.id && 'alert-success'];
    return <div className={classes.join(' ')}>
        <div className="review-body">
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                    <div className="avatar avatar-xxl mb-6 mb-md-0">
                  <span className="avatar-title rounded-circle">
                    <i className="fa fa-user"></i>
                  </span>
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                    <div className="row mb-6">
                        <div className="col-12">
                            <StarRatingDisplay score={data.info.score} />
                        </div>
                        <div className="col-12">
                            <span className="fs-xs text-muted">
                                {data.info.author.displayName}, {moment(data.info.createdAt).fromNow()}
                            </span>
                        </div>
                    </div>
                    <p className="mb-2 fs-lg fw-bold">{data.info.title}</p>
                    <p className="text-gray-500">{data.info.text}</p>
                    <div className="row align-items-center">
                        <div className="col-auto d-none d-lg-block">
                            <p className="mb-0 fs-sm">{text[lang].review.helpful}</p>
                        </div>
                        <div className="col-auto me-auto">
                            <div className={user.userId === data.info.author.id ? "rate c-cross" : "rate c-pointer"}>
                                <span className="rate-item" onClick={() => {like()}}>
                                    <i className={likeButtonClass.join(' ')}></i>
                                    &nbsp;<span>{(data.info.positiveScore + positivePredictive) ?? 0}</span>
                                </span>
                                <span className="rate-item" onClick={() => {dislike()}}>
                                    <i className={dislikeButtonClass.join(' ')}></i>
                                    &nbsp;<span>{(data.info.negativeScore + negativePredictive) ?? 0}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<div className="review review-child">*/}
        {/*    <div className="review-body">*/}
        {/*        <div className="row">*/}
        {/*            <div className="col-12 col-md-4 col-lg-3 col-xl-2">*/}
        {/*                <div className="avatar avatar-xxl mb-6 mb-md-0">*/}
        {/*            <span className="avatar-title rounded-circle">*/}
        {/*              <i className="fa fa-user"></i>*/}
        {/*            </span>*/}
        {/*                </div>*/}

        {/*            </div>*/}
        {/*            <div className="col-12 col-md-8 col-lg-9 col-xl-10">*/}
        {/*                <div className="row mb-6">*/}
        {/*                    <div className="col-12">*/}
        {/*                        <div className="rating fs-sm text-dark" data-value="4">*/}
        {/*                            <div className="rating-item">*/}
        {/*                                <i className="fas fa-star"></i>*/}
        {/*                            </div>*/}
        {/*                            <div className="rating-item">*/}
        {/*                                <i className="fas fa-star"></i>*/}
        {/*                            </div>*/}
        {/*                            <div className="rating-item">*/}
        {/*                                <i className="fas fa-star"></i>*/}
        {/*                            </div>*/}
        {/*                            <div className="rating-item">*/}
        {/*                                <i className="fas fa-star"></i>*/}
        {/*                            </div>*/}
        {/*                            <div className="rating-item">*/}
        {/*                                <i className="fas fa-star"></i>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}

        {/*                    </div>*/}
        {/*                    <div className="col-12">*/}
        {/*                                <span className="fs-xs text-muted">*/}
        {/*                William Stokes, <time dateTime="2019-07-14">14 Jul 2019</time>*/}
        {/*              </span>*/}

        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <p className="mb-2 fs-lg fw-bold">*/}
        {/*                    Very good*/}
        {/*                </p>*/}
        {/*                <p className="text-gray-500">*/}
        {/*                    Made face lights yielding forth created for image behold blessed seas.*/}
        {/*                </p>*/}
        {/*                <div className="row align-items-center">*/}
        {/*                    <div className="col-auto d-none d-lg-block">*/}
        {/*                        <p className="mb-0 fs-sm">Was this review helpful?</p>*/}
        {/*                    </div>*/}
        {/*                    <div className="col-auto me-auto">*/}
        {/*                        <div className="rate">*/}
        {/*                            <a className="rate-item" data-toggle="vote" data-count="7" href="#!"*/}
        {/*                               role="button">*/}
        {/*                                <i className="fe fe-thumbs-up"></i>*/}
        {/*                            </a>*/}
        {/*                            <a className="rate-item" data-toggle="vote" data-count="0" href="#!"*/}
        {/*                               role="button">*/}
        {/*                                <i className="fe fe-thumbs-down"></i>*/}
        {/*                            </a>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div className="col-auto d-none d-lg-block">*/}
        {/*                        <p className="mb-0 fs-sm">Comments (0)</p>*/}
        {/*                    </div>*/}
        {/*                    <div className="col-auto">*/}
        {/*                        <a className="btn btn-xs btn-outline-border" href="#!">*/}
        {/*                            Comment*/}
        {/*                        </a>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
    ;
}

export default ReviewElementBlock;