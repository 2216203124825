import imagePlaceholder from "../../../assets/db-placeholder.png";
import Money from "../money/Money";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {Link, useNavigate} from "react-router-dom";
import {makeItem} from "../../../routes/routes";
import {changeItemsCount} from "../../../redusers/CartReducer";
import {removeItem} from "../../../redusers/CartReducer";

export const CartItemBlock = (data) => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const lang = useAppSelector(getLang);

    const countChange = (count) => {
        dispatch(changeItemsCount({
            itemOrderId: data.itemOrderId,
            count: parseInt(count.target.value)
        }));
    }

    const remove = () => {
        dispatch(removeItem({itemOrderId: data.itemOrderId}));
    }

    const linkClick = (url) => {
        navigate(url);
    }

    return <li className="list-group-item">
        <div className="row align-items-center">
            <div className="col-4">
                <Link
                    className="text-body"
                    to={makeItem(data.item.item.id)}
                    data-bs-dismiss="offcanvas"
                    onClick={() => {linkClick(makeItem(data.item.item.id));}}
                >
                    <img className="img-fluid" src={data?.item?.item?.images[0]?.thumb ?? imagePlaceholder} alt={text[lang].cart.item.image_placeholder}/>
                </Link>

            </div>
            <div className="col-8">
                <p className="fs-sm fw-bold mb-6">
                    <Link
                        className="text-body"
                        to={makeItem(data.item.item.id)}
                        onClick={() => {linkClick(makeItem(data.item.item.id));}}
                        data-bs-dismiss="offcanvas"
                    >{data.item.item.title}</Link> {data.item.count > 1 ? <span className='text-warning'>* {data.item.count}</span> : null}<br/>
                    <span className="text-muted"><Money amount={data.item.total}/></span>
                </p>
                <div className="d-flex align-items-center">
                    <select
                        className="form-select form-select-xxs w-auto"
                        value={data.item.count}
                        onChange={(e) => { countChange(e); }}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    <span
                        className="fs-xs text-gray-400 ms-auto c-pointer"
                        onClick={() => { remove(); }}
                    >
                        <i className="fe fe-x"></i> {text[lang].cart.item.remove}
                    </span>
                </div>
            </div>
        </div>
    </li>;
}

export default CartItemBlock;