import AvailableCountries from "./AvailableCountries";

export const ShippingFields = [
    {
        name: 'shippingCompany',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'shippingCountry',
        type: 'select',
        values: AvailableCountries,
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'select', validValues: AvailableCountries}],
        meta: {},
        disabled: null,
    },
    {
        name: 'shippingAddress1',
        type: 'text',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
    {
        name: 'shippingAddress2',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'shippingCity',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 2}],
        meta: {},
        disabled: null,
    },
    {
        name: 'shippingZip',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
];

export default ShippingFields