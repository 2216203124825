export const ShippingVariantsModel = [
    {
        title: 'Standard Shipping (PostNL)',
        text: 'Delivery in 5 - 7 working days',
        value: 'SHIPPING_DHL',
        cost: 8,
    },
    {
        title: 'Express Shipping (DHL)',
        text: 'Delivery in 3 - 5 working days',
        value: 'SHIPPING_UPS',
        cost: 12,
    },
    {
        title: '1 - 2 Shipping (DHL)',
        text: 'Delivery in 1 - 2 working days',
        value: 'SHIPPING_UPS_FAST',
        cost: 18,
    },
    {
        title: 'Free Shipping (UPS)',
        text: 'Living won\'t the He one every subdue meat replenish face was you morning firmament darkness.',
        value: 'SHIPPING_POST_NL',
        cost: 0,
    }
];
export default ShippingVariantsModel;