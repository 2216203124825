import Header from "../parts/Header";
import React, {useEffect, useState} from "react";
import Footer from "../parts/Footer";
import InfoBlock from "../parts/block/InfoBlock";
import CheckoutBreadcrumb from "../parts/checkout/CheckoutBreadcrumb";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getInitServer, getInitUser, getLang, getServer, isUserAuth} from "../../redusers/InterfaceReducer";
import {text} from "../../config/i18n/text";
import CheckoutItemListBlock from "../parts/checkout/CheckoutItemListBlock";
import PlaceOrderBlock from "../parts/checkout/PlaceOrderBlock";
import BillingFormBlock from "../parts/checkout/BillingFormBlock";
import ShippingFormBlock from "../parts/checkout/ShippingFormBlock";
import PaymentFormBlock from "../parts/checkout/PaymentFormBlock";
import OrderNoteFormBlock from "../parts/checkout/OrderNoteFormBlock";
import {Helmet} from "react-helmet";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import FullPageLoader from "../parts/FulPageLoader";
import MakeRequest from "../../helpers/network/MakeRequest";
import {getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {clearCheckoutState, getPlaced, loadOrder} from "../../redusers/CheckoutReducer";
import {getUserToken} from "../../redusers/UserReducer";
import CheckoutPlacedBlock from "../models/checkout/CheckoutPlacedBlock";
import * as routers from "../../routes/routes";
import {Link, useLocation} from "react-router-dom";
import makeGetParams from "../../helpers/network/MakeGetParams";

export const CheckoutPage = () => {

    const dispatch = useAppDispatch();

    const auth = useAppSelector(isUserAuth);

    const lang = useAppSelector(getLang);

    const server = useAppSelector(getServer);
    const token = useAppSelector(getUserToken);

    const initServer = useAppSelector(getInitServer);
    const initUser = useAppSelector(getInitUser);

    const placed = useAppSelector(getPlaced);

    const [state, setState] = useState(NetworkStatus.loading);

    let location = useLocation();
    const orderId = location.pathname.replace('/checkout/','');

    useEffect(() => {
        if (true === initServer && true === initUser) {
            MakeRequest(getUrl(apiRoutes.orderPlace, server) + makeGetParams({id: orderId}),
                'get', {}, token, (response) => {

                dispatch(loadOrder({
                    items: response.data.data.items,
                    order: response.data.data.order,
                    promo: response.data.data.promo,
                    addresses: response.data.data.addresses,
                    defaultShipping: response.data.data.defaultShipping,
                    defaultBilling: response.data.data.defaultBilling,
                    billingFirstName: response.data.data.user.firstName,
                    billingLastName: response.data.data.user.lastName,
                    billingEmail: response.data.data.user.email,
                    billingMobile: response.data.data.user.phone,
                }));
                setState(NetworkStatus.idle);

            }, (e) => {
                if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                    setState(NetworkStatus.idle);
                } else {
                    console.error('response error is', e);
                    setState(NetworkStatus.fail);
                }
            });
        }
        return () => {
            dispatch(clearCheckoutState());
        }
    }, [initServer, initUser, orderId]);


    if ( true === placed && state === NetworkStatus.idle ) {
        return <>
            <Helmet>
                <title>- Order placed</title>
                <meta name="description" content='- Checkout Page' />
            </Helmet>
            <Header />
            <CheckoutPlacedBlock />
            <Footer />
        </>;
    }

    if(state === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>- Checkout Page</title>
                <meta name="description" content='- Checkout Page' />
            </Helmet>
            <Header />
            <FullPageLoader />
            <Footer />
        </>;
    }

    return <>
        <Helmet>
            <title>- Checkout Page</title>
            <meta name="description" content='- Checkout Page' />
        </Helmet>
        <Header />
        <CheckoutBreadcrumb />

        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-4">{text[lang].checkout.form.title}</h3>

                        { false === auth ? <p className="mb-10">
                            {text[lang].checkout.form.you_have_account} <Link
                            className="fw-bold text-reset"
                            to={routers.login}
                        >{text[lang].checkout.form.click_to_login}</Link>
                        </p> : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-7">
                        <form>
                            <BillingFormBlock />
                            <ShippingFormBlock />
                            <PaymentFormBlock />
                            <OrderNoteFormBlock />
                        </form>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 offset-lg-1">
                        <CheckoutItemListBlock />
                        <PlaceOrderBlock />
                    </div>
                </div>
            </div>
        </section>
        <InfoBlock />
        <Footer />
    </>;
}

export default CheckoutPage;