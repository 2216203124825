import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";
import React from "react";
import {useNavigate} from "react-router-dom";
import {makeItem} from "../../../../routes/routes";

export const GroupDisplayInDropdown = (data) => {

    const navigate = useNavigate();

    let dic = {};
    for (let i in data.connections ) {
        dic[data.connections[i].value] = data.connections[i].itemId;
    }


    const lang = useAppSelector(getLang);
    const list = data.connections.map(function (listItem, listIndex) {
        return <option
            key={'group-' + data.index + '-list-item-' + listIndex}
            value={listItem.name}
        >
            {listItem.titles[lang] ?? listItem.titles['en'] ?? listItem.title}
        </option>;
    });

    return <select
        defaultValue={data.current}
        onChange={(e) => navigate(makeItem(dic[e.target.value]))}
        disabled={false}
        className="form-select mb-2"
    >
        {list}
    </select>
}

export default GroupDisplayInDropdown;