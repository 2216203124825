import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import {useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getCategoryStatus, getFilters} from "../../../redusers/ItemsListReducer";
import {Link} from "react-router-dom";
import {makeCategory} from "../../../routes/routes";
import React from "react";
import {text} from "../../../config/i18n/text";
import {getLang} from "../../../redusers/InterfaceReducer";

export const CategoryList = (data) => {
    const filters = useItemListSelector(getFilters);
    const categoryStatus = useItemListSelector(getCategoryStatus);
    const lang = useAppSelector(getLang);

    if(categoryStatus !== NetworkStatus.idle || filters.length === 0 || data.subCategories.length === 0){
        return null;
    }

    const subCategoryList = data.subCategories.map((item, index) => {
        return <li key={'item-key' + item.id + '-' + index} className={'list-styled-item'}>
            <Link className='list-styled-link' to={makeCategory(item.slug)}>
                {item.titles[lang] ?? item.titles['en'] ?? item.title}
            </Link>
        </li>;
    });



    return <li className="nav-item">
        <a className="nav-link dropdown-toggle fs-lg text-reset border-bottom mb-6"
           data-bs-toggle="collapse" href="#categoryCollapse">
            {text[lang].category.filters.category}
        </a>
        <div className="collapse show" id="categoryCollapse">
            <div className="form-group">
                <ul className="list-styled mb-0" id="productsNav">
                    {subCategoryList}
                </ul>
            </div>
        </div>

    </li>;
}

export default CategoryList;