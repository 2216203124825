import FieldLengthValidator from "./FieldLengthValidator";
import validateEmail from "./EmailValidator";
import SelectValidator from "./SelectValidator";

export const FieldValidate = (field, filters, lang, form) => {
    let answer;
    for(const i in filters){
        switch (filters[i].type) {
            case 'name':
                answer = FieldLengthValidator(form.values[field], lang, field, 3, 32);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            case 'email':
                answer = validateEmail(form.values[field], lang);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            case 'minLength':
                answer = FieldLengthValidator(form.values[field], lang, field, 3, null);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            case 'maxLength':
                answer = FieldLengthValidator(form.values[field], lang, field, null, 30);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            case 'phone':
                answer = FieldLengthValidator(form.values[field], lang, field, 10, 12);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            case 'select':
                answer = SelectValidator(form.values[field], lang, field, filters[i].validValues);
                if(false === answer.valid) {
                    return answer;
                }
                break;
            default:
                console.error('changed filter type not found', filters[i].type);
        }
    }
    return answer;
}