import AvailableCountries from "./AvailableCountries";

export const BillingFields = [
    {
        name: 'billingFirstName',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'name'}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingLastName',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'name'}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingEmail',
        type: 'email',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'email'}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingCompany',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingCountry',
        type: 'select',
        values: AvailableCountries,
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'select', validValues: AvailableCountries}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingAddress1',
        type: 'text',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingAddress2',
        type: 'text',
        required: false,
        size: 12,
        size_md: 12,
        validators: [{type : 'maxLength', value: 255}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingCity',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 2}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingZip',
        type: 'text',
        required: true,
        size: 12,
        size_md: 6,
        validators: [{type : 'maxLength', value: 255}, {type : 'minLength', value: 5}],
        meta: {},
        disabled: null,
    },
    {
        name: 'billingMobile',
        type: 'text',
        required: true,
        size: 12,
        size_md: 12,
        validators: [{type : 'phone'}],
        meta: {},
        disabled: null,
    },
];

export default BillingFields