import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    list: [],
    address: {},

    formValues: {
        addressType: '',
        company: '',
        country: '',
        address1: '',
        address2: '',
        name: '',
        city: '',
        zip: '',
        defaultShipping: false,
        defaultBilling: false,
    },
    formErrors: {
        addressType: '',
        company: '',
        country: '',
        address1: '',
        address2: '',
        name: '',
        city: '',
        zip: '',
        defaultShipping: '',
        defaultBilling: '',
    },
    formValid: {
        addressType: null,
        company: null,
        country: null,
        address1: null,
        address2: null,
        name: null,
        city: null,
        zip: null,
        defaultShipping: null,
        defaultBilling: null,
    },
};

export const userSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setList: (state, action) => {
            state.list = action.payload;
        },
        setFormValue: (state, action) => {
            state.formValues[action.payload.field] = action.payload.value
        },
        setFormFocus: (state, action) => {
            state.formValid[action.payload] = null;
            state.formErrors[action.payload] = '';
        },
        setFormBlur: (state, action) => {
            state.formValid[action.payload.field] = action.payload.isValid;
            state.formErrors[action.payload.field] = action.payload.error;
        },
        loadAddress: (state, action) => {
            state.address = action.payload;

            state.formValues.addressType = action.payload.type;
            state.formValues.company = action.payload.company;
            state.formValues.country = action.payload.country;
            state.formValues.address1 = action.payload.line1;
            state.formValues.address2 = action.payload.line2;
            state.formValues.name = action.payload.name;
            state.formValues.city = action.payload.city;
            state.formValues.zip = action.payload.zipCode;
            state.formValues.defaultShipping = action.payload.isDefaultShipping;
            state.formValues.defaultBilling = action.payload.isDefaultBilling;

            state.formErrors.addressType = '';
            state.formErrors.company = '';
            state.formErrors.country = '';
            state.formErrors.address1 = '';
            state.formErrors.address2 = '';
            state.formErrors.name = '';
            state.formErrors.city = '';
            state.formErrors.zip = '';
            state.formErrors.defaultShipping = '';
            state.formErrors.defaultBilling = '';

            state.formValid.addressType = null;
            state.formValid.company = null;
            state.formValid.country = null;
            state.formValid.address1 = null;
            state.formValid.address2 = null;
            state.formValid.name = null;
            state.formValid.city = null;
            state.formValid.zip = null;
            state.formValid.defaultShipping = null;
            state.formValid.defaultBilling = null;
        },
        clearAddressState: (state) => {
            state.formValues = initialState.formValues;
            state.formErrors = initialState.formErrors;
            state.formValid  = initialState.formValid;
            state.list = initialState.list;
            state.address = initialState.address;
        },
    },
});

export const {
    setList,
    setFormValue,
    setFormFocus,
    setFormBlur,
    loadAddress,
    clearAddressState,
} = userSlice.actions;

export const getAddressList = (state) => state.address.list;
export const getAddressForm = (state) => {
    return {
        values: state.address.formValues,
        errors: state.address.formErrors,
        valid:  state.address.formValid,
    }};
export default userSlice.reducer;