import React from "react";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";

export const ColorFilter = (data) => {

    const lang = useAppSelector(getLang);

    const values = Object.keys(data.values).map((e, i) => {

        const style = data.values[e].name === ':mixed:'
            ? {background: "conic-gradient(from 315deg, rgba(255,0,0,1), rgba(9,121,12,1), rgba(6,43,148,1), rgba(239,255,0,1), rgba(189,3,177,1))"}
            : { backgroundColor: data.values[e].name}
        ;
        return <div className="form-check form-check-color mb-3" key={'color-filter-inside-' + data.id + '-id-' + data.values[e].id}>
            <input
                className="form-check-input"
                id={'color-filter-' + i + '-id-' + data.values[e].id}
                type="checkbox"
                style={style}
                onChange={(event) => {console.warn('color changed', event.target.value, data.values[e], data.id); data.onChange(data.id,data.values[e].id, event.target.value); }}
                checked={data.values[e].value}
            />
            <label className="form-check-label text-body"
                   htmlFor={'color-filter-' + i + '-id-' + data.values[e].id}>
                {data.values[e].titles[lang] ?? data.values[e].titles['en'] ?? data.values[e].title}
            </label>
        </div>;
    });
    return <li className="nav-item">
        <a className="nav-link dropdown-toggle fs-lg text-reset border-bottom mb-6"
           data-bs-toggle="collapse" href={'#filter' + data.name}>
            {data?.titles[lang] ?? data?.titles['en'] ?? data.title}
        </a>
        <div className="collapse" id={'filter' + data.name} data-simplebar-collapse={'#filter' + data.name}>
            <div className="form-group form-group-overflow mb-6" id={'filter' + data.name}>
                {values}
            </div>
        </div>
    </li>
}

export default ColorFilter;