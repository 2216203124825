import {text} from "../../config/i18n/text";

export function validateUsername(username, lang)
{
    let valid = true;
    let error = '';

    if(username.length < 5){
        valid = false;
        error = text[lang].login.errors.username.short;
    }

    if(username.length > 32){
        valid = false;
        error = text[lang].login.errors.username.long;
    }

    return {
        valid: valid,
        error: error,
    };
}

export default validateUsername;