import {text} from "../../config/i18n/text";

export function validatePassword(password, lang)
{
    let valid = true;
    let error = '';

    if(password.length < 7){
        valid = false;
        error = text[lang].login.errors.password.short;
    }

    if(password.length > 64){
        valid = false;
        error = text[lang].login.errors.password.long;
    }

    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
    if ( !re.test(password) ) {
        valid = false;
        error = text[lang].login.errors.password.invalid;
    }

    return {
        valid: valid,
        error: error,
    };
}

export default validatePassword;