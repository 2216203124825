import AccountMenu from "../../parts/account/AccountMenu";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import Footer from "../../parts/Footer";
import {text} from "../../../config/i18n/text";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getInitServer, getInitUser, getLang, getServer} from "../../../redusers/InterfaceReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import FullPageLoader from "../../parts/FulPageLoader";
import {getUserToken} from "../../../redusers/UserReducer";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getAddressList, setList} from "../../../redusers/AddressReducer";
import AccountAddressBlock from "../../parts/account/address/AccountAddressBlock";
import {Link} from "react-router-dom";
import {accountAddressesCreate} from "../../../routes/routes";

export const PersonalAddressesPage = () => {

    const lang = useAppSelector(getLang);

    const token = useItemListSelector(getUserToken);

    const server = useAppSelector(getServer);
    const addressList = useAppSelector(getAddressList);

    const dispatch = useAppDispatch();

    const [status, setStatus] = useState(NetworkStatus.loading);

    const initServer = useItemListSelector(getInitServer);
    const initUser = useItemListSelector(getInitUser);

    useEffect(() => {
        if(true === initUser && true === initServer){
            MakeRequest(
                getUrl(apiRoutes.addressList, server),
                'GET',
                {},
                token,
                (response) => {
                    dispatch(setList([
                        ...response.data.data.billing,
                        ...response.data.data.shipping,
                    ]));
                    setStatus(NetworkStatus.idle);
                },
                (e) => {
                    setStatus(NetworkStatus.fail);
                    console.error('response error is', e);
                });
        }
    }, [status, token, initServer, initUser]);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [status, addressList]);

    if(status === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Header />
            <FullPageLoader />
        </>;
    }

    const displayList = addressList.map((item, index) => {
        return <AccountAddressBlock
            key={'user-address-' + index}
            id={item.id}
            name={item.name}
            companyName={item.companyName}
            country={item.country}
            city={item.city}
            zip={item.zip}
            line1={item.line1}
            line2={item.line2}
        />
    });

    return <>
        <Helmet>
            <title>{text[lang].seo.account.address.title}</title>
            <meta name="description" content={text[lang].seo.account.address.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header/>
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.address_list.title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={3} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        <div className="row">
                            {displayList}
                            <div className="col-12">
                                <Link className="btn w-100 btn-lg btn-outline-border" to={accountAddressesCreate}>
                                    {text[lang].account.address_list.add_address_title} <i className="fe fe-plus"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>;
}

export default PersonalAddressesPage;