import { configureStore } from '@reduxjs/toolkit';
import InterfaceReducer from "../redusers/InterfaceReducer";
import userReducer from "../redusers/UserReducer";
import itemListReducer from "../redusers/ItemsListReducer";
import CartReducer from "../redusers/CartReducer";
import ProductReducer from "../redusers/ProductReducer";
import MainReducer from "../redusers/MainReducer";
import ArticleReducer from "../redusers/ArticleReducer";
import CheckoutReducer from "../redusers/CheckoutReducer";
import OrderReducer from "../redusers/OrderReducer";
import AddressReducer from "../redusers/AddressReducer";

export const store = configureStore({
  reducer: {
    interface:InterfaceReducer,
    user: userReducer,
    itemList: itemListReducer,
    cart: CartReducer,
    product: ProductReducer,
    main: MainReducer,
    article: ArticleReducer,
    checkout: CheckoutReducer,
    order: OrderReducer,
    address: AddressReducer,
  },
});
