export const login = '/login';
export const logout = '/logout';
export const registration = '/registration';
export const forgotPassword = '/forgot-password';

export const home = '/';
export const menu = '/api/v1/category/menu';
export const main = '/main';
export const favorites = '/favorites';
export const cart = '/cart';
export const profile = '/profile';
export const sell = '/sell';
export const sellDone = '/sell_done';

export const category = '/category/:slug';

export const item = '/item/:item_id'
export const test = '/test'
export const article = '/article/:article_slug'
export const checkout = '/checkout/:id'
export const accountInfo = '/account/info'
export const accountOrders = '/account/orders'
export const accountOrder = '/account/order/:id'
export const accountWishlist = '/account/wishlist'
export const accountAddresses = '/account/addresses'
export const accountAddressesCreate = '/account/addresses/create'
export const accountAddress = '/account/address/:id'

export function makeCategory(slug){
    return category.replace(':slug', slug) + '#top';
}
export function makeItem(id){
    return item.replace(':item_id', id) + '#top';
}
export function makeAccountOrder(id){
    return accountOrder.replace(':id', id) + '#top';
}
export function makeArticle(slug){
    return article.replace(':article_slug', slug) + '#top';
}
export function makeAddress(id){
    return accountAddress.replace(':id', id) + '#top';
}
export function makeCheckout(id){
    return checkout.replace(':id', id) + '#top';
}