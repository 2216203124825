import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import React, {useEffect, useState} from "react";
import Footer from "../../parts/Footer";
import AccountMenu from "../../parts/account/AccountMenu";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {AuthUser, getUserProfileInfo, getUserToken} from "../../../redusers/UserReducer";
import FieldLengthValidator from "../../../helpers/validators/FieldLengthValidator";
import {text} from "../../../config/i18n/text";
import moment from "moment";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {authKeyName, getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";

export const PersonalInfoPage = () => {

    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);
    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);

    const info = useAppSelector(getUserProfileInfo);

    const [localState, setLocalState] = useState(NetworkStatus.idle);


    const [firstName, setFirstName] = useState(info.firstName);
    const [firstNameChanged, setFirstNameChanged] = useState(false);
    const [firstNameValid, setFirstNameValid] = useState(null);
    const [firstNameError, setFirstNameError] = useState('');

    const [lastName, setLastName] = useState(info.lastName);
    const [lastNameChanged, setLastNameChanged] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(null);
    const [lastNameError, setLastNameError] = useState('');

    const [phone, setPhone] = useState(info.phone);
    const [phoneChanged, setPhoneChanged] = useState(false);
    const [phoneValid, setPhoneValid] = useState(null);
    const [phoneError, setPhoneError] = useState('');


    const [username, setUsername] = useState(info.userName);
    const [usernameChanged, setUsernameChanged] = useState(false);
    const [usernameValid, setUsernameValid] = useState(null);
    const [usernameError, setUsernameError] = useState('');

    const [gender, setGender] = useState(info.sex);

    const mom = null === info.birth ? null : moment(info.birth);

    const [day, setDay] = useState(null !== mom ? parseInt(mom?.format('DD')) : 1);
    const [mon, setMonth] = useState(null !== mom ? parseInt(mom?.format('MM')) : 1);
    const [year, setYear] = useState(null !== mom ? parseInt(mom?.format('YYYY')) : 2023);


    const firstNameChange = (e) => {
        setFirstName(e.target.value);
        setFirstNameChanged(true);
    }
    const lastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameChanged(true);
    }
    const userNameChange = (e) => {
        setUsername(e.target.value);
        setUsernameChanged(true);
    }
    const phoneChange = (e) => {
        setPhone(e.target.value);
        setPhoneChanged(true);
    }

    const firstNameFocus = () => {
        setFirstNameValid(null);
        setFirstNameError(null);
    }
    const lastNameFocus = () => {
        setLastNameValid(null);
        setLastNameError(null);
    }
    const usernameFocus = () => {
        setUsernameValid(null);
        setUsernameError(null);
    }
    const phoneFocus = () => {
        setPhoneValid(null);
        setPhoneError(null);
    }
    const genderMaleChange = () => {
        setGender(true);
    }
    const genderFemaleChange = () => {
        setGender(false);
    }

    const firstNameBlur = () => {
        const validator = FieldLengthValidator(firstName, lang, 'First Name', 1);
        setFirstNameValid(validator.valid);
        setFirstNameError(validator.error);
    }
    const lastNameBlur = () => {
        const validator = FieldLengthValidator(lastName, lang, 'Last Name', 1);
        setLastNameValid(validator.valid);
        setLastNameError(validator.error);
    }
    const usernameBlur = () => {
        const validator = FieldLengthValidator(lastName, lang, 'Last Name', 1);
        setLastNameValid(validator.valid);
        setLastNameError(validator.error);
    }
    const phoneBlur = () => {}

    const firstNameClass = firstNameValid === null || !firstNameChanged ? 'form-control form-control-sm' : ( firstNameValid === true ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid');
    const lastNameClass = lastNameValid === null || !lastNameChanged ? 'form-control form-control-sm' : ( lastNameValid === true ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid');
    const usernameClass = usernameValid === null || !usernameChanged ? 'form-control form-control-sm' : ( usernameValid === true ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid');
    const phoneClass = phoneValid === null || !phoneChanged ? 'form-control form-control-sm' : ( phoneValid === true ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid');


    let days = [];
    for(let i=1; i<= 31; i++){
        days.push(<option
            key={'day-item-' + i}
            value={i}
        >{i}</option>)
    }
    let years = [];
    for(let i=2023; i>= 1923; i--){
        years.push(<option
            key={'year-item-' + i}
            value={i}
        >{i}</option>)
    }

    const monthNames = [
        text[lang].month.january,
        text[lang].month.february,
        text[lang].month.march,
        text[lang].month.april,
        text[lang].month.may,
        text[lang].month.june,
        text[lang].month.july,
        text[lang].month.august,
        text[lang].month.september,
        text[lang].month.october,
        text[lang].month.november,
        text[lang].month.december,
    ];

    const month = monthNames.map((item, index) => {
        return <option
            key={'month-item-' + index}
            value={index + 1}
        >{item}</option>;
    });

    const [alerts, setAlerts] = useState(null);


    const saveAction = () => {
        setLocalState(NetworkStatus.loading);
        const postData = {
            firstName: firstName,
            lastName: lastName,
            username: username,
            phone: phone,
            birth: moment(day + '/' + mon + '/' + year, "DD/MM/YYYY", true).format('YYYY-MM-DD'),
            sex: gender,

        };
        MakeRequest(getUrl(apiRoutes.updateProfile, server), 'POST', postData, token, (response) => {
            console.info('response of save', response, postData);
            const newState = {
                userId: response.data.data.id,
                userToken: token,
                userName: response.data.data.username,
                firstName: response.data.data.firstName,
                lastName: response.data.data.lastName,
                email: info.email,
                phone: response.data.data.phone,
                sex: response.data.data.sex,
                birth: response.data.data.birth,
            };
            dispatch(AuthUser(newState));
            const serializedState = JSON.stringify(newState);
            localStorage.setItem(authKeyName + 'user', serializedState);

            setLocalState(NetworkStatus.idle);
            setAlerts([<div className="alert alert-success" role="alert" key={'alert-success'}>
                {text[lang].account.info.success}
                </div>]);
            setLocalState(NetworkStatus.idle);
        }, (e) => {
            if(400 === e.response?.data?.code ?? 0){
                setAlerts((e.response?.data?.errors ?? []).map((item, index) =>
                    <div className="alert alert-primary" role="alert" key={'alert-' + index}>
                        <b>{item.field}</b> {item.message}
                    </div>));
                setLocalState(NetworkStatus.idle);
            } else {
                setLocalState(NetworkStatus.fail);
            }
            console.error('response error is',e.response.data.code, e.response.data.errors, alerts);
        });
    }

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [localState]);


    return <>
        <Helmet>
            <title>{text[lang].seo.account.personal_info.title}</title>
            <meta name="description" content={text[lang].seo.account.personal_info.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header/>
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.info.page_title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={2} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        <form>
                            {alerts}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="accountEmail">
                                            {text[lang].account.info.field_title.email} (<span className='text-danger text-size-error'>{text[lang].account.info.field_title.email_read_only}</span>)
                                        </label>
                                        <input
                                            className='form-control form-control-sm'
                                            id="accountEmail"
                                            type="email"
                                            placeholder={text[lang].account.info.field_title.email}
                                            value={info.email}
                                            disabled={true}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="accountFirstName">
                                            {text[lang].account.info.field_title.first_name}
                                        </label>
                                        <input
                                            className={firstNameClass}
                                            id="accountFirstName"
                                            type="text"
                                            placeholder={text[lang].account.info.field_title.first_name}
                                            value={firstName}
                                            onChange={firstNameChange}
                                            onFocus={firstNameFocus}
                                            onBlur={firstNameBlur}
                                            disabled={localState !== NetworkStatus.idle}
                                            required
                                        />
                                        {firstNameError}
                                    </div>

                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="accountLastName">
                                            {text[lang].account.info.field_title.last_name}
                                        </label>
                                        <input
                                            className={lastNameClass}
                                            id="accountLastName"
                                            type="text"
                                            placeholder={text[lang].account.info.field_title.last_name}
                                            value={lastName}
                                            onChange={lastNameChange}
                                            onFocus={lastNameFocus}
                                            onBlur={lastNameBlur}
                                            disabled={localState !== NetworkStatus.idle}
                                            required
                                        />
                                        {lastNameError}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="accountLastName">
                                            {text[lang].account.info.field_title.username}
                                        </label>
                                        <input
                                            className={usernameClass}
                                            id="accountUsername"
                                            type="text"
                                            placeholder={text[lang].account.info.field_title.username}
                                            value={username}
                                            onChange={userNameChange}
                                            onFocus={usernameFocus}
                                            onBlur={usernameBlur}
                                            disabled={localState !== NetworkStatus.idle}
                                            required
                                        />
                                        {usernameError}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="accountPhone">
                                            {text[lang].account.info.field_title.phone}
                                        </label>
                                        <input
                                            className={phoneClass}
                                            id="accountPhone"
                                            type="phone"
                                            placeholder={text[lang].account.info.field_title.phone}
                                            value={phone}
                                            onChange={phoneChange}
                                            onFocus={phoneFocus}
                                            onBlur={phoneBlur}
                                            disabled={localState !== NetworkStatus.idle}
                                            required
                                        />
                                        {phoneError}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="form-group">
                                        <label className="form-label">{text[lang].account.info.field_title.birth}</label>
                                        <div className="row gx-5">
                                            <div className="col-auto">
                                                <label className="visually-hidden" htmlFor="accountDate">
                                                    {text[lang].account.info.field_title.day}
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    id="accountDate"
                                                    value={day}
                                                    onChange={(e) => { setDay(e.target.value); }}
                                                    disabled={localState !== NetworkStatus.idle}
                                                >
                                                    {days}
                                                </select>
                                            </div>

                                            <div className="col">
                                                <label className="visually-hidden" htmlFor="accountMonth">
                                                    {text[lang].account.info.field_title.month}
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    id="accountMonth"
                                                    value={mon}
                                                    disabled={localState !== NetworkStatus.idle}
                                                    onChange={(e) => { setMonth(e.target.value); }}
                                                >
                                                    {month}
                                                </select>
                                            </div>

                                            <div className="col-auto">
                                                <label className="visually-hidden" htmlFor="accountYear">
                                                    {text[lang].account.info.field_title.year}
                                                </label>
                                                <select
                                                    className="form-select form-select-sm"
                                                    id="accountYear"
                                                    disabled={localState !== NetworkStatus.idle}
                                                    value={year}
                                                    onChange={(e) => { setYear(e.target.value); }}
                                                >
                                                    {years}
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-12 col-lg-4 text-end">
                                    <div className="form-group mb-8">
                                        <label className="form-label">{text[lang].account.info.field_title.gender}</label>
                                        <div>
                                            <input
                                                className="btn-check"
                                                type="radio"
                                                name="gender"
                                                id="male"
                                                value={1}
                                                disabled={localState !== NetworkStatus.idle}
                                                onChange={() => {
                                                    genderMaleChange();
                                                }}
                                                checked={gender===true}
                                            />
                                                <label className="btn btn-sm btn-outline-border" htmlFor="male">
                                                    {text[lang].account.info.field_title.male}
                                                </label>
                                            <input
                                                className="btn-check"
                                                type="radio"
                                                name="gender"
                                                id="female"
                                                value={1}
                                                disabled={localState !== NetworkStatus.idle}
                                                checked={gender===false}
                                                onChange={() => {
                                                    genderFemaleChange();
                                                }}
                                            />
                                                <label className="btn btn-sm btn-outline-border" htmlFor="female">
                                                    {text[lang].account.info.field_title.female}
                                                </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        className="btn btn-dark"
                                        type="button"
                                        disabled={localState !== NetworkStatus.idle}
                                        onClick={saveAction}
                                    >
                                        {text[lang].account.info.field_title.button}
                                    </button>
                                </div>
                                <div className="container mt-3">
                                    {alerts}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default PersonalInfoPage;