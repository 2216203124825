import React from 'react';
import {
    availableSelectLang,
    showHeaderAccountButton, showHeaderCartButton, showHeaderPromo,
    showHeaderSearchButton, showHeaderWishListButton,
    socialLinks,
} from "../../config/config";
import {Link} from "react-router-dom";
import * as routers from "../../routes/routes";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    getCategories,
    getLang,
    isUserAuth, setLang
} from "../../redusers/InterfaceReducer";
import {makeArticle, makeCategory} from "../../routes/routes";
import * as routerPath from "./../../routes/routes";
// import logo from '../../assets/dekbed/logo2.png';
import logo from '../../assets/dekbed/db-logo4.svg';
import '../../scss/header.scss';
import {availableLang, text} from "../../config/i18n/text";
import {getWishListSize} from "../../redusers/UserReducer";
import {getTotal} from "../../redusers/CartReducer";
import HeaderPromoBlock from "./block/HeaderPromoBlock";
import CartBlock from "./block/CartBlock";

function Header() {

    const dispatch = useAppDispatch();

    const auth = useAppSelector(isUserAuth);
    const categories = useAppSelector(getCategories);
    const lang = useAppSelector(getLang);

    const wishListSize = useAppSelector(getWishListSize);
    const cartSize = useAppSelector(getTotal);

    // const topLvl = categories.map((category, index) => {
    //     return <Link to={makeCategory(category.slug)} key={'main-nav-menu-index-' + index} className={['nav-link', 'text-uppercase', 'c-pointer', selectedMenu === index && 'active'].join(' ')} >{category.title}</Link>;
    // });

        //<span className="nav-link text-uppercase active" data-bs-toggle="tab">Women</span>
    //                                                         <span className="nav-link text-uppercase" data-bs-toggle="tab">Men</span>
    //                                                         <span className="nav-link text-uppercase" data-bs-toggle="tab">Kids</span>

    // let buttons = auth ? <div className="right-buttons not-anon">
    //     <Link className='hvr-pop animate__pulse btn btn-primary' to={routers.sell}>{texts.sellNow}</Link>
    //     <Link className='hvr-pulse-grow' to={routers.favorites}><BookmarkHeart size={16} /></Link>
    //     <Link className='hvr-pulse-grow' to={routers.cart}><Cart size={16} /></Link>
    //     <Dropdown>
    //         <Dropdown.Toggle variant='white' id="account">
    //             <PersonCircle size={16} />
    //         </Dropdown.Toggle>
    //
    //         <Dropdown.Menu>
    //             <Dropdown.Item ><Link className='link-full-weight' to={routers.profile}>Profile</Link></Dropdown.Item>
    //             <Dropdown.Item ><Link className='link-full-weight' to={routers.logout}>Logout</Link></Dropdown.Item>
    //         </Dropdown.Menu>
    //     </Dropdown>
    // </div> : <div className="right-buttons anon animate__pulse">
    //     <Link className='btn btn-sm button-top-bordered hvr-pop animate__pulse' to={routers.login}>{texts.login}</Link>
    //     <Link className='btn btn-primary btn-sm button-top hvr-pop animate__pulse' to={routers.login}>{texts.sellNow}</Link>
    // </div>
    //
    // const menu = categories.map((category, index) => {
    //     let subMenu = null;
    //     if(category.child.length > 0){
    //         subMenu = category.child.map((item, index) => {
    //             let lvl3Menu = null;
    //             if(item.child.length > 0){
    //                 lvl3Menu = item.child.map((item3, index3) => {
    //                     return <li key={'subItem' + index + '-lvl3-' + index3}>
    //                         <Link title={item3.title} to={makeCategory(item3.slug)}>{item3.title}</Link>
    //                     </li>
    //                 });
    //             }
    //             return <div key={'subItem3' + index + '-lvl3-'} className='submenu-column'>
    //                 <span
    //                     key={'subItem' + index}
    //                     className='submenu-title'
    //                 >
    //             <Link title={item.title} to={makeCategory(item.slug)}>{item.title}</Link>
    //                 </span>
    //                 {lvl3Menu !== null ? <ul>
    //                     {lvl3Menu}
    //                 </ul> : null }
    //             </div> });
    //     }
    //     return <li key={'menu-item-' + index}>
    //         <Link className='hvr-grow hvr-underline-from-center' title={category.title} to={makeCategory(category.slug)}>{category.title}</Link>
    //         { null !== subMenu ?<div className='submenu animate__bounce'>{subMenu}</div> : null}
    //     </li>
    // })
    // let menuBlock = null;
    // if(categories.length > 0){
    //     menuBlock = <div className={'head-menu-block animate__pulse'}>
    //         <ul>
    //             {menu}
    //         </ul>
    //     </div>;
    // }

    const setSiteLang = (lang) => {
        if(true === availableSelectLang[lang]){
            dispatch(setLang(lang));
        } else {
            alert('this language not available now');
        }
    }

    const socialLinksDisplay = socialLinks.map((item, index) => {
        return <li className="nav-item" key={'social-top-link-' + index}>
            <a className="nav-link text-gray-350" href={item.url} title={item.name} target={'_blank'} rel="noreferrer">
                <i className={item.className}></i>
            </a>
        </li>;
    });

    const menu = categories.map((item, index) => {
        return <li key={'main-menu-' + index} className="nav-item dropdown text-capitalize"><Link className="nav-link" to={makeCategory(item.slug)}>
            { item.titles[lang] ?? item.titles['en'] ?? item.title }
        </Link></li>
    });

    return (
        <>
            <CartBlock/>
            <div className="navbar navbar-topbar navbar-expand-xl navbar-light bg-light">
                {/*<a name="top" href='/'></a>*/}
                <div className="container">
                    <div className="me-xl-8">
                        <i className="fe fe-truck me-2"></i> <span
                        className="heading-xxxs">{text[lang].header.shipping}</span>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#topbarCollapse" aria-controls="topbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="btn btn-outline-border btn-xxs">{text[lang].header.short_lang[lang]}</span>
                        {/*<span className="navbar-toggler-icon"></span>*/}
                    </button>
                    <div className="collapse navbar-collapse" id="topbarCollapse">
                        <ul className="nav nav-divided navbar-nav me-auto">
                            <li className="nav-item dropdown">
                                <button className="btn nav-link dropdown-toggle" type='button' data-bs-toggle="dropdown"
                                        id='lang-button'>{text[lang].header.lang[lang]}</button>
                                <div className="dropdown-menu min-w-0" aria-labelledby="lang-button">
                                    <span
                                        className={[
                                            "dropdown-item",
                                            "c-pointer",
                                            availableSelectLang.en === false ? 'disabled crossed' : 'enabled'
                                        ].join(' ')}
                                        onClick={() => {
                                            setSiteLang(availableLang.en);
                                        }}
                                    >
                                        {
                                            true === availableSelectLang.en
                                                ? text[lang].header.lang.en
                                                : <del>{text[lang].header.lang.en}</del>
                                        }
                                    </span>
                                    <span
                                        className={[
                                            "dropdown-item",
                                            "c-pointer",
                                            availableSelectLang.fr === false ? 'disabled crossed' : 'enabled'].join(' ')}
                                        onClick={() => {
                                            setSiteLang(availableLang.fr);
                                        }}
                                    >
                                        {
                                            true === availableSelectLang.fr
                                                ? text[lang].header.lang.fr
                                                : <del>{text[lang].header.lang.fr}</del>
                                        }
                                    </span>
                                    <span
                                        className={[
                                            "dropdown-item",
                                            "c-pointer",
                                            availableSelectLang.nl === false ? 'disabled crossed' : 'enabled'].join(' ')}
                                        onClick={() => {
                                            setSiteLang(availableLang.nl);
                                        }}
                                    >
                                        {
                                            true === availableSelectLang.nl
                                                ? text[lang].header.lang.nl
                                                : <del>{text[lang].header.lang.nl}</del>
                                        }
                                    </span>
                                    <span
                                        className={[
                                            "dropdown-item",
                                            "c-pointer",
                                            availableSelectLang.de === false ? 'disabled crossed' : 'enabled'].join(' ')}
                                        onClick={() => {
                                            setSiteLang(availableLang.de);
                                        }}
                                    >
                                        {
                                            true === availableSelectLang.de
                                                ? text[lang].header.lang.de
                                                : <del>{text[lang].header.lang.de}</del>
                                        }
                                    </span>
                                </div>

                            </li>
                        </ul>
                        <ul className="nav navbar-nav me-8">
                            <li className="nav-item">
                                <Link className="nav-link"
                                      to={makeArticle('shipping')}>{text[lang].links.shipping}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={makeArticle('faq')}>{text[lang].links.faq}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link"
                                      to={makeArticle('contacts')}>{text[lang].links.contacts}</Link>
                            </li>
                        </ul>

                        <ul className="nav navbar-nav flex-row">
                            {socialLinksDisplay}
                        </ul>

                    </div>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container">
                    <Link className="navbar-brand hvr-grow" to={routerPath.home}>
                        <img className='main-logo' src={logo} alt='Logo'/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mx-auto">
                            {menu}
                        </ul>

                        <ul className="navbar-nav flex-row">
                            {showHeaderSearchButton === true ? <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="offcanvas">
                                    <i className="fe fe-search"></i>
                                </button>
                            </li> : null}
                            {showHeaderAccountButton === true ? <li className="nav-item ms-lg-n4">
                                <Link className="nav-link" to={auth ? routerPath.accountInfo : routers.login}>
                                    <i className="fe fe-user"></i>
                                </Link>
                            </li> : null}
                            {showHeaderWishListButton === true ? <li className="nav-item ms-lg-n4">
                                <Link className="nav-link" to={auth ? routerPath.accountWishlist : routers.login}>
                                    {
                                        wishListSize > 0
                                            ? <span data-cart-items={wishListSize}>
                                            <i className="fe fe-heart"></i>
                                        </span>
                                            : <i className="fe fe-heart"></i>
                                    }
                                </Link>
                            </li> : null}
                            {showHeaderCartButton === true ?
                                <li className="nav-item ms-lg-n4">
                                    <button className="btn-invisible nav-link" data-bs-toggle="offcanvas"
                                            data-bs-target="#modalShoppingCart" aria-controls="modalShoppingCart">
                                        {
                                            cartSize > 0
                                                ? <span data-cart-items={cartSize}>
                                              <i className="fe fe-shopping-cart"></i>
                                            </span>
                                                : <i className="fe fe-shopping-cart"></i>
                                        }
                                    </button>
                                </li> : null
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            {showHeaderPromo === true ?
                <HeaderPromoBlock text={'⚡️ Happy Holiday Deals on Everything ⚡'} to={'/'}/> : null}
        </>
    );
}

export default Header;
