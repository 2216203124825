import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {Link} from "react-router-dom";
import {makeItem} from "../../../routes/routes";
import Money from "../money/Money";
import image from "../../../assets/img/products/product-6.jpg";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {getItems} from "../../../redusers/CheckoutReducer";

export const CheckoutItemListBlock = () => {

    const items = useAppSelector(getItems);

    const lang = useAppSelector(getLang);

    const list = items.map((item, index) => {
        const cost = item.count <= 1
            ? <Money amount={item.item.cost} />
            : <><Money amount={item.item.cost} /> * {item.count} = <Money amount={item.total} /></>;

        return <li className="list-group-item" key={'checkout-item-' + index}>
            <div className="row align-items-center">
                <div className="col-4">
                    <Link  to={makeItem(item.item.id)}>
                        <img src={image} alt="..."
                             className="img-fluid"/>
                    </Link>
                </div>
                <div className="col align-text-top">
                    <p className="mb-4 fs-sm fw-bold col align-text-top">
                        <Link className="text-body" to={makeItem(item.item.id)}>{item.item.title}</Link>
                        <br/>
                        <span className="text-muted">{cost}</span>
                    </p>
                    {/*<div className="fs-sm text-muted">*/}
                    {/*    Size: M <br/>*/}
                    {/*    Color: Red*/}
                    {/*</div>*/}
                </div>
            </div>
        </li>;
    });

    return <>
        <h6 className="mb-7">{text[lang].checkout.order_items} ({items.length})</h6>
        <hr className="my-7"/>
        <ul className="list-group list-group-lg list-group-flush-y list-group-flush-x mb-7">
            {list}
        </ul>
    </>;
}

export default CheckoutItemListBlock;