import {text} from "../../../../config/i18n/text";
import moment from "moment/moment";
import Money from "../../money/Money";
import React from "react";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";

export const OrderTitleBlock = (data) => {

    const params = {
        displayId: '',
        id: '',
        date: 'now',
        status: {
            title: '',
            class: '',
        },
        amount: 0.0,
        ...data
    };

    const lang = useAppSelector(getLang);

    return <div className="card-body pb-0">
        <div className="card card-sm">
            <div className="card-body bg-light">
                <div className="row">
                    <div className="col-6 col-lg-4">
                        <h6 className="heading-xxxs text-muted">{text[lang].account.order_list.order_number}</h6>
                        <p className="mb-lg-0 fs-sm fw-bold text-size-error">
                            {params.displayId}
                        </p>
                    </div>
                    <div className="col-6 col-lg-3">
                        <h6 className="heading-xxxs text-muted">{text[lang].account.order_list.order_date}</h6>
                        <p className="mb-lg-0 fs-sm fw-bold">
                            <time>
                                {moment(params.date).format("MMM Do YYYY")}
                            </time>
                        </p>
                    </div>
                    <div className="col-6 col-lg-3">
                        <h6 className="heading-xxxs text-muted">{text[lang].account.order_list.order_status}</h6>
                        <p className="mb-0 fs-sm fw-bold">
                            <span className={params.status.class}>{params.status.title}</span>
                        </p>
                    </div>
                    <div className="col-6 col-lg-2">
                        <h6 className="heading-xxxs text-muted">{text[lang].account.order_list.order_amount}</h6>
                        <p className="mb-0 fs-sm fw-bold"><Money amount={params.amount} /></p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default OrderTitleBlock;