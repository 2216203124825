import Header from "../parts/Header";
import React, {useEffect} from "react";
import Footer from "../parts/Footer";
import InfoBlock from "../parts/block/InfoBlock";
import ArticleBreadcrumb from "../parts/article/ArticleBreadcrumb";
import {
    getArticle,
    getArticleStatus,
    getTick,
    isArticleExist,
    setArticle,
    setArticleNotExist
} from "../../redusers/ArticleReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useLocation} from "react-router-dom";
import {getInitServer, getLang, getServer} from "../../redusers/InterfaceReducer";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import FullPageLoader from "../parts/FulPageLoader";
import {Helmet} from "react-helmet";
import ArticleNotFoundBlock from "../parts/article/ArticleNotFoundBlock";
import MakeRequest from "../../helpers/network/MakeRequest";
import {getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import MakeGetParams from "../../helpers/network/MakeGetParams";
import {text} from "../../config/i18n/text";
import moment from "moment";

export const ArticlePage = () => {

    const dispatch = useAppDispatch();

    let location = useLocation();
    const slug = location.pathname.replace('/article/','');

    const server = useAppSelector(getServer);

    const lang = useAppSelector(getLang);

    const isExist = useAppSelector(isArticleExist);

    const article = useAppSelector(getArticle);

    const status = useAppSelector(getArticleStatus);

    const tick = useAppSelector(getTick);

    const initServer = useAppSelector(getInitServer);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [slug]);

    useEffect(() => {
        if(true === initServer){
            MakeRequest(getUrl(apiRoutes.article, server) + MakeGetParams({slug: slug}), 'GET',{}, '', (response) => {
                dispatch(setArticle(response.data.data));
            }, () => {
                dispatch(setArticleNotExist());
            });
        }
    }, [tick, slug, initServer]);

    if (status === NetworkStatus.loading) {
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
            </Helmet>
            <Header />
            <FullPageLoader />
            <Footer />
        </>
    }

    if (false === isExist || status === NetworkStatus.fail) {
        return <>
            <Helmet>
                <title>{text[lang].seo.not_found.title}</title>
                <meta name="description" content={text[lang].seo.not_found.description} />
            </Helmet>
            <Header />
            <ArticleNotFoundBlock />
            <Footer />
        </>
    }

    const mom = moment(article?.lastModified);

    return <>
        <Helmet>
            <title>{text[lang].seo.article.title} {article.title ?? '-'}</title>
            <meta name="description" content={text[lang].seo.article.description + (article.title ?? '-')} />
        </Helmet>
        <Header />
        <ArticleBreadcrumb title={article.title ?? '-'} />

        <article className="pt-7 pb-12">
            <header className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-3">{article.title ?? '-'}</h3>
                        <p className="mb-0 text-muted">
                            {text[lang].article.last_updated} {mom.format('LLLL')}
                        </p>
                    </div>
                </div>
            </header>

            <section className="pt-10">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 fs-lg text-gray-500">
                            <div dangerouslySetInnerHTML={{__html: article.text }} />
                        </div>
                    </div>
                </div>
            </section>
        </article>
        <InfoBlock />
        <Footer />
    </>;
}

export default ArticlePage;