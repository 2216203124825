import {createSlice} from '@reduxjs/toolkit';
import {NetworkStatus} from "../helpers/network/NetworkStatus";
import variants from "./../components/models/checkout/PaymentTypeModel";


const initialState = {
    state: NetworkStatus.idle,

    items: [],

    promoCode: '',
    promoCodeStatus: 'not_loaded',
    promoCodeId: null,
    promoCodeError: '',
    promoCodeDiscount: 0,

    shipping_position: 0,
    shipping_cost: 8,

    addressList: [],
    defaultShipping: null,
    defaultBilling: null,

    shippingDifferentAddress: false,

    placed: false,

    order: {
        id: null,
        orderId: null,
        subTotal: 0.0,
    },

    paymentMethod: variants[0].value,

    formValues: {
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingCompany: '',
        billingCountry: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingZip: '',
        billingMobile: '',
        shippingCompany: '',
        shippingCountry: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingZip: '',
        note: '',
    },
    formErrors: {
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingCompany: '',
        billingCountry: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingZip: '',
        billingMobile: '',
        shippingCompany: '',
        shippingCountry: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingZip: '',
        note: '',
    },
    formValid: {
        billingFirstName: null,
        billingLastName: null,
        billingEmail: null,
        billingCompany: null,
        billingCountry: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingZip: null,
        billingMobile: null,
        shippingCompany: null,
        shippingCountry: null,
        shippingAddress1: null,
        shippingAddress2: null,
        shippingCity: null,
        shippingZip: null,
        note: null,
    },
};

export const userSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCheckoutStatus: (state, payload) => {
            state.state = payload.payload;
        },
        promoCodeChange: (state, payload) => {
            state.promoCode = payload.payload;
            state.promoCodeError = '';
            state.promoCodeStatus = 'not_loaded';
            state.promoCodeDiscount = 0;
        },
        shippingMethodChange: (state, payload) => {
            state.shipping_position = payload.payload.position;
            state.shipping_cost = payload.payload.cost;
        },
        setPaymentMethod: (state, payload) => {
            state.paymentMethod = payload.payload;
        },
        setFormValue: (state, payload) => {
            state.formValues[payload.payload.field] = payload.payload.value;
        },
        setFormFocus: (state, payload) => {
            state.formValid[payload.payload] = null;
            state.formErrors[payload.payload] = '';
        },
        setFormBlur: (state, payload) => {
            state.formValid[payload.payload.field] = payload.payload.isValid;
            state.formErrors[payload.payload.field] = payload.payload.error;
        },

        setShippingDifferentAddress: (state, action) => {
            state.shippingDifferentAddress = action.payload;
        },

        orderPlace: (state) => {
            state.placed = true;
            state.promoCode= '';
            state.promoCodeStatus = 'not_loaded';
            state.promoCodeId = null;
            state.promoCodeError = '';
            state.promoCodeDiscount = 0;
        },

        loadOrder: (state, payload) => {
            state.order = payload.payload.order;
            state.items = payload.payload.items;

            if (null !== payload.payload.promo) {
                state.promoCode = payload.payload.promo.name;
                state.promoCodeStatus = 'loaded'
                state.promoCodeId = payload.payload.promo.id;
                state.promoCodeError = '';
                state.promoCodeDiscount = payload.payload.promo.discount;
            }

            state.addressList = payload.payload.addresses;
            state.formValues.billingFirstName = payload.payload.billingFirstName;
            state.formValues.billingLastName = payload.payload.billingLastName;
            state.formValues.billingEmail = payload.payload.billingEmail;
            state.formValues.billingMobile = payload.payload.billingMobile;
            if(null !== payload.payload.defaultShipping){
                state.formValues.shippingCompany = payload.payload.defaultShipping.company ?? '';
                state.formValues.shippingCountry = payload.payload.defaultShipping.country ?? '';
                state.formValues.shippingAddress1 = payload.payload.defaultShipping.line1 ?? '';
                state.formValues.shippingAddress2 = payload.payload.defaultShipping.line2 ?? '';
                state.formValues.shippingCity = payload.payload.defaultShipping.city ?? '';
                state.formValues.shippingZip = payload.payload.defaultShipping.zipCode ?? '';
            }
            if(null !== payload.payload.defaultBilling){
                state.formValues.billingCompany = payload.payload.defaultBilling.company ?? '';
                state.formValues.billingCountry = payload.payload.defaultBilling.country ?? '';
                state.formValues.billingAddress1 = payload.payload.defaultBilling.line1 ?? '';
                state.formValues.billingAddress2 = payload.payload.defaultBilling.line2 ?? '';
                state.formValues.billingCity = payload.payload.defaultBilling.city ?? '';
                state.formValues.billingZip = payload.payload.defaultBilling.zipCode ?? '';
            }

        },
        clearCheckoutState: (state) => {
            state.formValues = initialState.formValues;
            state.formValid = initialState.formValid;
            state.formErrors = initialState.formErrors;
            state.shippingDifferentAddress = initialState.shippingDifferentAddress;
            state.placed = initialState.placed;
            state.order = initialState.order;
            state.promoCode= '';
            state.promoCodeStatus = 'not_loaded';
            state.promoCodeId = null;
            state.promoCodeError = '';
            state.promoCodeDiscount = 0;
        },

        setLoadStatus: (state, action) => {
            state.promoCodeStatus = action.payload;
        },
        setPromoActivate: (state, action) => {
            state.order.discount = parseFloat(action.payload.discount);
            state.promoCode = action.payload.code;
            state.promoCodeId = action.payload.id;
            state.promoCodeStatus = 'loaded';
            state.promoCodeError = '';
            state.promoCodeDiscount = action.payload.discount;
        },
        setWrongPromo: (state, action) => {
            state.promoCodeStatus = 'not_loaded';
            state.promoCodeError = action.payload;
        },
    },
});

export const {
    setCheckoutStatus,
    promoCodeChange,
    shippingMethodChange,
    setPaymentMethod,
    setFormValue,
    setFormFocus,
    setFormBlur,
    loadOrder,
    setShippingDifferentAddress,
    orderPlace,
    clearCheckoutState,
    setLoadStatus,
    setPromoActivate,
    setWrongPromo,
} = userSlice.actions;

export const getCheckoutStatus = (state) => state.checkout.state;
export const getPromoCodeText = (state) => state.checkout.promoCode;
export const getPromoCodeError = (state) => state.checkout.promoCodeError;
export const getPromoCodeDiscount = (state) => state.checkout.promoCodeDiscount;
export const getPromoCodeStatus = (state) => state.checkout.promoCodeStatus;
export const getPromoCode = (state) => { return {
    code: state.checkout.promoCode,
    error: state.checkout.promoCodeError,
    status: state.checkout.promoCodeStatus,
    discount: state.checkout.promoCodeDiscount,
}};

export const getShippingPosition = (state) => state.checkout.shipping_position;
export const getShippingCost = (state) => state.checkout.shipping_cost;
export const getShipping = (state) => { return {
    position: state.checkout.shipping_position,
    cost: state.checkout.shipping_cost,
}};

export const getPaymentMethod = (state) => state.checkout.paymentMethod;
export const getOrderInfo = (state) => state.checkout.order;
export const getShippingDifferentAddress = (state) => state.checkout.shippingDifferentAddress;
export const getPlaced = (state) => state.checkout.placed;
export const getItems = (state) => state.checkout.items;
export const getForm = (state) => {
    return {
        values: state.checkout.formValues,
        errors: state.checkout.formErrors,
        valid:  state.checkout.formValid,
}};
export default userSlice.reducer;