import React, {useState} from "react";
import "./../../scss/footer.scss";
import mastercard from "./../../assets/img/payment/mastercard.svg";
import visa from "./../../assets/img/payment/visa.svg";
import paypal from "./../../assets/img/payment/paypal.svg";
import maestro from "./../../assets/img/payment/maestro.svg";
import {useAppSelector} from "../../app/hooks";
import {getCategories, getLang, getServer} from "../../redusers/InterfaceReducer";
import {text} from "../../config/i18n/text";
import {contacts, getUrl, socialLinks} from "../../config/config";
import {Link} from "react-router-dom";
import {makeArticle, makeCategory} from "../../routes/routes";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import MakeRequest from "../../helpers/network/MakeRequest";
import {apiRoutes} from "../../config/apiRoutes";

function Footer() {

    const lang = useAppSelector(getLang);

    const categories = useAppSelector(getCategories);

    const server = useAppSelector(getServer);

    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [status, setStatus] = useState(NetworkStatus.idle);
    const [placed, setPlaced] = useState(false);
    const [msg, setMsg] = useState(null);

    const onEmailChange = (value) => {
        setSubscribeEmail(value.target.value);
    }

    const subscribeAction = () => {
        setStatus(NetworkStatus.loading);
        MakeRequest(
            getUrl(apiRoutes.clientSubscribe, server),
            'post', {email: subscribeEmail}, null, () => {
                setPlaced(true);
                setStatus(NetworkStatus.idle);
                setMsg(text[lang].subscribe.placed)
            }, (e) => {
                if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                    setStatus(NetworkStatus.idle);
                } else {
                    console.error('response error is', e);
                    setStatus(NetworkStatus.fail);
                    setMsg(e.response.data.message);
                }
            });
    }

    const from = 0;
    const to = Math.min(9, categories.length);

    const displayCategories = categories.slice(from, to).map((item, index) => {
        return <li key={'footer-category-links-' + index}>
            <Link className="text-gray-300" to={makeCategory(item.slug)}>{item.title}</Link>
        </li>;
    });

    const contactInfo = contacts.map((item, index) => {
        switch(item.type){
            case 'phone':
                return <li key={'contact-info-' + index}>
                    <a className="text-gray-300" href={"tel:" + item.value}>{item.title}</a>
                </li>;
            case 'email':
                return <li key={'contact-info-' + index}>
                    <a className="text-gray-300" href={"mailto:" + item.value}>{item.title}</a>
                </li>;
            default:
                return <li key={'contact-info-' + index}>
                    <a className="text-gray-300" href={item.value}>{item.title}</a>
                </li>;
        }
    });

    const socialLinksDisplay = socialLinks.map((item, index) => {
        return <li className="list-inline-item" key={'social-bottom-link-' + index}>
            <a href={item.url} className="text-gray-350" title={item.name} target={'_blank'} rel='noreferrer'>
                <i className={item.className}></i>
            </a>
        </li>
    });

    return (
        <>
            <footer className="bg-dark bg-cover ">
                <div className="py-12 border-bottom border-gray-700">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                                <h5 className="mb-7 text-center text-white">{text[lang].subscribe.title}</h5>
                                <form className="mb-11">
                                    <div className="row gx-5 align-items-start">
                                        <div className="col">
                                            <input type="email"
                                                   value={subscribeEmail}
                                                   onChange={onEmailChange}
                                                   className="form-control form-control-gray-700 form-control-lg"
                                                   placeholder="Enter Email *"
                                                   required={true}
                                                   disabled={status === NetworkStatus.loading}
                                            />
                                            {msg !== null && <span className={
                                                placed !== true
                                                    ? 'text-danger'
                                                    : 'text-success'
                                            }>{msg}</span>}
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                type="button"
                                                className={status === NetworkStatus.loading
                                                    ? "btn btn btn-outline-warning text-warning btn-lg"
                                                    : "btn btn-gray-500 btn-lg"
                                                }
                                                onClick={subscribeAction}
                                                disabled={status === NetworkStatus.loading}
                                            >{text[lang].subscribe.button_title} {status === NetworkStatus.loading && <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <h4 className="mb-6 text-white">{text[lang].name}</h4>
                                <ul className="list-unstyled list-inline mb-7 mb-md-0">
                                    {socialLinksDisplay}
                                </ul>

                            </div>
                            <div className="col-6 col-sm">
                                <h6 className="heading-xxs mb-4 text-white">{text[lang].footer.support}</h6>
                                <ul className="list-unstyled mb-7 mb-sm-0">
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('contact')}>{text[lang].links.contact_us}</Link>
                                    </li>
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('faq')}>{text[lang].links.faqs}</Link>
                                    </li>
                                    <li>
                                        <a className="text-gray-300" data-bs-toggle="modal" href="/">{text[lang].links.size}</a>
                                    </li>
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('returns')}>{text[lang].links.returns}</Link>
                                    </li>
                                </ul>

                            </div>
                            <div className="col-6 col-sm">
                                <h6 className="heading-xxs mb-4 text-white">{text[lang].footer.shop}</h6>
                                <ul className="list-unstyled mb-7 mb-sm-0">
                                    {displayCategories}
                                </ul>

                            </div>
                            <div className="col-6 col-sm">
                                <h6 className="heading-xxs mb-4 text-white">{text[lang].footer.company}</h6>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('our-story')}>{text[lang].links.our_story}</Link>
                                    </li>
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('careers')}>{text[lang].links.careers}</Link>
                                    </li>
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('terms-and-conditions')}>{text[lang].links.terms_and_conditions}</Link>
                                    </li>
                                    <li>
                                        <Link className="text-gray-300" to={makeArticle('privacy-cookie-policy')}>{text[lang].links.privacy_cookie_policy}</Link>
                                    </li>
                                </ul>

                            </div>
                            <div className="col-6 col-sm">
                                <h6 className="heading-xxs mb-4 text-white">{text[lang].footer.contact}</h6>
                                <ul className="list-unstyled mb-0">
                                    {contactInfo}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p className="mb-3 mb-md-0 fs-xxs text-muted">
                                    © 2022 - {new Date().getFullYear()} {text[lang].footer.all_rights} <a href='https://u1tr0n.nl/' target='_blank' rel="noreferrer">u1tr0n.nl</a>.
                                </p>

                            </div>
                            <div className="col-auto payment-methods">
                                <img className="footer-payment" src={mastercard} alt="..."/>
                                <img className="footer-payment" src={visa} alt="..."/>
                                <img className="footer-payment" src={paypal} alt="..."/>
                                <img className="footer-payment" src={maestro} alt="..."/>
                            </div>
                            <div className="col-auto">
                                <div className='btn-top'
                                     onClick={() => document.body.scrollTo({ top: 0 })}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;