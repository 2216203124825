import Header from "../parts/Header";
import {Helmet} from "react-helmet";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as routers from "../../routes/routes";
import validateEmail from "../../helpers/validators/EmailValidator";
import validatePassword from "../../helpers/validators/PasswordValidator";
import axios from "axios";
import {getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import validateUsername from "../../helpers/validators/UserNameValidator";
import {getLang, getServer, setAuth} from "../../redusers/InterfaceReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import {AuthUser} from "../../redusers/UserReducer";
import Footer from "../parts/Footer";
import {text} from "../../config/i18n/text";

export const RegistrationPage = () => {

    const [responseError, setResponseError] = useState('');
    //const [username, setUsername] = useState('');

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [emailChanged, setEmailChanged] = useState(false);

    const [signUpProgress, setSignUpProgress] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    //const [usernameValid, setUsernameValid] = useState(false);
    //const [usernameErrorText, setUsernameErrorText] = useState('');
    //const [usernameChanged, setUsernameChanged] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');
    const [firstNameChanged, setFirstNameChanged] = useState(false);

    const [lastName, setLastName] = useState('');
    const [lastNameValid, setLastNameValid] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');
    const [lastNameChanged, setLastNameChanged] = useState(false);

    let emailError = emailValid ? null : <div className='invalid-feedback'>{emailErrorText}</div>
    let firstNameError = firstNameValid ? null : <div className='invalid-feedback'>{firstNameErrorText}</div>
    let lastNameError = lastNameValid ? null : <div className='invalid-feedback'>{lastNameErrorText}</div>

    let emailFiledClass = 'form-control form-control-sm';
    if(emailChanged){
        emailFiledClass = emailValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    let firstNameFiledClass = 'form-control form-control-sm';
    if(firstNameChanged){
        firstNameFiledClass = firstNameValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    let lastNameFiledClass = 'form-control form-control-sm';
    if(lastNameChanged){
        lastNameFiledClass = lastNameValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    //let usernameError = usernameValid ? null : <div className='invalid-feedback'>{usernameErrorText}</div>
    //let usernameFiledClass = 'form-control form-control-sm';
    //if(usernameChanged){
        //usernameFiledClass = usernameValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    //}

    let passwordError = passwordValid ? null : <div className='invalid-feedback'>{passwordErrorText}</div>
    let passwordFiledClass = 'form-control form-control-sm';
    if(passwordChanged){
        passwordFiledClass = passwordValid ? 'form-control form-control-sm is-valid' : 'form-control form-control-sm is-invalid';
    }

    function changeEmail(e){
        setEmail(e.currentTarget.value);
    }
    function checkEmail(){
        let answer = validateEmail(email, lang);
        setEmailValid(answer.valid);
        setEmailChanged(true);
        setEmailErrorText(answer.error)
    }

    function changeFirstName(e){
        setFirstName(e.currentTarget.value);
    }
    function checkFirstName(){
        let answer = validateUsername(firstName, lang);
        setFirstNameValid(answer.valid);
        setFirstNameChanged(true);
        setFirstNameErrorText(answer.error)
    }

    function changeLastName(e){
        setLastName(e.currentTarget.value);
    }
    function checkLastName(){
        let answer = validateUsername(lastName, lang);
        setLastNameValid(answer.valid);
        setLastNameChanged(true);
        setLastNameErrorText(answer.error)
    }
    // function changeUsername(e){
    //     setUsername(e.currentTarget.value);
    // }
    // function checkUsername(){
    //     let answer = validateUsername(username, lang);
    //     setUsernameValid(answer.valid);
    //     setUsernameChanged(true);
    //     setUsernameErrorText(answer.error)
    // }
    function changePassword(e){
        setPassword(e.currentTarget.value);
    }
    function checkPassword(){
        let answer = validatePassword(password, lang);
        setPasswordValid(answer.valid);
        setPasswordChanged(true);
        setPasswordErrorText(answer.error)
    }

    function displayPassword(){
        setShowPassword(!showPassword);
    }

    const server = useAppSelector(getServer);

    const lang = useAppSelector(getLang);

    async function signUp() {
        let formValid = true;
        if(!emailValid){
            setEmailChanged(true);
            formValid = false;
        }
        if(!passwordValid){
            setPasswordChanged(true);
            formValid = false;
        }
        if(!firstNameValid){
            setFirstNameChanged(true);
            formValid = false;
        }
        if(!lastNameValid){
            setLastNameChanged(true);
            formValid = false;
        }
        if(formValid){
            setSignUpProgress(true);

            const body = {
                email: email,
                password: password,
                type: 'TYPE_WEB'
            };

            axios.post(
                getUrl(apiRoutes.registration, server), body
            )
                .then(async function (response) {

                    setSignUpProgress(false);

                    const newState = {
                        userId: response.data.data.id,
                        userToken: response.data.data.token,
                        userName: response.data.data.username,
                    };

                    const serializedState = JSON.stringify(newState);
                    await localStorage.setItem('user', serializedState);

                    dispatch(AuthUser(newState));
                    dispatch(setAuth())
                    navigate(routers.main);

                })
                .catch(function (error) {
                    setSignUpProgress(false);
                    console.error('NETWORK ERROR!!!!');
                    console.error(error);
                    if(false === error.response.data.status){
                        setResponseError(error.response.data.message);
                    }
                });
        }
    }

    console.error(responseError);

    return (
        <div className="happy-app bg-light">
            <Helmet>
                <title>Sign In</title>
                <meta name="description" content="Happy Wardrobe" />
            </Helmet>
            <Header/>
            <section className="py-12">
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'></div>
                        <div className="col-12 col-md-6">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <h6 className="mb-7">{text[lang].registration.new_customer}</h6>
                                    <p className="lead fs-12">{text[lang].registration.have_account} <Link to={routers.login}> {text[lang].registration.login_now}</Link></p>
                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="visually-hidden" htmlFor="registerFirstName">
                                                        {text[lang].registration.first_name}
                                                    </label>
                                                    <input
                                                        className={firstNameFiledClass}
                                                        onBlur={checkFirstName}
                                                        onChange={changeFirstName}
                                                        value={firstName}
                                                        id="registerFirstName"
                                                        type="text"
                                                        placeholder={text[lang].registration.first_name}
                                                        required
                                                    />
                                                    {firstNameError}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="visually-hidden" htmlFor="registerLastName">
                                                        {text[lang].registration.last_name}
                                                    </label>
                                                    <input
                                                        className={lastNameFiledClass}
                                                        onBlur={checkLastName}
                                                        onChange={changeLastName}
                                                        value={lastName}
                                                        id="registerLastName"
                                                        type="text"
                                                        placeholder={text[lang].registration.last_name}
                                                        required
                                                    />
                                                    {lastNameError}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="visually-hidden" htmlFor="registerEmail">
                                                        {text[lang].registration.email}
                                                    </label>
                                                    <input
                                                        className={emailFiledClass}
                                                        onBlur={checkEmail}
                                                        onChange={changeEmail}
                                                        value={email}
                                                        id="registerEmail"
                                                        type="email"
                                                        placeholder={text[lang].registration.email}
                                                        required
                                                    />
                                                    {emailError}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="visually-hidden" htmlFor="registerPassword">
                                                        {text[lang].registration.password}
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className={passwordFiledClass}
                                                            onBlur={checkPassword}
                                                            onChange={changePassword}
                                                            value={password}
                                                            id="registerPassword"
                                                            placeholder={text[lang].registration.password}
                                                            required
                                                        />
                                                        <div onClick={displayPassword} className='input-group-text c-pointer'>{showPassword ? <EyeSlash /> : <Eye />}</div>
                                                    </div>
                                                    {passwordError}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-auto">
                                                <div className="form-group fs-sm text-muted fs-12">
                                                    {text[lang].registration.text1}
                                                    <Link to={'/'}>{text[lang].registration.term_and_conditions}</Link>
                                                    {text[lang].registration.text2}
                                                    <Link to={'/'}>{text[lang].registration.privacy_policy}</Link>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="registerNewsletter"
                                                               type="checkbox"/>
                                                            <label className="form-check-label"
                                                                   htmlFor="registerNewsletter">
                                                                {text[lang].registration.newsletter}
                                                            </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-sm btn-dark"
                                                    type="button"
                                                    onClick={signUp}
                                                    disabled={signUpProgress}
                                                >
                                                    {text[lang].registration.register} { signUpProgress && <span
                                                    className="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span> }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {/*<div className="Happy-app-body">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="col-lg-6">*/}
            {/*                <div className="login-form border p-5 happy-login-form">*/}
            {/*                    <div className="text-center heading">*/}
            {/*                        <h2 className="mb-2">Sign Up</h2>*/}
            {/*                        <p className="lead">Already have an account? <Link to={routers.login}> Login now</Link>*/}
            {/*                        </p>*/}
            {/*                    </div>*/}

            {/*                    <form action="src/pages/RegistrationForm#">*/}
            {/*                        <div className="form-group mb-4">*/}
            {/*                            <label htmlFor="#">Enter Email Address</label>*/}
            {/*                            <input*/}
            {/*                                type="text"*/}
            {/*                                className={emailFiledClass}*/}
            {/*                                placeholder="Enter Email Address"*/}
            {/*                                onBlur={checkEmail}*/}
            {/*                                onChange={changeEmail}*/}
            {/*                                value={email}*/}
            {/*                            />*/}
            {/*                            {emailError}*/}
            {/*                        </div>*/}
            {/*                        <div className="form-group mb-4">*/}
            {/*                            <label htmlFor="#">Enter username</label>*/}
            {/*                            <input*/}
            {/*                                type="text"*/}
            {/*                                className={usernameFiledClass}*/}
            {/*                                placeholder="Enter Username"*/}
            {/*                                onBlur={checkUsername}*/}
            {/*                                onChange={changeUsername}*/}
            {/*                                value={username}*/}
            {/*                            />*/}
            {/*                            {usernameError}*/}
            {/*                        </div>*/}
            {/*                        <div className="col-auto">*/}
            {/*                            <label className="visually-hidden"*/}
            {/*                                   htmlFor="autoSizingInputGroup">Password</label>*/}
            {/*                            <label htmlFor="#">Enter Password</label>*/}
            {/*                            <div className="input-group">*/}
            {/*                                <input*/}
            {/*                                    type={showPassword ? 'text' : 'password'}*/}
            {/*                                    className={passwordFiledClass}*/}
            {/*                                    id="password"*/}
            {/*                                    placeholder="Enter Password"*/}
            {/*                                    onBlur={checkPassword}*/}
            {/*                                    onChange={changePassword}*/}
            {/*                                    value={password}*/}
            {/*                                />*/}
            {/*                                <div onClick={displayPassword} className='input-group-text c-pointer'>{showPassword ? <EyeSlash /> : <Eye />}</div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-auto">*/}
            {/*                            {passwordError}*/}
            {/*                        </div>*/}
            {/*                        <div className="button-line text-center mb-5">*/}
            {/*                            <div*/}
            {/*                                className={"btn btn-main mt-5 btn-block btn-login " + (signUpProgress && 'disabled') }*/}
            {/*                                onClick={signUp}*/}
            {/*                            >*/}
            {/*                                SignUp { signUpProgress && <span*/}
            {/*                                className="spinner-border spinner-border-sm" role="status"*/}
            {/*                                aria-hidden="true"></span> }*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className='terms'>By pressing "SignUIp" button you agree with our <Link to={'/terms'}>term of use</Link></div>*/}
            {/*                    </form>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default RegistrationPage;