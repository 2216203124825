import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import GroupDisplayInList from "./GroupDisplayType/GroupDisplayInList";
import GroupDisplayInDropdown from "./GroupDisplayType/GroupDisplayInDropdown";
import GroupDisplayInColor from "./GroupDisplayType/GroupDisplayInColor";

export const GroupsBlock = (data) => {

    const lang = useAppSelector(getLang);

    const list = data.groups.map(function(item, index) {
        let list = null;
        switch (item.info.type.value) {
            case 'list':
                list = <GroupDisplayInList connections={item.connections} index={index} current={item.current.value} />
                break;
            case 'dropdown':
                list = <GroupDisplayInDropdown connections={item.connections} index={index} current={item.current.value} />
                break;
            case 'color':
                list = <GroupDisplayInColor connections={item.connections} index={index} current={item.current.value} />
                break;
            default:
                break;
        }

        return <div key={'group-block-' + index}>
            <p className="mb-5">
                {item.info.titles[lang] ?? item.info.titles['en'] ?? item.info.title}: <strong>
                {item.current.titles[lang] ?? item.current.titles['en'] ?? item.current.title}
            </strong>
            </p>
            <div className="mb-2">
                { list }
            </div>
        </div>
    });

    return <div className='mt-5'>
        {list}
    </div>;
}

export default GroupsBlock;