export const AvailableCountries = [
    {
        code: 'nl',
        title: 'Netherlands'
    },
    {
        code: 'be',
        title: 'Belgium'
    }
];

export default AvailableCountries;