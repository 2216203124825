import Header from "../parts/Header";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as routers from "../../routes/routes";
import axios from "axios";
import {authKeyName, getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {getLang, getServer, setAnon} from "../../redusers/InterfaceReducer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {AuthUser, getUserToken} from "../../redusers/UserReducer";
import FullScreenLoader from "../parts/loaders/FullScreenLoader";
import {text} from "../../config/i18n/text";

export const LogoutPage = () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(getUserToken);
    const server = useAppSelector(getServer);
    const lang = useAppSelector(getLang);
    const [state, setState] = useState('loading');

    useEffect(() => {
        if(state === 'loading') {
            axios.delete(
                getUrl(apiRoutes.logout, server), {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then(async function () {
                    localStorage.removeItem('user');
                    dispatch(AuthUser({
                        userId: '',
                        userToken: '',
                        userName: '',
                    }));
                    dispatch(setAnon());
                    setState('done');

                })
                .catch(function (error) {
                    if(error.response.status === 401){
                        dispatch(setAnon());
                        localStorage.removeItem(authKeyName + 'user');
                        setState('done');

                    } else {
                        setState('error');
                    }
                });
        }
    }, [state]);

    switch (state){
        case 'loading':
            return (
                <div className="Happy-app">
                    <Header/>
                    <FullScreenLoader />
                </div>
            );
        case 'done':
            return (
                <div className="bg-light">
                    <Header/>
                    <section className="py-12">
                        <div className="container">
                            <div className="row">
                                <div className='col-3'></div>
                                <div className="col-6">
                                    <div className="card card-lg mb-10 mb-md-0">
                                        <div className="card-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="error-content text-center">
                                                            <h2 className="mb-8">{text[lang].logout.title}</h2>
                                                            <Link to={routers.main} className="btn btn-outline-dark">{text[lang].logout.button_title}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            );
        default:
            return (
                <div className="Happy-app">
                    <Header/>
                    <div className="Happy-app-body">
                        <section className="page-404 text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="error-content">
                                            <h2 className="mb-4">{text[lang].logout.wrong}</h2>
                                            <h1 className="mb-4">O<span className="text-muted">p</span>s!</h1>
                                            <Link to={routers.logout} className="btn btn-main">{text[lang].logout.try_again}</Link>
                                            <Link to={routers.main} className="btn btn-main">{text[lang].logout.button_title}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            );
    }
}

export default LogoutPage;