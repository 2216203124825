export const findCategory = (categories, slug, level) => {
    let result = [];
    let lookSlug = slug.slice(0, level+1).join('_');
    for(let i=0; i< categories.length; i++){
        if(categories[i].slug === lookSlug){
            result.push(categories[i]);
            if(categories[i].child.length > 0){
                return [
                    ...result,
                    ...findCategory(categories[i].child, slug, level +1),
                ];
            } else {
                return [
                    ...result,
                ];
            }
        }
    }
    return [];
}

export default findCategory;