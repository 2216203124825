import imagePlaceholder from "../../../assets/db-placeholder.png";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import Money from "../money/Money";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {makeItem} from "../../../routes/routes";
import { ReactComponent as Heart } from "../../../assets/dekbed/heart.svg";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getProductPageStatus, setIsLiked} from "../../../redusers/ProductReducer";
import {getUserToken, setWishListSize} from "../../../redusers/UserReducer";
import {updateWishListState} from "../../../redusers/ItemsListReducer";
import {addToCart} from "../../../redusers/CartReducer";

export const CategoryItem = (data) => {

    const dispatch = useAppDispatch();

    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);

    const link = makeItem(data.id);
    const cost = data.discount === null
        ? <div className="fw-bold text-muted"><Money amount={data.cost}/></div>
        : <>
            <span className="fs-xs text-gray-350 text-decoration-line-through"><Money amount={data.cost}/></span>
            <div className="fw-bold text-primary"><Money amount={data.discount}/></div>
        </>;

    const col = data.col ?? 6;
    const mdColl = data.mdCol ?? 4;

    const lang = useAppSelector(getLang);

    const [state, setState] = useState(NetworkStatus.idle);

    const toggleWishList = () => {
        setState(NetworkStatus.loading);
        MakeRequest(
            getUrl(apiRoutes.itemLike, server),
            'POST',
            {item: data.id},
            token,
            (response) => {
                dispatch(setIsLiked(response.data.data.isLiked));
                dispatch(setWishListSize(response.data.data.wishListSize));
                dispatch(updateWishListState({
                    id: data.id,
                    isLiked: response.data.data.isLiked,
                }))
                setState(NetworkStatus.idle);
            },
            (e) => {
                dispatch(getProductPageStatus(NetworkStatus.fail));
                setState(NetworkStatus.fail);
                console.error('response error is', e);
            });
    }

    const addToCartClick = () => {
        dispatch(addToCart({
            item: data,
            count: 1,
            total: data.cost
        }));
    }

    const wishListButton = true !== data.isLiked
        ? <button
            onClick={() => {toggleWishList(); }}
            className="btn btn-xs btn-circle btn-white-primary"
            type='button'
            disabled={state === NetworkStatus.loading}
        ><i className='fe fe-heart'></i></button>
        : <button
            onClick={() => {toggleWishList(); }}
            className="btn btn-xs btn-circle btn btn-primary"
            type='button'
            disabled={state === NetworkStatus.loading}
        ><Heart fill='white' stroke='white' className={'heart-filled-icon'} /></button>
    ;

    let firstImage = imagePlaceholder;
    let secondImage = imagePlaceholder;
    if (data?.images?.length > 0) {
        firstImage = data.images[0].thumb;
        secondImage = data.images[0].thumb;
        if (data?.images?.length > 1) {
            secondImage = data.images[1].thumb;
        }
    }

    return <div className={"col-"+col+" col-lg-3 col-md-" + mdColl + ' ' + data.className}>
        <div className="card mb-7">
            {data.new === true && <div className="badge bg-black card-badge card-badge-start text-uppercase">{text[lang].product.new}</div>}
            {data.discount !== null && <div className="badge bg-primary card-badge card-badge-end text-uppercase">{text[lang].product.sale}</div>}
            <div className="card-img">
                <Link className="card-img-hover" to={link}>
                    {secondImage !== null ? <img className="card-img-top card-img-back" src={secondImage} alt=''/> : null}

                    {firstImage !== null ? <img className="card-img-top card-img-front" src={firstImage} alt=''/> : null}


                </Link>
                <div className="card-actions">
                      <span className="card-action">
                        <button className="btn btn-xs btn-circle btn-white-primary" data-bs-toggle="modal"
                                data-bs-target="#modalProduct">
                          <i className="fe fe-eye"></i>
                        </button>
                      </span>
                    <span className="card-action">
                        <button
                            className="btn btn-xs btn-circle btn-white-primary"
                            data-toggle="button"
                            onClick={() => { addToCartClick(); }}
                        >
                          <i className="fe fe-shopping-cart"></i>
                        </button>
                      </span>
                    <span className="card-action">
                        {wishListButton}
                      </span>
                </div>
            </div>
            <div className="card-body px-0">
                <div className="fs-xs">
                    <Link className="text-muted" to={link}>{ data.category.titles[lang] ?? data.category.titles['en'] ?? data.category.name }</Link>
                </div>
                <div className="fw-bold">
                    <Link className="text-body" to={link}>
                        {(data.titles && data.titles[lang]) || (data.titles && data.titles['en']) || data.title }
                    </Link>
                </div>
                {cost}
            </div>
        </div>
    </div>;
};

export default CategoryItem;