import {text} from "../../config/i18n/text";

export function FieldLengthValidator(value, lang, fieldName, min = null, max = null)
{
    let valid = true;
    let error = '';

    if(min !== null && value !==undefined && value.length < min){
        valid = false;
        error = text[lang].validation.field_short.replace(':field:', fieldName).replace(':digits:', min);
    }
console.info('value', value, max);
    if(max !== null && value !==undefined && value.length > max){
        valid = false;
        error = text[lang].validation.field_long.replace(':field:', fieldName).replace(':digits:', max);
    }

    return {
        valid: valid,
        error: error,
    };
}

export default FieldLengthValidator;