import {Link, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../app/hooks";
import {getCategories, getInitServer, getInitUser, getLang, getServer} from "../../redusers/InterfaceReducer";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import CategoryList from "../parts/listParts/CategoryList";
import FiltersList from "../parts/listParts/FiltersList";
import Pager from "../parts/Pager";
import React, { useEffect, useState} from "react";
import CategoryHeader from "../parts/listParts/CategoryHeader";
import ItemsList from "../parts/listParts/ItemsList";
import MakeRequest from "../../helpers/network/MakeRequest";
import {getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {
    addFilters,
    changeListItemStatus,
    getCost,
    getCurrentCategory,
    getCurrentCategoryTitle,
    getCurrentPage,
    getCurrentSort,
    getDisplayFiltersMobile,
    getFilters,
    getFilterUpdateIndex,
    getItemList,
    getItemsListStatus,
    LoadFilters,
    setCategoryId,
    setCategoryTitle,
    setCurrentPage,
    setItemList, toggleMobileFilterList,
    updateItemListStatus
} from "../../redusers/ItemsListReducer";
import {getUserToken} from "../../redusers/UserReducer";
import BuildBreadCrumb from "../../helpers/catalog/BuildBreadCrumb";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import CategoryItem from "../parts/listParts/CategoryItem";
import CategoryItemPlaceholder from "../parts/listParts/CategoryItemPlaceHolder";
import InfoBlock from "../parts/block/InfoBlock";
import {clearProduct} from "../../redusers/ProductReducer";
import {text} from "../../config/i18n/text";
import * as routers from "../../routes/routes";
import {Helmet} from "react-helmet";

export const CATALOG_ITEMS_ON_PAGE = 24;

export const Category2Page = () => {
    let location = useLocation();
    const url_slug = location.pathname.replace('/category/','');
    const path = url_slug.split('_');

    const categories = useAppSelector(getCategories);
    const lang = useAppSelector(getLang);

    const breadCrumb = BuildBreadCrumb(categories, path, 0, path, lang);

    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);
    const dispatch = useAppDispatch();

    const [subCategories, setSubCategories] = useState([]);
    const showMobileFilter = useItemListSelector(getDisplayFiltersMobile);

    const itemListStatus = useItemListSelector(getItemsListStatus);
    const currentPage = useItemListSelector(getCurrentPage);

    const initServer = useItemListSelector(getInitServer);
    const initUser = useItemListSelector(getInitUser);

    const itemsList = useItemListSelector(getItemList);
    let items = [];

    const updateIndex = useItemListSelector(getFilterUpdateIndex);

    const itemsFrom = itemListStatus === NetworkStatus.idle ? CATALOG_ITEMS_ON_PAGE * (currentPage -1) : 0;
    const itemsTo = itemListStatus === NetworkStatus.idle ? Math.min(CATALOG_ITEMS_ON_PAGE * currentPage, itemsList.length) : 0;

    //const itemsCount = itemsList.total;
    const itemsCount = itemsList.length;

    const maxPage = Math.ceil(itemsCount/Number(CATALOG_ITEMS_ON_PAGE));

    function changePage(newPage){
        if(newPage <= maxPage && newPage > 0){
            document.body.scrollTo({ top: 0 });
        }
    }

    const sortBy = useItemListSelector(getCurrentSort);
    const cost = useItemListSelector(getCost);
    const filtersData = useItemListSelector(getFilters);

    const categoryId = useItemListSelector(getCurrentCategory);
    const categoryTitle = useItemListSelector(getCurrentCategoryTitle);

    useEffect(() => {
        if (true === initServer && true === initUser) {
            MakeRequest(getUrl(apiRoutes.category_info, server) + '?slug=' + url_slug, 'GET', {}, token, (response) => {
                dispatch(setCategoryId(response.data.data.info.id));
                dispatch(setCategoryTitle(response.data.data.info.name));
                setSubCategories(response.data.data.child);
                let values = {};
                for (let i in response.data.data.filters) {
                    let vals = {};
                    for (let j in response.data.data.filters[i].values) {
                        vals[response.data.data.filters[i].values[j].id] = {
                            id: response.data.data.filters[i].values[j].id,
                            name: response.data.data.filters[i].values[j].value,
                            title: response.data.data.filters[i].values[j].title,
                            titles: response.data.data.filters[i].values[j].titles,
                            value: false,
                        };
                    }
                    values[response.data.data.filters[i].id] = {
                        name: response.data.data.filters[i].name,
                        title: response.data.data.filters[i].title,
                        titles: response.data.data.filters[i].titles,
                        id: response.data.data.filters[i].id,
                        type: response.data.data.filters[i].type,
                        values: vals,
                    }
                }
                dispatch(LoadFilters());
                dispatch(addFilters(values));
            }, (e) => {
                console.error('response error is', e);
            });
        }

        // return () => {
        //     dispatch(updateItemListStatus(NetworkStatus.loading));
        // }
    }, [url_slug, token, initServer, initUser]);

    useEffect(() => {
        if (true === initServer && true === initUser && null !== categoryId) {
            makeItemsRequest();
            return () => {
                dispatch(clearProduct());
                dispatch(updateItemListStatus(NetworkStatus.loading));
            }
        }
    },[sortBy, updateIndex, initServer, initUser, categoryId]);

    if(itemListStatus === NetworkStatus.idle){
        if(itemsList.length < 1){
            items = [];
            items.push(<section className="py-1" key={'no-items-to-show-block'}>
                <div className="container border">
                    <div className="row">
                        <div className='col-1'></div>
                        <div className="col-10">
                            <div className="card card-lg mb-10 mb-md-0">
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="error-content text-center">
                                                    <h1 className="mb-8 text-danger">{text[lang].category.no_items.title}</h1>
                                                    <p>{text[lang].category.no_items.text}</p>
                                                    <Link to={routers.main} className="btn btn-outline-dark">{text[lang].category.no_items.button_home_title}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>);
        } else {
            items = itemsList.slice(itemsFrom, itemsTo).map((item, index) => {
                return <CategoryItem
                    key={'item-' + index + '-id-' + item.id}
                    id={item.id}
                    title={item.title}
                    titles={item.titles}
                    cost={item.cost}
                    isLiked={item.isLiked}
                    discount={null}
                    category={item.category}
                    images={item.images}
                    new={false}
                    sale={false}
                />
            });
        }
    } else if(itemListStatus === NetworkStatus.loading) {
        items = Array.from('123456789123').map((item, index) => {
            return <CategoryItemPlaceholder key={'place-holder-' + index} />
        });
    }

    function makeItemsRequest(){
        dispatch(updateItemListStatus(NetworkStatus.loading));
        let data = {
            count: 10000,
            offset: 0,
        };
        switch (sortBy){
            case 'popular':
                data['orderBy'] = "cost";
                data['order'] = "asc";
                break;
            case 'avg-rating':
                data['orderBy'] = "cost";
                data['order'] = "asc";
                break;
            case 'price-asc':
                data['orderBy'] = "cost";
                data['order'] = "asc";
                break;
            case 'price-desc':
                data['orderBy'] = "cost";
                data['order'] = "desc";
                break;
            case 'latest':
            default:
                data['orderBy'] = "date";
                data['order'] = "desc";
                break;
        }
        let filters = {};
        if(cost.min > 0){
            if(filters['cost']=== undefined){
                filters['cost'] = {};
            }
            filters['cost']['min'] = cost.min;
        }
        if(cost.max < 10000){
            if(filters['cost'] === undefined){
                filters['cost'] = {};
            }
            filters['cost']['max'] = cost.max ?? 10000;
        }
        if(cost !== {}){
            data['filters'] = filters;
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks

        for(let i in filtersData){
            let filtersVariants = [];
            for(let j in filtersData[i].values){
                if(filtersData[i].values[j].value === true){
                    filtersVariants.push(filtersData[i].values[j].name);
                }
            }
            if(filtersVariants.length > 0){
                data['filters'][filtersData[i].name] = filtersVariants;
            }
        }
        data.category = categoryId;
        MakeRequest(getUrl(apiRoutes.itemsList, server), 'POST',data, token, (response) => {
            console.log('incoming data', response.data);
            dispatch(setItemList(response.data.items));
            const tmr = setInterval(() => {
                clearInterval(tmr);
                dispatch(changeListItemStatus(NetworkStatus.idle));
            }, 100);
            changePage(1);
        }, (e) => { dispatch(changeListItemStatus(NetworkStatus.fail)); console.error('response error is', e);});
    }

    const mobileClass = true === showMobileFilter ? 'col-12 col-md-4 col-lg-3' : 'col-12 col-md-4 col-lg-3 d-none d-sm-none d-md-block';

    return (
        <>
            <Helmet>
                <title>{text[lang].seo.category.title + categoryTitle}</title>
                <meta name="description" content={text[lang].seo.category.description} />
            </Helmet>
            <Header />
            <section className="py-11" name='top'>
                <div className="container">
                    <div className="row">
                        <button onClick={() => dispatch(toggleMobileFilterList()) } className='btn btn-dark btn-small hvr-grow d-md-none d-lg-none'>
                            {true === showMobileFilter ? text[lang].category.filter_button.hide_button : text[lang].category.filter_button.show_button}
                        </button>
                        <div className={mobileClass}>
                            <form className="mb-10 mb-md-0 ">
                                <ul className="nav nav-vertical" id="filterNav">
                                    <CategoryList key='catalog-list' subCategories={subCategories} />
                                    <FiltersList key='filter-list' />
                                </ul>
                            </form>
                        </div>
                        <div className="col-12 col-md-8 col-lg-9">
                            <CategoryHeader breadCrumb={breadCrumb} />
                            <ItemsList items={items} key='item-list-section' />
                            {maxPage > 0 && <Pager page={currentPage} max={maxPage} click={setCurrentPage} />}
                        </div>
                    </div>
                </div>
            </section>
            <InfoBlock />
            <Footer />
        </>
    );
}

export default Category2Page;