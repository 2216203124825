import * as routerPath from "../../../routes/routes";
import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {Link} from "react-router-dom";

export const CheckoutBreadcrumb = () => {

    const lang = useAppSelector(getLang);

    return <nav className="py-5">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <ol className="breadcrumb mb-0 fs-xs text-gray-400">
                        <li className="breadcrumb-item">
                            <Link
                                className="text-gray-400"
                                to={routerPath.main}
                            >{text[lang].checkout.breadcrumb.home}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            {text[lang].checkout.breadcrumb.checkout}
                        </li>
                    </ol>

                </div>
            </div>
        </div>
    </nav>;
}

export default CheckoutBreadcrumb;