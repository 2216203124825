import findCategory from "./FindCategory";
import {Link} from "react-router-dom";
import {makeCategory} from "../../routes/routes";
import React from "react";

export const BuildBreadCrumb = (categories, slug, level, path, lang) => {
    const slugData = findCategory(categories, path, 0);
    let currentSlug = '';
    return slugData.map((item, index) => {
        if(currentSlug !== ''){
            currentSlug += '_';
        }
        currentSlug += item;

        return <li key={'bread-crumb-' + index} className="breadcrumb-item">
            <Link className="text-gray-400" to={makeCategory(item.slug)}>
                {item.titles[lang] ?? item.titles['en'] ?? item.title}
            </Link>
        </li>;
    });
};
export default BuildBreadCrumb;