import { createSlice } from '@reduxjs/toolkit';
import {authKeyName} from "../config/config";


const initialState = {
    isUserAuthorized: false,
    userAuthStatus: 'loading',
    categoryMenu1: 0,
    server: null,
    initUser: false,
    initServer: false,
    lang: process.env.REACT_APP_DEFAULT_LANGUAGE,
    categories: [],

};

export const AuthState = {
    failed: 'failed',
    idle: 'idle',
    loading: 'loading',
};

export const userSlice = createSlice({
    name: 'interface',
    initialState,
    reducers: {
        setServer: (state, payload) => {
            state.server = payload.payload;
            state.initServer = true;
        },
        setLang: (state, payload) => {
            localStorage.setItem(authKeyName + 'lang', payload.payload);
            state.lang = payload.payload;
        },
        setAuth:(state) => {
            state.isUserAuthorized = true;
        },
        setAnon:(state) => {
            state.isUserAuthorized = false;
        },
        statusFail:(state) => {
            state.userAuthStatus = AuthState.failed;
        },
        statusIdle:(state) => {
            state.userAuthStatus = AuthState.idle;
        },
        statusLoading:(state) => {
            state.userAuthStatus = AuthState.loading;
        },

        setCategories:(state, payload) => {
            state.categories = payload.payload;
        },

        setInitUser: (state, payload) => {
            state.initUser = payload.payload;
        },
        setInitServer: (state, payload) => {
            state.initServer = payload.payload;
        },
        setSelectedMenu: (state, payload) => {
            state.categoryMenu1 = payload.payload;
        },
    },
});

export const {
    setAuth,
    setAnon,
    statusFail,
    statusIdle,
    statusLoading,
    setCategories,
    setServer,
    setInitUser,
    setInitServer,
    setLang,
    setSelectedMenu,
} = userSlice.actions;

export const isUserAuth = (state) => state.interface.isUserAuthorized;
export const getServer = (state) => state?.interface?.server;
export const AuthLoadingStatus = (state) => state.interface.userAuthStatus;
export const getCategories = (state) => state.interface.categories;
export const getInitUser = (state) => state.interface.initUser;
export const getInitServer = (state) => state.interface.initServer;
export const getLang = (state) => state.interface.lang;
export const getSelectedMenu = (state) => state.interface.categoryMenu1;

export default userSlice.reducer;