import {text} from "../../../../config/i18n/text";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../../app/hooks";
import {AuthLoadingStatus, getInitServer, getInitUser, getLang, getServer} from "../../../../redusers/InterfaceReducer";
import {useEffect, useState} from "react";
import {NetworkStatus} from "../../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../../helpers/network/MakeRequest";
import {getUrl} from "../../../../config/config";
import {apiRoutes} from "../../../../config/apiRoutes";
import {getNewestItems, setNewest} from "../../../../redusers/MainReducer";
import Flickity from "react-flickity-component";
import CategoryItem from "../../listParts/CategoryItem";

export const MainLatestItemsBlock = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const server = useAppSelector(getServer);
    const list = useAppSelector(getNewestItems);
    const globalStatus = useAppSelector(AuthLoadingStatus);

    const [state, setState] = useState(NetworkStatus.loading);

    const flickityOptions = {
        initialIndex: 1,
        prevNextButtons: true,
        fade: true,
        accessibility: true,
        adaptiveHeight: false,
        cellSelector: undefined,
        pageDots: false,
    }

    const initServer = useItemListSelector(getInitServer);
    const initUser = useItemListSelector(getInitUser);


    useEffect(() => {
        if(true === initUser && true === initServer) {
            MakeRequest(getUrl(apiRoutes.latestItems, server), 'GET', {}, '', (response) => {
                dispatch(setNewest(response.data.result));
                setState(NetworkStatus.idle);
            }, (e) => {
                console.error('response error is', e);
            });
        }
    },[globalStatus, state, initServer, initUser]);

    if(state === NetworkStatus.loading){
        return null;
    }

    const itemList = list.map((item, index) => {

        return <CategoryItem
            key={'item-newest-' + index + '-id-' + item.id}
            className={'p-3'}
            col={6}
            mdCol={12}
            id={item.id}
            title={item.title}
            cost={item.cost}
            isLiked={item.isLiked}
            discount={null}
            category={item.category}
            iamges={item.images}
            new={true}
            sale={false}
        />;
    });

    return <section className="pt-3">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="mb-4 text-center">{text[lang].main_page.newest_items}</h2>
                    <Flickity
                        className={'carousel'}
                        elementType={'div'}
                        options={flickityOptions}
                        disableImagesLoaded={false}
                        reloadOnUpdate
                        static
                    >
                        {itemList}
                    </Flickity>
                </div>
            </div>
        </div>
    </section>;
}

export default MainLatestItemsBlock;