export const FullPageLoader = () => {
    return <section className="py-12">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <div className="mb-7 fs-1">
                        <div className="spinner-grow text-primary mb-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default FullPageLoader;