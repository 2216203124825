import img1 from "./../../../../assets/img/covers/cover-5.jpg";
import img2 from "./../../../../assets/img/covers/cover-23.jpg";
import img3 from "./../../../../assets/img/covers/cover-16.jpg";
import Flickity from 'react-flickity-component'
import React from "react";

export const MainSliderBlock = (data) => {
    const params = {
        minHeight: 550,
        ...data
    };
    const flickityOptions = {
        initialIndex: 0,
        prevNextButtons: true,
        fade: true,
        accessibility: true,
        adaptiveHeight: false,
        cellSelector: undefined,
        pageDots: false,
    }

    return <section>
        <Flickity
            className={'carousel'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
        >
            <div className="w-100 bg-cover"
                 style={{backgroundImage: `url(${img1})`}}
            >
                <div className="container d-flex flex-column">
                    <div
                        className="row align-items-center py-12"
                        style={{minHeight: params.minHeight}}
                    >
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1">
                            <h1>Summer Sale</h1>
                            <h2 className="display-1 text-primary">-70%</h2>
                            <h5 className="mt-n4 mb-8">with promo code CN67EW*</h5>
                            <a className="btn btn-dark" href="#!ads1">
                                Shop Now <i className="fe fe-arrow-right ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 bg-cover"
                 style={{backgroundImage: `url(${img2})`}}
            >
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-end py-12"
                         style={{minHeight: params.minHeight}}
                    >
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-n1">
                            <h1 className="mb-5">Summer Collection</h1>
                            <p className="mb-8 fs-lg text-gray-500">
                                So called give, one whales tree seas dry place own
                                day, winged tree created spirit.
                            </p>
                            <a className="btn btn-dark" href="#!ads2">
                                Shop Now <i className="fe fe-arrow-right ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 bg-cover"
                 style={{backgroundImage: `url(${img3})`}}
            >
                <div className="bg-dark-20">
                    <div className="container d-flex flex-column">
                        <div className="row align-items-center justify-content-end"
                             style={{minHeight: params.minHeight}}
                        >
                            <div className="col-12 text-center text-white">

                                <h4 className="mb-0">Summer Styles</h4>
                                <h1 className="display-1">
                                    50% OFF
                                </h1>

                                <p className="mb-0">
                                    <a href="#!ads3" className="link-underline text-reset mx-4 my-4">Shop Women</a>
                                    <a href="#!ads4" className="link-underline text-reset mx-4 my-4">Shop Men</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Flickity>
    </section>;
}
export default MainSliderBlock;