import React from "react";
import {Card, Placeholder} from "react-bootstrap";

export const CategoryItemPlaceholder = () => {
    return <div className="col-6 col-md-3">
        <div className="card mb-7">
            <div className="image-placeholder"></div>
            <Placeholder as={Card.Title} animation="glow" className='placeholder-item-title'>
                <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Body} color='red' animation="glow" className='placeholder-item-cost'>
                <Placeholder xs={2} />
            </Placeholder>
        </div>
    </div>;
};

export default CategoryItemPlaceholder;