import {ExclamationCircle, Trash3} from "react-bootstrap-icons";
import React from "react";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import {Spinner} from "react-bootstrap";

export const ImagePreview = (data) => {
    let style = {};
    if(data.image.status === NetworkStatus.idle){
        style = {
            backgroundImage:  `url(${data.image.url.small})`
        }
    }
    return <li key={'image-prv-' + data.index} className={data.image.status === NetworkStatus.fail && 'fail'} style={style}>
        {data.image.status === NetworkStatus.loading && <Spinner animation="border" variant="primary" />}
        {data.image.status === NetworkStatus.fail && <ExclamationCircle size={30} />}
        {data.image.status === NetworkStatus.idle && <span className='image-remove' onClick={() => {
            data.removeImage();
        }
        }><Trash3 /></span>}
    </li>;
}

export default ImagePreview;