export const ItemGroups = () => {
    return null;
    // return <>
    //     <div className="form-group">
    //         <p className="mb-5">Color: <strong id="colorCaption">Pink</strong></p>
    //         <div className="mb-8 ms-n1">
    //             <div className="form-check form-check-inline form-check-img">
    //                 <input type="radio" className="form-check-input"
    //                        id="imgRadioOne" name="imgRadio"
    //                        data-toggle="form-caption"
    //                        data-target="#colorCaption" value="Pink"
    //                        // style="background-image: url(assets/img/products/product-157.jpg);"
    //                        checked/>
    //             </div>
    //         </div>
    //     </div>
    //     <div className="form-group">
    //         <p className="mb-5">Size: <strong><span id="sizeCaption">S</span></strong></p>
    //         <div className="mb-2">
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioOne" value="3XS"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioOne">3XS</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioTwo" value="2XS"
    //                        data-toggle="form-caption" data-target="#sizeCaption"
    //                        disabled/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioTwo">2XS</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioThree" value="XS"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioThree">XS</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioFour" value="S"
    //                        data-toggle="form-caption" data-target="#sizeCaption"
    //                        checked/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioFour">S</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioFive" value="M"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioFive">M</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioSix" value="L"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioSix">L</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioSeven" value="XL"
    //                        data-toggle="form-caption" data-target="#sizeCaption"
    //                        disabled/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioSeven">XL</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioEight" value="2XL"
    //                        data-toggle="form-caption" data-target="#sizeCaption"
    //                        disabled/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioEight">2XL</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioNine" value="3XL"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioNine">3XL</label>
    //             </div>
    //             <div
    //                 className="form-check form-check-inline form-check-size mb-2">
    //                 <input type="radio" className="form-check-input"
    //                        name="sizeRadio" id="sizeRadioTen" value="4XL"
    //                        data-toggle="form-caption"
    //                        data-target="#sizeCaption"/>
    //                     <label className="form-check-label"
    //                            htmlFor="sizeRadioTen">4XL</label>
    //             </div>
    //         </div>
    //         <p className="mb-8">
    //             <img src="/assets/img/icons/icon-ruler.svg" alt="..."
    //                  className="img-fluid"/> <a
    //                 className="text-reset text-decoration-underline ms-3"
    //                 data-bs-toggle="modal" href="#modalSizeChart">Size chart</a>
    //         </p>
    //     </div>
    // </>;
}
export default ItemGroups;