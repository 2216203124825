import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import Money from "../money/Money";
import PromoCodeBlock from "./PromoCodeBlock";
import {
    getForm, getOrderInfo, getPaymentMethod,
    getShippingCost,
    getShippingDifferentAddress,
    getShippingPosition, orderPlace, setFormBlur
} from "../../../redusers/CheckoutReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import shippingVariants from '../../models/checkout/ShippingVariantsModel';
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken} from "../../../redusers/UserReducer";
import {triggerAllValidators} from "../../../helpers/form/FormBuild";
import billingFields from "../../models/checkout/BillingFormModel";
import shippingFields from "../../models/checkout/ShippingFormModel";

export const PlaceOrderBlock = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const shippingCost = useAppSelector(getShippingCost);
    const form = useAppSelector(getForm);
    const order = useAppSelector(getOrderInfo);

    const shippingDifferentAddress = useAppSelector(getShippingDifferentAddress);

    const server = useAppSelector(getServer);
    const token = useAppSelector(getUserToken);

    const shippingPosition = useAppSelector(getShippingPosition);

    const paymentMethod = useAppSelector(getPaymentMethod);



    const [state, setState] = useState(NetworkStatus.idle);



    const PlaceOrderAction = () => {

        const data ={
            billingFirstName: form.values.billingFirstName,
            billingLastName: form.values.billingLastName,
            billingEmail: form.values.billingEmail,
            billingCompany: form.values.billingCompany,
            billingCountry: form.values.billingCountry,
            billingAddress1: form.values.billingAddress1,
            billingAddress2: form.values.billingAddress2,
            billingCity: form.values.billingCity,
            billingZip: form.values.billingZip,
            billingMobile: form.values.billingMobile,
            note: form.values.note,
            payment: paymentMethod,
            order: order.id,
            shipping: shippingVariants[shippingPosition],
            ...shippingDifferentAddress ? {
                shippingCompany: form.values.shippingCompany,
                shippingCountry: form.values.shippingCountry,
                shippingAddress1: form.values.shippingAddress1,
                shippingAddress2: form.values.shippingAddress2,
                shippingCity: form.values.shippingCity,
                shippingZip: form.values.shippingZip,
            } : {}
        };
        const formValid = triggerAllValidators(dispatch, setFormBlur, lang, billingFields, form);
        const formShippingValid = shippingDifferentAddress
            ? triggerAllValidators(dispatch, setFormBlur, lang, shippingFields, form)
            : true;

        if(true === formValid && true === formShippingValid) {
            setState(NetworkStatus.loading);
            MakeRequest(
                getUrl(apiRoutes.orderPlace, server),
                'post', data, token, () => {
                    setState(NetworkStatus.idle);
                    dispatch(orderPlace());
                    document.body.scrollTo({ top: 0 });

                }, (e) => {
                    if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                        setState(NetworkStatus.idle);
                    } else {
                        console.error('response error is', e);
                        setState(NetworkStatus.fail);
                    }
                });
        }
    };


    return <>
        <PromoCodeBlock />
        <div className="card mb-9 bg-light">
            <div className="card-body">
                <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x">
                    <li className="list-group-item d-flex">
                        <span>{text[lang].checkout.subtotal}</span> <span className="ms-auto fs-sm">
                        <Money amount={order.subTotal}/>
                        </span>
                    </li>
                    <li className="list-group-item d-flex">
                        <span>{text[lang].checkout.discount}</span> <span className="ms-auto fs-sm">
                        {/*//order.discount*/}
                        <Money amount={order.discount}/></span>
                    </li>
                    <li className="list-group-item d-flex">
                        <span>{text[lang].checkout.shipping}</span> <span className="ms-auto fs-sm">
                        <Money amount={shippingCost}/></span>
                    </li>
                    <li className="list-group-item d-flex fs-lg fw-bold">
                        <span>{text[lang].checkout.order_items}</span> <span className="ms-auto">
                        <Money amount={order.subTotal + shippingCost - order.discount}/>
                    </span>
                    </li>
                </ul>
            </div>
        </div>
        <p className="mb-7 fs-xs text-gray-500">
            {text[lang].checkout.order_text_info}
        </p>
        <button
            className="btn w-100 btn-dark"
            disabled={state === NetworkStatus.loading}
            onClick={PlaceOrderAction}
        >{text[lang].checkout.order_button_title} {state !== NetworkStatus.idle && <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>}</button>
    </>;
}

export default PlaceOrderBlock;