import AccountMenu from "../../parts/account/AccountMenu";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import Footer from "../../parts/Footer";
import {text} from "../../../config/i18n/text";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {AuthLoadingStatus, getLang, getServer} from "../../../redusers/InterfaceReducer";
import Pager from "../../parts/Pager";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken, getWishList, getWishListPage, setWishList, setWishListPage} from "../../../redusers/UserReducer";
import AccountWishListItem from "../../parts/account/AccountWishListItem";

export const wishListPageSize = 24;

export const PersonalWishlistPage = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);
    const globalStatus = useItemListSelector(AuthLoadingStatus);
    const page = useAppSelector(getWishListPage);

    const [state, setState] = useState(NetworkStatus.loading);

    const list = useAppSelector(getWishList);

    const maxPage = Math.ceil(list.length/wishListPageSize);

    useEffect(() => {
        if(globalStatus === NetworkStatus.idle){
            MakeRequest(getUrl(apiRoutes.myWishList, server), 'GET',{}, token, (response) => {
                dispatch(setWishList(response.data.data.items));
                setState(NetworkStatus.idle);

            }, (e) => {console.error('response error is', e);});
        }
    }, [globalStatus, state]);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [state]);

    const itemsFrom = state === NetworkStatus.idle ? wishListPageSize * (page -1) : 0;
    const itemsTo = state === NetworkStatus.idle ? Math.min(wishListPageSize * page, list.length ) : 0;

    const itemsListsForDisplay = list.slice( itemsFrom, itemsTo).map((item, index) => {
        return <AccountWishListItem
            key={'wish-list-item-' + index}
            title={item.title}
            price={item.cost}
            images={item.images}
            id={item.id}
        />
    });

    return <>
        <Helmet>
            <title>{text[lang].seo.account.wishlist.title}</title>
            <meta name="description" content={text[lang].seo.account.wishlist.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header/>
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.wishlist.page_title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={1} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        <div className="row">
                            {itemsListsForDisplay}
                            {list.length > 0 ? null : <div className="text-center">
                                <div className="mb-6 fs-1">{text[lang].review.smile}</div>
                                <p>{text[lang].account.wishlist.no_items}</p>
                            </div>}
                        </div>
                        <Pager page={page} max={maxPage} click={setWishListPage} />
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>;
}

export default PersonalWishlistPage;