import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {createReview} from "../../../redusers/ProductReducer";
import {text} from "../../../config/i18n/text";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {getUserToken} from "../../../redusers/UserReducer";
import {useState} from "react";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import {Star, StarFill} from "react-bootstrap-icons";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";

export const ReviewForm = (data) => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const token = useItemListSelector(getUserToken);
    const server = useAppSelector(getServer);

    const [title, setTitle] = useState('');
    const [review, setReview] = useState('');
    const [score, setScore] = useState(0);

    const [titleError, setTitleError] = useState(null);
    const [reviewError, setReviewError] = useState(null);
    const [scoreError, setScoreError] = useState(null);

    const [status, setStatus] = useState(NetworkStatus.idle);

    const [reviewPosted, setReviewPosted] = useState(false);

    if (true === reviewPosted) {
        return null;
    }

    // if (null !== placed) {
    //     return null;
    // }

    const titleInputClass = titleError === null ? ['form-control', 'form-control-sm'] : ['form-control', 'form-control-sm', 'is-invalid'];
    const textInputClass = titleError === null ? ['form-control', 'form-control-sm'] : ['form-control', 'form-control-sm', 'is-invalid'];

    const displayScore = Array.from('12345').map((item, index) => {
        let className = ['c-pointer', 'score-star'];
        if (null === scoreError) {
            switch (score){
                case 1:
                case 2:
                    className.push('text-primary');
                    break;
                case 3:
                    className.push('text-warning');
                    break;
                case 4:
                case 5:
                    className.push('text-success');
                    break;
                default:
                    className.push('text-dark');
                    break;
            }
        } else {
            className.push('text-danger');
        }
        return index < score
            ? <StarFill
                className={className.join(' ')}
                key={'score-value-' + index}
                onClick={() => {setScoreValue(index+1);}}
            />
            : <Star
                className={className.join(' ')}
                key={'score-value-' + index}
                onClick={() => {setScoreValue(index+1);}}
            />;
    });
    function setScoreValue(value){
        if(status !== NetworkStatus.loading){
            setScore(value);
            setScoreError(null);
        }
    }
    const createReviewAction = () => {
        if (status === NetworkStatus.idle) {
            let breakSubmit = false;
            setStatus(NetworkStatus.loading);
            if (score < 1 || score > 5){
                setScoreError(text[lang].review.error.score_error);
                setStatus(NetworkStatus.idle);
                breakSubmit = true;
            }
            if(title.length < 5 || title.length > 255){
                setTitleError(text[lang].review.error.title_error);
                setStatus(NetworkStatus.idle);
                breakSubmit = true;
            }
            if(review.length < 5 || title.length > 4000){
                setReviewError(text[lang].review.error.text_error);
                setStatus(NetworkStatus.idle);
                breakSubmit = true;
            }

            if(true === breakSubmit){
                return null;
            }
            const sendData = {
                title: title,
                text: review,
                itemId: data.itemId,
                score: score,
            };
            MakeRequest(getUrl(apiRoutes.itemReviewAdd, server),
                'POST',
                sendData,
                token,
                (response) => {
                    if(data.reviewRef){
                        data.reviewRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                    setStatus(NetworkStatus.idle);
                    dispatch(createReview({
                        review: response.data.data.review,
                        total: response.data.data.total,
                        yourScore: response.data.data.yourScore,
                        avgScore: response.data.data.avgScore,
                    }));
                    setReviewPosted(true);

                    //setTotal(total + 1);
                    //reviewsList.unshift(response.data.data);
                    //setReviewsList(reviewsList);
                },
                (e) => {
                    console.error('req error', e);
                    setStatus(NetworkStatus.fail);
                });
        }
    }
//
    return <div className="collapse" id="reviewForm" >
         <hr className="my-8"/>
         <form ref={data.formRef}>
             <div className="row">
                 <div className="col-12 mb-6 text-center">
                     <p className="mb-1 fs-xs">{text[lang].review.score}:</p>
                     <div className="rating-form">
                         <div className="rating h5 text-dark">
                             {displayScore}
                         </div>
                     </div>
                     {scoreError === null ? null : <div className='text-danger text-size-error'>{scoreError}</div>}
                 </div>
                 <div className="col-12">
                     <div className="form-group">
                         <label className="visually-hidden" htmlFor="reviewTitle">{text[lang].review.input_title_placeholder}:</label>
                         {titleError === null ? null : <div className='text-danger text-size-error'>{titleError}</div>}
                         <input
                             className={titleInputClass.join(' ')}
                             id="reviewTitle"
                             type="text"
                             value={title}
                             onChange={(e) => {setTitle(e.target.value)}}
                             onFocus={() => {setTitleError(null)}}
                             placeholder={text[lang].review.input_title_placeholder + " *"}
                             required
                             disabled={status === NetworkStatus.loading}
                         />
                     </div>
                 </div>
                 <div className="col-12">
                     <div className="form-group">
                         <label className="visually-hidden"
                                htmlFor="reviewText">{text[lang].review.input_text_placeholder}:</label>
                         {reviewError === null ? null : <div className='text-danger text-size-error'>{reviewError}</div>}
                         <textarea
                             className={textInputClass.join(' ')}
                             id="reviewText"
                             rows="5"
                             placeholder={text[lang].review.input_text_placeholder + " *"}
                             required
                             value={review}
                             disabled={status === NetworkStatus.loading}
                             onChange={(e) => {setReview(e.target.value)}}
                             onFocus={() => {setReviewError(null)}}
                         ></textarea>
                     </div>

                 </div>
                 <div className="col-12 text-center">
                     <button
                         className="btn btn-outline-dark"
                         type="button"
                         onClick={() => { createReviewAction(); }}
                         disabled={status === NetworkStatus.loading}
                     >
                         {text[lang].review.title_submit_button}

                         {status === NetworkStatus.loading ? <div className="spinner-border fs-6 text-primary" role="status">
                             <span className="visually-hidden fs-6">Loading...</span>
                         </div> : null}
                     </button>
                 </div>
             </div>
         </form>
     </div>
}
export default ReviewForm;