import {text} from "./i18n/text";

export const sorting = (lang) => {
    return [
        { title: text[lang].category.sorting.default, name: "default"},
        { title: text[lang].category.sorting.popular, name: "popular"},
        { title: text[lang].category.sorting.rating, name: "avg-rating"},
        { title: text[lang].category.sorting.latest, name: "latest"},
        { title: text[lang].category.sorting.pricea, name: "price-asc"},
        { title: text[lang].category.sorting.priced, name: "price-desc"},
    ]
}
export default sorting;