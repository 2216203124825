import React, {useState} from "react";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import ReviewElementBlock from "./ReviewElementBlock";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import {getUserToken} from "../../../redusers/UserReducer";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUrl, reviewsPageSize} from "../../../config/config";
import MakeRequest from "../../../helpers/network/MakeRequest";
import StarRatingDisplay from "../StarRatingDisplay";
import {
    addReviews,
    getProductReviewPagination,
    getProductReviews,
    getProductYourScore,
    isProductPlaced
} from "../../../redusers/ProductReducer";
import ReviewForm from "./ReviewForm";
import MakeGetParams from "../../../helpers/network/MakeGetParams";

export const ReviewBlock = (data) => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const token = useItemListSelector(getUserToken);
    const server = useAppSelector(getServer);

    const placed = useAppSelector(isProductPlaced);
    const reviews = useAppSelector(getProductReviews);
    const yourScore = useAppSelector(getProductYourScore);

    const [status, setStatus] = useState(NetworkStatus.idle);

    const [
        rTotal,
        rCount,
        rOffset
    ] = useAppSelector(getProductReviewPagination);

    const reviewsData = reviews.map((item, index) => <ReviewElementBlock key={'review-' + index} info={item}/>);

    const loadMore = () => {
        setStatus(NetworkStatus.loading);
        const sendData = {
            count: reviewsPageSize,
            offset: rOffset + reviewsPageSize,
            item: data.itemId
        };
        MakeRequest(getUrl(apiRoutes.itemReviewList + MakeGetParams(sendData), server),
            'GET',
            {},
            token,
            (response) => {
                setStatus(NetworkStatus.idle);
                dispatch(addReviews({reviews: response.data.data.list}));
            },
            (e) => {
                console.error('req error', e);
                setStatus(NetworkStatus.fail);
            });
    };


    return <section className="pt-9 pb-11" id="reviews">
     <div className="container">
         <div className="row">
             <div className="col-12">
                 <h4 className="mb-10 text-center">{text[lang].review.title} {rTotal > 0 ? <span> ( <span className='text-primary'>{rTotal ?? 0}</span> )</span> : null}</h4>
                 {rTotal > 0 ? null : <div className="text-center">
                     <div className="mb-6 fs-1">{text[lang].review.smile}</div>
                     <p>{text[lang].review.text}</p>
                 </div>}
                 {placed === false && yourScore === null
                     ? <div className='text-center'>
                         <a
                             className="btn btn-sm btn-dark"
                             data-bs-toggle="collapse"
                             href="#reviewForm"
                         >
                            {text[lang].review.title_button}
                         </a>
                         <br />
                         <br />
                     </div>
                     : <div className='card'>
                         <div className='card-body border text-center'>
                             <h3>{text[lang].review.already_placed}</h3>
                             <StarRatingDisplay size={40} score={yourScore} />
                         </div>
                         <br />
                         <br />
                     </div>
                 }
                 <div className='row'>{reviewsData}</div>
                 {rCount < rTotal ? <div className='row'>
                     <button
                         className="btn btn-sm btn-outline-primary"
                         type='button'
                         disabled={status === NetworkStatus.loading}
                         onClick={() => {
                             loadMore();
                         }}
                     >{text[lang].review.load_more}  {status === NetworkStatus.loading ? <div className="spinner-border fs-6 text-primary" role="status">
                         <span className="visually-hidden fs-6">Loading...</span>
                     </div> : null}</button>
                 </div> : null}
                 <ReviewForm itemId={data.itemId} />
             </div>
         </div>
     </div>
    </section>;

    // function setScoreValue(value){
    //     if(status !== NetworkStatus.loading){
    //         setScore(value);
    //         setScoreError(null);
    //     }
    // }

    // useEffect(() => {
    //     setReviewsList(data?.reviews?.list ?? []);
    //     setTotal(data.reviews.total);
    // },[data.reviews, data.itemId]);

    // const formRef = React.createRef();
    // const reviewRef = React.createRef();
    //
    // const displayScore = Array.from('12345').map((item, index) => {
    //     let className = ['c-pointer', 'score-star'];
    //     if (null === scoreError) {
    //         switch (score){
    //             case 1:
    //             case 2:
    //                 className.push('text-primary');
    //                 break;
    //             case 3:
    //                 className.push('text-warning');
    //                 break;
    //             case 4:
    //             case 5:
    //                 className.push('text-success');
    //                 break;
    //             default:
    //                 className.push('text-dark');
    //                 break;
    //         }
    //     } else {
    //         className.push('text-danger');
    //     }
    //     return index < score
    //         ? <StarFill
    //             className={className.join(' ')}
    //             key={'score-value-' + index}
    //             onClick={() => {setScoreValue(index+1);}}
    //         />
    //         : <Star
    //             className={className.join(' ')}
    //             key={'score-value-' + index}
    //             onClick={() => {setScoreValue(index+1);}}
    //         />;
    // });
    //
    // const reviewsData = reviewsList.map((item, index) => <ReviewElementBlock key={'review-' + index} info={item}/>);
    //
    // function goToReviews(){
    //     reviewRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    //
    // const createReview = () => {
    //     if (status === NetworkStatus.idle) {
    //         let breakSubmit = false;
    //         setStatus(NetworkStatus.loading);
    //         if (score < 1 || score > 5){
    //             setScoreError(text[lang].review.error.score_error);
    //             setStatus(NetworkStatus.idle);
    //             breakSubmit = true;
    //         }
    //         if(title.length < 5 || title.length > 255){
    //             setTitleError(text[lang].review.error.title_error);
    //             setStatus(NetworkStatus.idle);
    //             breakSubmit = true;
    //         }
    //         if(review.length < 5 || title.length > 4000){
    //             setReviewError(text[lang].review.error.text_error);
    //             setStatus(NetworkStatus.idle);
    //             breakSubmit = true;
    //         }
    //
    //         if(true === breakSubmit){
    //             return null;
    //         }
    //         const sendData = {
    //             title: title,
    //             text: review,
    //             itemId: data.itemId,
    //             score: score,
    //         };
    //         MakeRequest(getUrl(apiRoutes.itemReviewAdd, server),
    //             'POST',
    //             sendData,
    //             token,
    //             (response) => {
    //                 reviewRef.current.scrollIntoView({ behavior: 'smooth' });
    //                 console.info('create review request', response.data.data);
    //                 setPlaced(1);
    //                 setStatus(NetworkStatus.idle);
    //                 setTotal(total + 1);
    //                 reviewsList.unshift(response.data.data);
    //                 setReviewsList(reviewsList);
    //             },
    //             (e) => {
    //                 console.error('req error', e);
    //                 setStatus(NetworkStatus.fail);
    //             });
    //     }
    // }
    //
    // const titleInputClass = titleError === null ? ['form-control', 'form-control-sm'] : ['form-control', 'form-control-sm', 'is-invalid'];
    // const textInputClass = titleError === null ? ['form-control', 'form-control-sm'] : ['form-control', 'form-control-sm', 'is-invalid'];
    //
    // return <div>qqq</div>;
    //
    // return <section className="pt-9 pb-11" id="reviews" ref={reviewRef}>
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-12">
    //                 <h4 className="mb-10 text-center">{text[lang].review.title}</h4>
    //                 {
    //                     data?.reviews?.list?.length ?? 0 < 1
    //                     ? <div className="text-center">
    //                             <div className="mb-6 fs-1">{text[lang].review.smile}</div>
    //                             <p>{text[lang].review.text}</p>
    //                             <a className="btn btn-sm btn-dark" data-bs-toggle="collapse" href="#reviewForm">
    //                                 {text[lang].review.title_button}
    //                             </a>
    //                         </div>
    //                     : <div className="text-center">
    //                             { placed !== null || data.reviews.yourScore !== null
    //                                 ? <div className='card'>
    //                                     <div className='card-body border text-center'>
    //                                         <h3>{text[lang].review.already_placed}</h3>
    //                                         <StarRatingDisplay size={40} score={data.reviews.yourScore ?? score} />
    //                                     </div>
    //                                 </div>
    //                                 : <a
    //                                         className="btn btn-sm btn-dark"
    //                                         data-bs-toggle="collapse"
    //                                         href="#reviewForm"
    //                                         onClick={() => {
    //                                             formRef.current.scrollIntoView({ behavior: 'smooth' });
    //                                         }}
    //                                     >{text[lang].review.title_button}</a>}
    //                         <br/>
    //                         <br/>
    //                         <div className={'row'}>{reviews}</div>
    //                         <div className={'row'}>
    //                             <button
    //                                 className="btn btn-sm btn-outline-primary"
    //                                 type='button'
    //                                 onClick={() => {
    //                                     data.loadMoreCallback()
    //                                 }}
    //                             >{text[lang].review.load_more}</button>
    //                         </div>
    //                     </div>
    //                     }
    //                 {null === placed
    //                     ? <div className="collapse" id="reviewForm">
    //                         <hr className="my-8"/>
    //                         <form ref={formRef}>
    //                             <div className="row">
    //                                 <div className="col-12 mb-6 text-center">
    //                                     <p className="mb-1 fs-xs">{text[lang].review.score}:</p>
    //                                     <div className="rating-form">
    //                                         <div className="rating h5 text-dark">
    //                                             {displayScore}
    //                                         </div>
    //                                     </div>
    //                                     {scoreError === null ? null : <div className='text-danger text-size-error'>{scoreError}</div>}
    //                                 </div>
    //                                 <div className="col-12">
    //                                     <div className="form-group">
    //                                         <label className="visually-hidden" htmlFor="reviewTitle">{text[lang].review.input_title_placeholder}:</label>
    //                                         {titleError === null ? null : <div className='text-danger text-size-error'>{titleError}</div>}
    //                                         <input
    //                                             className={titleInputClass.join(' ')}
    //                                             id="reviewTitle"
    //                                             type="text"
    //                                             value={title}
    //                                             onChange={(e) => {setTitle(e.target.value)}}
    //                                             onFocus={() => {setTitleError(null)}}
    //                                             placeholder={text[lang].review.input_title_placeholder + " *"}
    //                                             required
    //                                             disabled={status === NetworkStatus.loading}
    //                                         />
    //                                     </div>
    //                                 </div>
    //                                 <div className="col-12">
    //                                     <div className="form-group">
    //                                         <label className="visually-hidden"
    //                                                htmlFor="reviewText">{text[lang].review.input_text_placeholder}:</label>
    //                                         {reviewError === null ? null : <div className='text-danger text-size-error'>{reviewError}</div>}
    //                                         <textarea
    //                                             className={textInputClass.join(' ')}
    //                                             id="reviewText"
    //                                             rows="5"
    //                                             placeholder={text[lang].review.input_text_placeholder + " *"}
    //                                             required
    //                                             value={review}
    //                                             disabled={status === NetworkStatus.loading}
    //                                             onChange={(e) => {setReview(e.target.value)}}
    //                                             onFocus={() => {setReviewError(null)}}
    //                                         ></textarea>
    //                                     </div>
    //
    //                                 </div>
    //                                 <div className="col-12 text-center">
    //                                     <button
    //                                         className="btn btn-outline-dark"
    //                                         type="button"
    //                                         onClick={() => { createReview(); }}
    //                                         disabled={status === NetworkStatus.loading}
    //                                     >
    //                                         {text[lang].review.title_submit_button}
    //
    //                                         {status === NetworkStatus.loading ? <div className="spinner-border fs-6 text-primary" role="status">
    //                                             <span className="visually-hidden fs-6">Loading...</span>
    //                                         </div> : null}
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </form>
    //                     </div>
    //                     : null}
    //             </div>
    //         </div>
    //     </div>
    // </section>;
}

export default ReviewBlock;