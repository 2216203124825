import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.scss';
import {RouterProvider} from "react-router-dom";
import {router} from "./routes/createRouter"
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
//import './scss/theme.css';
import Init from "./components/Init";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById('root');
const root = createRoot(container);

if(process.env.REACT_APP_SENTRY_ENABLED === 'true'){
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
        replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
        replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    });
}

root.render(
  // <React.StrictMode>
    <Provider store={store}>
        <Init />
        <RouterProvider router={router} />
    </Provider>
  // {/*</React.StrictMode>*/}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
