import AccountMenu from "../../parts/account/AccountMenu";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import Footer from "../../parts/Footer";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getInitServer, getInitUser, getLang, getServer} from "../../../redusers/InterfaceReducer";
import {getUserToken} from "../../../redusers/UserReducer";
import {getOrderDetail, loadOrder} from "../../../redusers/OrderReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import FullPageLoader from "../../parts/FulPageLoader";
import makeGetParams from "../../../helpers/network/MakeGetParams";
import {Link, useLocation} from "react-router-dom";
import OrderTitleBlock from "../../parts/account/orders/OrderTitleBlock";
import Money from "../../parts/money/Money";
import img from "../../../assets/img/products/product-6.jpg";
import {text} from "../../../config/i18n/text";

export const PersonalOrderPage = () => {

    const dispatch = useAppDispatch();

    let location = useLocation();
    const orderId = location.pathname.replace('/account/order/','');

    const lang = useAppSelector(getLang);
    const server = useAppSelector(getServer);
    const token = useItemListSelector(getUserToken);

    const initServer = useAppSelector(getInitServer);
    const initUser = useAppSelector(getInitUser);
    const order = useAppSelector(getOrderDetail);

    const [state, setState] = useState(NetworkStatus.loading);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [state]);

    useEffect(() => {
        if (true === initServer && true === initUser) {

            MakeRequest(getUrl(apiRoutes.ordersDetail, server) + makeGetParams({id: orderId}), 'get', {}, token, (response) => {
                console.info('orders - data from server', response.data);
                dispatch(loadOrder(response.data));
                setState(NetworkStatus.idle);

            }, (e) => {
                if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                    setState(NetworkStatus.idle);
                } else {
                    console.error('orders - response error is', e);
                    setState(NetworkStatus.fail);
                }
            });
        }

    }, [initServer, initUser]);

    if(state === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Header />
            <FullPageLoader />
            <Footer />
        </>;
    }

    const items = order.items.map((item, index) => {
        const price = item.count > 1
            ? <><Money amount={item.item.cost} /> <span className='text-warning'>* {item.count}</span> = <Money amount={item.total}/></>
            : <Money amount={item.item.cost}/>;
        return <li className="list-group-item" key={'order-item' + index}>
            <div className="row align-items-center">
                <div className="col-4 col-md-3 col-xl-2">
                    <Link to={'/'}>
                        <img src={img} alt="..." className="img-fluid"/>
                    </Link>
                </div>
                <div className="col">
                    <p className="mb-4 fs-sm fw-bold">
                        <Link className="text-body" to={'/'}>{item.item.title}</Link> <br/>
                        <span className="text-muted">{price}</span>
                    </p>
                    {/*<div className="fs-sm text-muted">*/}
                    {/*    Size: M <br />*/}
                    {/*    Color: Red*/}
                    {/*</div>*/}
                </div>
            </div>
        </li>;
    });

    return <>
        <Helmet>
            <title>{text[lang].seo.account.order.title}</title>
            <meta name="description" content={text[lang].seo.account.order.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header/>
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.order_detail.title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={0} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        <div className="card card-lg mb-5 border">
                            <OrderTitleBlock
                                displayId={order.displayId}
                                id={order.id}
                                date={order?.updatedAt}
                                status={order.status}
                                amount={order.total}
                            />
                            <div className="card-footer">
                                <h6 className="mb-7">{text[lang].account.order_detail.item_list_title} ({order.items.length})</h6>
                                <hr className="my-5" />
                                    <ul className="list-group list-group-lg list-group-flush-y list-group-flush-x">
                                        {items}
                                    </ul>
                            </div>
                        </div>
                        <div className="card card-lg mb-5 border">
                            <div className="card-body">
                                <h6 className="mb-7">{text[lang].account.order_detail.item_payment_title}</h6>
                                <ul className="list-group list-group-sm list-group-flush-y list-group-flush-x">
                                    <li className="list-group-item d-flex">
                                        <span>{text[lang].account.order_detail.order_subtotal}</span>
                                        <span className="ms-auto"><Money amount={order.subTotal} /></span>
                                    </li>
                                    <li className="list-group-item d-flex">
                                        <span>{text[lang].account.order_detail.order_discount}</span>
                                        <span className="ms-auto"><Money amount={order.discount} /></span>
                                    </li>
                                    <li className="list-group-item d-flex">
                                        <span>{text[lang].account.order_detail.order_shipping}</span>
                                        <span className="ms-auto"><Money amount={order.shippingCost} /></span>
                                    </li>
                                    <li className="list-group-item d-flex fs-lg fw-bold">
                                        <span>{text[lang].account.order_detail.order_total}</span>
                                        <span className="ms-auto"><Money amount={order.total} /></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-lg border">
                            <div className="card-body">
                                <h6 className="mb-7">{text[lang].account.order_detail.order_info_title}</h6>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <p className="mb-4 fw-bold">
                                            {text[lang].account.order_detail.order_billing_address}:
                                        </p>
                                        <p className="mb-7 mb-md-0 text-gray-500">
                                            {order.billingCompany}, <br />
                                            {order.firstName} {order.lastName}, <br />
                                            {order.billingAddress1}, {order.billingAddress2}<br />
                                            {order.billingZip}, {order.billingCity}, <br />
                                            {order.billingCountry}, <br />
                                            {order.phone}
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <p className="mb-4 fw-bold">
                                            {text[lang].account.order_detail.order_shipping_address}:
                                        </p>
                                        <p className="mb-7 mb-md-0 text-gray-500">
                                            {order.shippingCompany}, <br />
                                            {order.firstName} {order.lastName}, <br />
                                            {order.shippingAddress1}, {order.shippingAddress2}<br />
                                            {order.shippingZip}, {order.shippingCity}, <br />
                                            {order.shippingCountry}, <br />
                                            {order.phone}
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <p className="mb-4 fw-bold">
                                            {text[lang].account.order_detail.order_shipping_method}:
                                        </p>
                                        <p className="mb-7 text-gray-500">
                                            {order.delivery}
                                        </p>
                                        <p className="mb-4 fw-bold">
                                            {text[lang].account.order_detail.order_payment_method}:
                                        </p>
                                        <p className="mb-0 text-gray-500">
                                            {order.payment}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>;
}

export default PersonalOrderPage;