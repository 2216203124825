import {
    getCategories, getInitServer,
    getServer,
    setAnon,
    setAuth,
    setCategories, setInitUser, setLang,
    setServer,
    statusIdle
} from "../redusers/InterfaceReducer";
import {AuthUser} from "../redusers/UserReducer";
import {useEffect} from "react";
import MakeRequest from "../helpers/network/MakeRequest";
import {authKeyName, defaultServer, getUrl} from "../config/config";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {loadCart} from "../redusers/CartReducer";
import {menu} from "../routes/routes";

export function Init(){
    const dispatch = useAppDispatch();
    const categories = useAppSelector(getCategories);
    const server = useAppSelector(getServer);
    const initServer = useAppSelector(getInitServer);

    //Get default server info
    useEffect(() => {

        const localServer = localStorage.getItem(authKeyName + 'server');
        const savedLang = localStorage.getItem(authKeyName + 'lang');

        if(null === localServer){
            localStorage.setItem(authKeyName + 'server', defaultServer);
            dispatch(setServer(defaultServer));
        } else {
            dispatch(setServer(localServer));
        }

        if (null !== savedLang) {
            dispatch(setLang(savedLang));
        }

    }, []);
    //Get default server info
    useEffect(() => {

        const cartStorageRawData = localStorage.getItem(authKeyName + 'cart');

        if(null !== cartStorageRawData){
            const cartData = JSON.parse(cartStorageRawData);
            dispatch(loadCart(cartData));
        }

    }, []);

    // Get info about auth
    useEffect(() => {

        if (true === initServer) {
            try {
                const serializedState = localStorage.getItem(authKeyName + 'user');

                if(serializedState === null){
                    dispatch(setAnon());
                    dispatch(statusIdle())
                    dispatch(setInitUser(true));
                } else {
                    const userData = JSON.parse(serializedState);
                    dispatch(setAuth());
                    dispatch(AuthUser(userData));
                    dispatch(statusIdle());
                    dispatch(setInitUser(true));
                }

                if(categories.length === 0){
                    MakeRequest(
                        getUrl(menu, server),
                'get',
                        {},
                    '',
                (response) => {
                            dispatch(setCategories(response.data.data.menu.categories));
                        },
                    (data) => {
                            console.error('error fetching categories: ', data);
                        }
                    );
                }
            } catch (err) {
                dispatch(setAnon());
                dispatch(statusIdle())
            }
        }
    }, [initServer]);

    return null;
}

export default  Init;