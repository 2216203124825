import {Link} from "react-router-dom";
import {useAppSelector} from "../../../app/hooks";
import {getLang} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {accountOrders, makeAccountOrder} from "../../../routes/routes";
import {getOrderInfo} from "../../../redusers/CheckoutReducer";

export const CheckoutPlacedBlock = () => {

    const lang = useAppSelector(getLang);
    const order = useAppSelector(getOrderInfo);
    const orderId = order.orderId;

    return <section className="py-12">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <div className="mb-7 fs-1">{text[lang].checkout.completed.emoji}</div>
                    <h2 className="mb-5">{text[lang].checkout.completed.title}</h2>
                    <p className="mb-7 text-gray-500">
                        {text[lang].checkout.completed.text1} <Link
                        to={makeAccountOrder(orderId)}
                        className=" text-decoration-underline text-primary"
                    >{orderId}</Link> {text[lang].checkout.completed.text2}
                    </p>
                    <Link className="btn btn-dark" to={accountOrders}>
                        {text[lang].checkout.completed.button_title}
                    </Link>
                </div>
            </div>
        </div>
    </section>;
}

export default CheckoutPlacedBlock;