import {createSlice} from '@reduxjs/toolkit';
import {NetworkStatus} from "../helpers/network/NetworkStatus";


const initialState = {
    state: NetworkStatus.loading,
    data: {},
    isArticleExist: false,
    tick: 1,
};

export const userSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        setArticle: (state, payload) => {
            state.data = payload.payload;
            state.state = NetworkStatus.idle;
            state.isArticleExist = true;
        },
        setArticleStatus: (state, payload) => {
            state.state = payload.payload;
        },
        setArticleExist: (state, payload) => {
            state.isArticleExist = payload.payload;
        },
        updateTick: (state) => {
            state.tick++;
        },
        setArticleNotExist: (state) => {
            state.isArticleExist = false;
            state.state = NetworkStatus.fail;
            state.data = {};
        },
    },
});

export const {
    setArticle,
    setArticleStatus,
    setArticleExist,
    updateTick,
    setArticleNotExist,
} = userSlice.actions;

export const getArticleStatus = (state) => state.article.state;
export const isArticleExist = (state) => state.article.isArticleExist;
export const getArticle = (state) => state.article.data;
export const getTick = (state) => state.article.tick;
export default userSlice.reducer;