import { createSlice} from '@reduxjs/toolkit';
import {NetworkStatus} from "../helpers/network/NetworkStatus";

const initialState = {
    categoryStatus: NetworkStatus.loading,
    favoriteListStatus: NetworkStatus.loading,
    itemsListStatus: NetworkStatus.loading,
    categoryId: null,
    categoryTitle: null,
    filters: {},
    itemList: [],
    updateIndex: 0,
    sortBy: 'default',
    currentPage: 1,
    displayFiltersMobile: false,
    costFilter: {
        min: 0,
        max: 10000
    }
};

export const itemsListSlice = createSlice({
    name: 'ItemList',
    initialState,
    reducers: {
        LoadFilters: (state) => {
            state.categoryStatus = NetworkStatus.idle;

        },
        addFilters: (state, payload) => {
            state.filters = payload.payload;
        },
        changeFilterListValue: (state, payload) => {
            state.filters[payload.payload.filterId].values[payload.payload.valueId].value =
                !state.filters[payload.payload.filterId].values[payload.payload.valueId].value;
        },
        updateCostFilter: (state, payload) => {
            state.costFilter = {
                min: payload.payload.min ?? 0,
                max: payload.payload.max ?? 10000,
            }
        },
        updateMinCostFilter: (state, action) => {
            state.costFilter = {
                min: action.payload,
                max: state.costFilter.max,
            }
        },
        updateMaxCostFilter: (state, action) => {
            state.costFilter = {
                min: state.costFilter.min,
                max: action.payload,
            }
        },
        updateFilterUpdateIndex: (state) => {
            ++state.updateIndex;
        },
        changeListItemStatus: (state, payload) => {
            state.itemsListStatus = payload.payload;
            state.currentPage = 1;
        },
        setSortBy: (state, payload) => {
            state.sortBy = payload.payload;
        },
        setCurrentPage: (state, payload) => {
            state.currentPage = payload.payload;
        },
        updateItemListStatus: (state, payload) => {
            state.itemsListStatus = payload.payload;
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload;
        },
        setCategoryTitle: (state, action) => {
            state.categoryTitle = action.payload;
        },
        setItemList: (state, action) => {
            state.itemList = action.payload;
        },
        updateWishListState: (state, action) => {
            for(let i=0; i<=state.itemList.length -1; i++){
                if(state.itemList[i].id === action.payload.id){
                    state.itemList[i].isLiked = action.payload.isLiked;
                }
            }
        },
        clearFilters: (state) => {
            const f = state.filters;
            for (let i in f) {
                for(let j in f[i].values) {
                    f[i].values[j].value = false;
                }
            }
            state.filters = f;
            state.costFilter = initialState.costFilter;
        },
        toggleMobileFilterList: (state) => {
            state.displayFiltersMobile = !state.displayFiltersMobile;
        },
        // AuthUser:(state, payload) => {
        //     state.userId = payload.payload.userId;
        //     state.userToken = payload.payload.userToken;
        //     state.userName = payload.payload.userName;
        // },
    },
});

export const {
    LoadFilters,
    addFilters,
    updateCostFilter,
    changeFilterListValue,
    setSortBy,
    changeListItemStatus,
    updateFilterUpdateIndex,
    setCurrentPage,
    updateItemListStatus,
    updateMinCostFilter,
    updateMaxCostFilter,
    setCategoryId,
    setCategoryTitle,
    setItemList,
    updateWishListState,
    clearFilters,
    toggleMobileFilterList,
} = itemsListSlice.actions;

export const getCategoryStatus = (state) => state.itemList.categoryStatus;
export const getCost = (state) => state.itemList.costFilter;
export const getFilters = (state) => state.itemList.filters;
export const getFilterUpdateIndex = (state) => state.itemList.updateIndex;
export const getFavoriteListStatus = (state) => state.favoriteListStatus;
export const getItemsListStatus = (state) => state.itemList.itemsListStatus;

export const getSortList = (state) => state.itemList.sortByVariants;

export const getCurrentSort = (state) => state.itemList.sortBy;
export const getCurrentPage = (state) => state.itemList.currentPage;
export const getCurrentCategory = (state) => state.itemList.categoryId;
export const getCurrentCategoryTitle = (state) => state.itemList.categoryTitle;
export const getItemList = (state) => state.itemList.itemList;
export const getDisplayFiltersMobile = (state) => state.itemList.displayFiltersMobile;

export default itemsListSlice.reducer;