import { createSlice} from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    userToken: '',
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '+',
    sex: null,
    birth: null,
    emailConfirmed: false,
    phoneConfirmed: false,
    wishListSize: 0,
    wishList: [],
    wishListPage: 1,
    orders: [],
    addresses: [],

};

export const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        AuthUser:(state, payload) => {
            state.userId = payload.payload.userId;
            state.userToken = payload.payload.userToken;
            state.userName = payload.payload.userName;
            state.firstName = payload.payload.firstName;
            state.lastName = payload.payload.lastName;
            state.email = payload.payload.email;
            state.sex = payload.payload.sex;
            state.birth = payload.payload.birth;
            state.phone = payload.payload.phone;
        },
        setWishListSize: (state, payload) => {
            state.wishListSize = payload.payload;
        },
        setWishList: (state, payload) => {
            state.wishList = payload.payload;
            state.wishListSize = payload.payload.length;
        },
        setWishListPage: (state, payload) => {
            state.wishListPage = payload.payload;
        },
        removeFromWishList: (state, payload) => {
            let wishList = [];
            for(let i in state.wishList){
                if(state.wishList[i].id !== payload.payload){
                    wishList.push(state.wishList[i]);
                }
            }
            state.wishList = wishList;
            state.wishListSize = state.wishList.length;
        },
    },
});

export const {
    AuthUser,
    setWishListSize,
    setWishList,
    setWishListPage,
    removeFromWishList,
} = userSlice.actions;

export const getUserData = (state) => state.user;
export const getUserToken = (state) => state.user.userToken;
export const getWishListSize = (state) => state.user.wishListSize;
export const getWishList = (state) => state.user.wishList;
export const getWishListPage = (state) => state.user.wishListPage;

export const getUserProfileInfo = (state) => { return {
    id: state.user.userId,
    userName: state.user.userName,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
    phone: state.user.phone,
    emailConfirmed: state.user.emailConfirmed,
    phoneConfirmed: state.user.phoneConfirmed,
    birth: state.user.birth,
    sex: state.user.sex,
}};

export default userSlice.reducer;