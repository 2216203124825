import React from "react";

export const ItemsList = (data) => {

    return <>
        <div className="row">
            {data.items}
        </div>
    </>;
}

export default ItemsList;