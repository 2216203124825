import Header from "../parts/Header";
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import {Check2Circle, ChevronRight, Circle, PlusCircle} from "react-bootstrap-icons";
import {Alert, ListGroup, Modal, Spinner} from "react-bootstrap";
import {useAppSelector} from "../../app/hooks";
import {getCategories, getServer} from "../../redusers/InterfaceReducer";
import {sell} from "../../routes/routes";
import axios from "axios";
import {getUrl} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {getUserToken} from "../../redusers/UserReducer";
import FilterListInput from "../parts/filtersInputs/FilterListInput";
import FilterColorListInput from "../parts/filtersInputs/FilterColorListInput";
import MakeRequest from "../../helpers/network/MakeRequest";
import ImagePreview from "../parts/imageUpload/ImagePreview";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import MDEditor from '@uiw/react-md-editor';
import {useNavigate} from "react-router-dom";
import * as routers from "../../routes/routes";
export const SellPage = () => {

    const [categoryStack, setCategoryStack] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [filters, setFilters] = useState([]);
    const [images, setImages] = useState([]);

    const [filtersValue, setFiltersValue] = useState({});

    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(null);

    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(null);

    const [price, setPrice] = useState(0.00);
    const [priceError, setPriceError] = useState(null);

    const [readySubmit, setReadySubmit] = useState(false);

    const fieldLength = {
        title: 10,
        description: 1000,
        price: {
            min: 0.01,
            max: 100000,
        }
    }

    function updateTitle(e) {
        if(e.target.value.length > fieldLength.title) {
            setTitleError('Title to long');
            updateSubmitState(false);
        } else {
            setTitle(e.target.value);
            if (titleError !== null) {
                setTitleError(null);
            }
            checkUpdateSubmitState();
        }
    }
    function updateDescription(e) {
        if(e.length > fieldLength.description) {
            setDescriptionError('Description to long');
            updateSubmitState(false);
        } else {
            setDescription(e);
            if (descriptionError !== null) {
                setDescriptionError(null);
            }
            checkUpdateSubmitState();
        }

    }
    function updatePrice(e) {
        const price = parseFloat(e.target.value);
        if(price >= fieldLength.price.min && price <= fieldLength.price.max){
            setPrice(price.toString());
            checkUpdateSubmitState();
        } else {
            setPriceError('Price must be between [' + fieldLength.price.min + ', '+fieldLength.price.max+']');
        }

    }

    const networkStatuses = {
        waiting: 'waiting',
        loading: 'loading',
        error: 'error',
        completed: 'completed',
    };

    const [networkStatus, setNetworkStatus] = useState(networkStatuses.waiting);

    const [imagesUploadProgress, setImagesUploadProgress] = useState(networkStatuses.completed);

    const br = currentCategory.map((item, index) => {
        return <li key={'br1-item-' + index + '-lvl-' + categoryStack.length} className={['breadcrumb-item', index !== currentCategory.length - 1 && 'text-primary active'].join(' ')} aria-current="page">{item.title}</li>
    });

    const br2 = categoryStack.map((item, index) => {
        return <li
            key={'br2-item-' + index + '-lvl-' + categoryStack.length}
            className={['breadcrumb-item c-pointer', index !== categoryStack.length - 1 && 'text-primary active'].join(' ')}
            onClick={()=> {
                sliceCategoryStack(index);
            }}
            aria-current="page"
        >{item.title} ({index})</li>
    });

    function selectCategory(item){
        let result = cloneArray(categoryStack);
        result.push(item);
        setSelectedCategory(result);
        setSelectedCategoryId(item.id);
    }

    function saveCategory(){
        setCurrentCategory(cloneArray(selectedCategory));
        setShowCategoryModal(false);
    }

    function goSubCategory(item, canChange) {
        if(false === canChange){
            return;
        }
        let result = [];
        for(let i in categoryStack){
            result.push(categoryStack[i]);
        }
        result.push(item);
        setCategoryStack(result);
    }

    function sliceCategoryStack(count) {
        let result = [];
        for(let i = 0; i < count; i++){
            result.push(categoryStack[i]);
        }
        setCategoryStack(result);
    }

    function cloneArray(array) {
        let result = [];
        for(let i in array){
            result.push(array[i]);
        }
        return result;
    }

    function cloneObject(inputObject) {
        let result = {}
        Object.keys(inputObject).forEach(function(key) {
            result[key] = inputObject[key];
        });

        return result;
    }

    function getCategoryLevel(localCategories, stack) {
        const localStack = cloneArray(stack);
        if(localStack.length < 1){
            return localCategories;
        }
        const stackCategory = localStack.shift();
        for(let i in localCategories){
            if(localCategories[i].id === stackCategory.id){
                return getCategoryLevel(localCategories[i].child, localStack);
            }
        }
    }


    const categories = useAppSelector(getCategories);

    const sliceCategories = (categoryStack.length < 1 ? categories : getCategoryLevel(categories, categoryStack)) ?? [];
    //const sliceCategories = categoryStack.length < 1 ? categories : categories[0].child;

    const displayCategory = (true === showCategoryModal) ? sliceCategories.map((item, index) => {
        const childExist = item.child.length > 0;
        return <ListGroup.Item className='c-pointer' key={'list-item-' + index}>{
            selectedCategoryId === item.id
                ? <Check2Circle className='text-primary' size={16}/>
                : <Circle color={'#ccc'} size={16} onClick={() => {selectCategory(item, index); }} />
        } <span className={['p-1', childExist === true ? 'text-primary' : 'text-grey'].join(' ')} onClick={() => {
            goSubCategory(item, childExist === true)
        }}>{item.title}</span> {childExist === true ? <ChevronRight className='text-primary' size={12}/> : null}</ListGroup.Item>;
    }) : null;

    const token = useAppSelector(getUserToken);
    const server = useAppSelector(getServer);

    useEffect(() => {
        if(currentCategory.length > 0){
            setNetworkStatus(networkStatuses.loading);
            axios.get(
                getUrl(apiRoutes.category_info, server) + '?slug=' + currentCategory[currentCategory.length - 1].slug, {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then(async function (response) {
                    let filVals = {};
                    for(let i in response.data.data.filters){
                        filVals[response.data.data.filters[i].id] = null;
                    }
                    setFilters(response.data.data.filters);
                    setFiltersValue(filVals);
                    setNetworkStatus(networkStatuses.completed);
                })
                .catch(function (error) {
                    setNetworkStatus(networkStatuses.error);
                    console.error(error);
                });
        }
    }, [currentCategory]);

    useEffect(() => {
        console.error('FiltersValue', filtersValue);
    },[filtersValue]);

    const filtersBlocks = filters.map((item, index) => {
        switch (item.type){
            case 'LIST':
                return <FilterListInput
                    key={'filter-list-' + index}
                    item={item}
                    onChange={(e) => {
                        saveFilterValue(item.id, e.value);
                    }}
                />;
            case 'COLOR_LIST':
                return <FilterColorListInput
                    key={'filter-color-list-' + index}
                    item={item}
                    onChange={(e) => {
                        saveFilterValue(item.id, e);
                    }}
                />;
            default:
                return <div key={'filter-unknown-' + index}>{item.title} ({item.type})</div>;
        }
    });

    function uploadImage(e) {
        setImagesUploadProgress(networkStatuses.loading);
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("context", 'image');
        MakeRequest(getUrl(apiRoutes.imageUpload, server), 'POST',formData, token, (response) => {

            let newImages = cloneArray(images);
            newImages.push({
                id: response.data.data.id,
                status: NetworkStatus.loading,
                url: {
                    big: null,
                    small: null,
                }
            });
            setImages(newImages);

            setImagesUploadProgress(networkStatuses.completed);
        }, (e) => {
            console.error('response error is', e);
            setImagesUploadProgress(networkStatuses.completed);
        }, {
            'Content-Type': 'multipart/form-data'
        });
    }

    function removeImage(imageId) {
        let newImages = cloneArray(images);
        let changed = false;
        for (let i in newImages) {
            if (newImages[i].id === imageId) {
                changed = true;
                newImages.splice(i, 1);
            }
        }

        if (true === changed) {
            setImages(newImages);
        }
    }

    const imageList = images.map((item, index) => {
        return <ImagePreview key={'image-prv-' + index} image={item} removeImage={() => {
            removeImage(images[index].id);
        } } />
    });

    function updateSubmitState(state) {
        if (state !== readySubmit) {
            setReadySubmit(state);
        }
    }
    function checkUpdateSubmitState() {
        let canSubmit = true;
        // тут делаем проверки
        if (title.length < 1 || null !== titleError ) {
            canSubmit = false;
        }

        if (description.length < 1 || null !== descriptionError ) {
            canSubmit = false;
        }


        if (readySubmit !== canSubmit) {
            setReadySubmit(canSubmit);
        }

        return canSubmit;
    }

    useEffect(() => {
        let newImages = cloneArray(images);
        for (let i in newImages) {
            if (newImages[i].status === NetworkStatus.loading) {
                MakeRequest(
                    getUrl(apiRoutes.imageResolve, server) + '?id=' + newImages[i].id,
                    'GET',
                    {},
                    token,
                    (response) => {
                        for(let j in newImages){
                            if(newImages[j].id === newImages[i].id){
                                newImages[j] = {
                                    id: response.data.data.id,
                                    status: NetworkStatus.idle,
                                    url: {
                                        big: response.data.data.big,
                                        small: response.data.data.small,
                                    }
                                }
                            }
                        }
                        setImages(newImages);
                    }, (e) => {
                        console.error('fail to resolve image', newImages[i], e);

                        for(let j in newImages){
                            if(newImages[j].id === newImages[i].id){
                                newImages[j] = {
                                    id: newImages[i].id,
                                    status: NetworkStatus.fail,
                                    url: {
                                        big: null,
                                        small: null,
                                    }
                                }
                            }
                        }

                        setImages(newImages);
                    });
            }
        }
    }, [images]);

    function saveFilterValue(filterID, valueID){
        let values = cloneObject(filtersValue);
        values[filterID] = [];
        if(Array.isArray(valueID)){
            for(let i in valueID){
                values[filterID].push(valueID[i].value);
            }

            values[filterID] = values[filterID].filter(function(item, i, ar){ return ar.indexOf(item) === i; });
        } else {
            values[filterID].push(valueID);
            values[filterID] = values[filterID].filter(function(item, i, ar){ return ar.indexOf(item) === i; });
        }
        setFiltersValue(values);
    }

    function uploadItem(){
        if(true === checkUpdateSubmitState()) {

            let data = new FormData();
            data.append('title', title);
            data.append('description', description);
            data.append('price', price);

            images.forEach((item) => {
                data.append('images[]', item.id);
            });

            for(let i in filtersValue){
                data.append('filters['+i+']', filtersValue[i]);
            }
            currentCategory.forEach((item) => {
                data.append('category[]', item.id);
            });

            MakeRequest(getUrl(apiRoutes.itemCreate, server), 'POST',data, token, () => {
                navigate(routers.sellDone);

            }, (e) => {
                console.error('response error is', e);
            }, {
                'Content-Type': 'multipart/form-data'
            });
        }
    }


    return (
        <div className="happy-app">
            <Helmet>
                <title>Sell an item</title>
                <meta name="description" content="Happy Wardrobe" />
            </Helmet>
            <Header/>
            <div className="Happy-app-body">
                <div className="container">
                    <form action="src/pages/LoginForm#">
                        <div className="row">
                            <div className='col-md-8 offset-2'>
                                <h1 className='m-4 mt-5'>Sell an item</h1>
                                <div className="photo-block block">
                                    Add up to 20 photos. <span className='text-primary c-pointer'>See photo tips</span>
                                    <ul className="photo-preview-block">
                                        {imageList}
                                        <label htmlFor="file_input" key={'button-upload'}>
                                            <input onChange={uploadImage} type="file" id="file_input" style={{display:"none"}} disabled={(imagesUploadProgress !== networkStatuses.waiting && imagesUploadProgress !== networkStatuses.completed)}/>
                                            <li className={['upload', (imagesUploadProgress !== networkStatuses.waiting && imagesUploadProgress !== networkStatuses.completed) && 'disabled'].join(' ')}>
                                                {imagesUploadProgress === networkStatuses.loading && <Spinner animation="border" variant="primary" />}
                                                { (imagesUploadProgress === networkStatuses.waiting || imagesUploadProgress === networkStatuses.completed) && <PlusCircle size={30} className='upload-icon' />}
                                            </li>
                                        </label>
                                    </ul>
                                </div>
                                <div className="block common-info-block mt-2">
                                    <div className="mb-3">
                                        <label htmlFor="title" className="form-label">Title {titleError !== null && <span className={'error-text text-danger'}>{titleError}</span>}</label>
                                        <input
                                            type="text"
                                            className={['form-control', titleError !== null && 'is-invalid'].join(' ')}
                                            id="title"
                                            placeholder="Write title here"
                                            value={title}
                                            onChange={(e) => {
                                              updateTitle(e);
                                            } }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="text" className="form-label">Describe {descriptionError !== null && <span className={'error-text text-danger'}>{descriptionError}</span>}</label>
                                        <MDEditor
                                            inputMode={'decimal'}
                                            minHeight={100}
                                            value={description}
                                            onChange={(e) => {
                                                updateDescription(e);
                                            }}
                                        />
                                        <MDEditor.Markdown source={description} style={{ whiteSpace: 'pre-wrap' }} />
                                    </div>
                                </div>
                                <div className="block common-info-block mt-2">
                                    <div className="row">
                                        <div className="col-md-2 text-start">Category:</div>
                                        <div className="col-md-10 text-start">
                                            <nav aria-label="breadcrumb text-start">
                                                <ol className="breadcrumb bg-transparent justify-content-start">
                                                    {br}

                                                    <div onClick={() => { setShowCategoryModal(true); }} className='hw-btn hw-btn-w120'>Change Category...</div>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>


                                </div>
                                {
                                    networkStatus !== networkStatuses.waiting
                                    && <div className="block common-info-block mt-2">
                                        { networkStatus === networkStatuses.loading && <div className='tl-center'><Spinner variant='primary' animation="border" size="sm" /></div> }
                                        { networkStatus === networkStatuses.error && <Alert variant='danger'>Error on loading filters! <a href={sell}>Try again...</a></Alert> }
                                        { networkStatus === networkStatuses.completed && filtersBlocks }
                                    </div>
                                }

                                <div className="block common-info-block mt-2">
                                    <div className="mb-3">
                                        <label htmlFor="cost" className="form-label">Cost {priceError !== null && <span className={'error-text text-danger'}>{priceError}</span>}</label>
                                        <input
                                            type="tel"
                                            className={['form-control', 'text-end', titleError !== null && 'is-invalid'].join(' ')}
                                            id="cost"
                                            placeholder="0.00"
                                            value={price}
                                            onChange={(e) => {
                                                updatePrice(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="block common-info-block mt-2">
                                    What do you think of our upload process
                                    <span className='hw-btn'>FeedBack</span>
                                </div>
                                <div className="text-end common-info-block mt-2">
                                    <button
                                        type='button'
                                        className={'btn'}
                                    >Save draft</button>
                                    &nbsp;
                                    <button
                                        type='button'
                                        // disabled={!readySubmit}
                                        className={'btn text-primary'}
                                        onClick={() => {
                                            uploadItem();
                                        }}
                                    >Upload</button>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <Modal
                            show={showCategoryModal}
                            onHide={() => { setShowCategoryModal(false); }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Select category</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ol className="breadcrumb bg-transparent justify-content-start">
                                    {br2}
                                </ol>
                                <ListGroup>
                                    {displayCategory}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className='hw-btn hw-btn-xl'  onClick={() => { setShowCategoryModal(false); }}>Cancel</div>
                                <div className={['hw-btn', 'hw-btn-xl', 'hw-btn-main', selectedCategoryId === null && 'disabled'].join(' ')}  onClick={() => { saveCategory(); }}>Set Category</div>
                            </Modal.Footer>
                        </Modal>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SellPage;