import React from "react";

export const StarRatingDisplay = (data) => {
    let score = [];
    const avgScore = parseInt(Math.round(data.score));
    let scoreClass = ['rating-item'];
    if(avgScore <= 2){
        scoreClass.push('text-danger');
    } else if(avgScore <= 3){
        scoreClass.push('text-warning');
    } else {
        scoreClass.push('text-success');
    }
    for(let i = 1; i <= 5; i++) {
        score.push(<div className={i <= avgScore ? scoreClass.join(' ') : 'rating-item'} key={'score-star-' + i}>
            <i className="fas fa-star" style={{fontSize: data.size ?? 12}}></i>
        </div>);
    }
    return <div className="rating fs-xs text-dark">
        {score}
    </div>;
}

export default StarRatingDisplay;