import Header from "../parts/Header";
import React, {useEffect, useState} from "react";
import Footer from "../parts/Footer";
import {Link, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../app/hooks";
import {getUserToken, setWishListSize} from "../../redusers/UserReducer";
import {getInitServer, getInitUser, getLang, getServer} from "../../redusers/InterfaceReducer";
import {makeCategory} from "../../routes/routes";
import MakeRequest from "../../helpers/network/MakeRequest";
import {getUrl, showFakeImages} from "../../config/config";
import {apiRoutes} from "../../config/apiRoutes";
import {NetworkStatus} from "../../helpers/network/NetworkStatus";
import Money from "../parts/money/Money";

import img1 from "./../../assets/img/products/product-6.jpg";
import img2 from "./../../assets/img/products/product-7.jpg";
import img3 from "./../../assets/img/products/product-8.jpg";
import img from "./../../assets/db-placeholder.png";
import InfoBlock from "../parts/block/InfoBlock";
import {text} from "../../config/i18n/text";
import SuggestItemList from "../parts/block/SuggestItemList";
import ReviewBlock from "../parts/block/ReviewBlock";
import ItemGroups from "../parts/block/ItemGroups";
import StarRatingDisplay from "../parts/StarRatingDisplay";
import {
    getProductAvgScore,
    getProductInfo,
    getProductPageStatus,
    getProductReviewsTotal, setIsLiked,
    setProduct,
    setStatus
} from "../../redusers/ProductReducer";
import FullPageLoader from "../parts/FulPageLoader";
import {addToCart} from "../../redusers/CartReducer";
import {Helmet} from "react-helmet";
import { ReactComponent as Heart } from "./../../assets/dekbed/heart.svg";
import GroupsBlock from "../parts/block/GroupsBlock";
import * as routes from "../../routes/routes";

export const ProductPage = () => {
    const token = useItemListSelector(getUserToken);

    const dispatch = useAppDispatch();

    let location = useLocation();
    const itemId = location.pathname.replace('/item/','');

    const server = useAppSelector(getServer);

    const lang = useAppSelector(getLang);

    const status = useAppSelector(getProductPageStatus);

    const item = useAppSelector(getProductInfo);

    const reviewsTotal = useAppSelector(getProductReviewsTotal);
    const avgScore = useAppSelector(getProductAvgScore);

    const [wishState, setWishState] = useState(NetworkStatus.idle);


    const breadcrumb = item?.categories?.map((item, index) => {
        return <li className="breadcrumb-item" key={'breadcrumb-item-' + index}><Link className="text-gray-400" to={makeCategory(item.slug)}>
            {item.titles[lang] ?? item.titles['en'] ?? item.title}
        </Link></li>;
    });

    const initServer = useItemListSelector(getInitServer);
    const initUser = useItemListSelector(getInitUser);

    useEffect(() => {
        dispatch(setStatus(NetworkStatus.loading));
        if(true === initUser && true === initServer){
            MakeRequest(
                getUrl(apiRoutes.itemsInfo, server) + '?item='+itemId + '&lang=' + lang.toUpperCase(),
                'GET',
                {item: itemId},
                token,
                (response) => {
                    if (response.data.status !== false) {
                        //dispatch(setStatus(NetworkStatus.idle));
                        dispatch(setProduct({
                            'reviews' : response.data.data.reviews,
                            'info' : response.data.data,
                        }))
                    } else {
                        dispatch(setStatus(NetworkStatus.fail));
                    }
                },
                (e) => {
                    dispatch(getProductPageStatus(NetworkStatus.fail));
                    console.error('response error is', e);
                });
        }
        return () => {
            dispatch(setStatus(NetworkStatus.loading));
        }
    }, [itemId, token, initUser, initServer]);

    const toggleWishList = () => {
        setWishState(NetworkStatus.loading);
        MakeRequest(
            getUrl(apiRoutes.itemLike, server),
            'POST',
            {item: itemId},
            token,
            (response) => {
                dispatch(setIsLiked(response.data.data.isLiked));
                dispatch(setWishListSize(response.data.data.wishListSize));
                setWishState(NetworkStatus.idle);
            },
            (e) => {
                dispatch(getProductPageStatus(NetworkStatus.fail));
                setWishState(NetworkStatus.fail);
                console.error('response error is', e);
            });
    }


    const wishListButton = item.isLiked
        ? <button
            onClick={() => {toggleWishList()}}
            className="btn btn-outline-primary w-100 mb-2"
            type='button'
            data-toggle="button"
            disabled={wishState === NetworkStatus.loading}
        >{text[lang].product.wishlist}&nbsp;&nbsp;<Heart fill='white' stroke='rgb(255, 111, 97)' className={'heart-filled-icon-product'} /></button>
        : <button
            onClick={() => {toggleWishList()}}
            className="btn btn-outline-dark w-100 mb-2"
            type='button'
            data-toggle="button"
            disabled={wishState === NetworkStatus.loading}
        >{text[lang].product.wishlist}&nbsp;&nbsp;<i className="fe fe-heart"></i></button>
    ;

    const filterData = item?.filterValues?.map(
        (data, index) => {
            return <li key={'item-filter-data-' + index}>
                <strong className="text-body">{data.filterTitle}:</strong> {data.valueTitle}
            </li>;
        }
    ) ?? [];

    const [count, setCount] = useState("1");

    const selectCount = (e) => {
        setCount(e.target.value);
    }

    const addToCartClick = () => {
        const itemCount = parseInt(count);
        dispatch(addToCart({
            item: item,
            count: itemCount,
            total: itemCount * item.cost
        }));
    }

    if(status === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
            </Helmet>
            <Header />
            <FullPageLoader />
        </>;
    } else if (status === NetworkStatus.fail) {
        return <>
            <Helmet>
                <title>{text[lang].seo.loading.title}</title>
                <meta name="description" content={text[lang].seo.loading.description} />
            </Helmet>
            <Header />
            <div className="row justify-content-center mt-10">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center mt-10">
                    <div className="mb-7 fs-1">🙁</div>
                    <h2 className="mb-5">{text[lang].product.not_found.title}</h2>
                    <p className="mb-7 text-gray-500">
                        {text[lang].product.not_found.text1}
                        {text[lang].product.not_found.text2}
                    </p>
                    <Link
                      className="btn btn-dark"
                      to={routes.home}
                    >
                        {text[lang].product.not_found.action}
                    </Link>
                </div>
            </div>
        </>;
    }

    let itemDisplayImages = [];

    if (item.images.length < 1) {
        if (showFakeImages === "true") {
            itemDisplayImages.push(<img key='item-index-1' className="img-fluid mb-4" src={img1} alt="..."/>);
            itemDisplayImages.push(<img key='item-index-2' className="img-fluid mb-4" src={img2} alt="..."/>);
            itemDisplayImages.push(<img key='item-index-3' className="img-fluid mb-4" src={img3} alt="..."/>);
        } else {
            //TODO:: Добавляем плейсхолдер
            itemDisplayImages.push(<img key='item-index-1' className="img-fluid mb-4" src={img} alt="..."/>);
        }
    } else {
        itemDisplayImages = item.images.map(function(item, index) {
            return <img key={'item-index-' + index} className="img-fluid mb-4" src={item.full} alt="..."/>
        });
    }

    return <>
        <Helmet>
        <title>{text[lang].seo.product.title} {item.titles[lang] ?? item.titles['en'] ?? item.title}</title>
            <meta name="description" content={text[lang].seo.product.description} />
        </Helmet>
        <Header />
        <div className="content">
            <nav className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb mb-0 fs-xs text-gray-400">
                                {breadcrumb}
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    {itemDisplayImages}
                                </div>
                                <div className="col-12 col-md-6 ps-lg-10">
                                    <div className="position-sticky" style={{top: '62px'}}>
                                        <div className="row mb-1">
                                            <div className="col">
                                                <span className="text-muted">
                                                    {item?.category?.titles[lang] ?? item?.category?.titles['en'] ?? item?.category?.name}
                                                </span>
                                            </div>
                                            <div className="col-auto">

                                                {reviewsTotal > 0 ? <StarRatingDisplay score={avgScore} /> : null}
                                                <a className="fs-sm text-reset ms-2" href="#reviews">
                                                    {text[lang].product.reviews}{reviewsTotal > 0 ? <span> ( <span className='text-primary'>{reviewsTotal ?? 0}</span> )</span> : null}
                                                </a>
                                            </div>
                                        </div>
                                        <h3 className="mb-2">{item.titles[lang] ?? item.titles['en'] ?? item.title}</h3>
                                        <div className="mb-7">
                                            <span className="ms-1 fs-5 fw-bolder"><Money amount={item.cost ?? 0} /></span>
                                            <span className="fs-sm ms-1">(<span className={item.status.text_color}>
                                                {/*{text[lang].product.in_stock}*/}
                                                {item.status.label}
                                            </span>)</span>
                                        </div>
                                        <form className="mb-9">
                                            <ItemGroups />
                                            <div className="row gx-5 mb-7">
                                                <div className="col-12 col-lg-auto">
                                                    <select
                                                        value={count}
                                                        onChange={selectCount}
                                                        disabled={!item.status.can_add_to_cart}
                                                        className="form-select mb-2"
                                                    >
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-lg">
                                                    <button
                                                        type="button"
                                                        className="btn w-100 btn-success mb-2"
                                                        onClick={() => { addToCartClick(); }}
                                                        disabled={!item.status.can_add_to_cart}
                                                    >
                                                        {text[lang].product.add_to_cart} <i className="fe fe-shopping-cart ms-2"></i>
                                                    </button>
                                                </div>
                                                <div className="col-12 col-lg-auto">
                                                    {/*<button className="btn btn-outline-dark w-100 mb-2"*/}
                                                    {/*        data-toggle="button">*/}
                                                    {/*    Wishlist <i className="fe fe-heart ms-2"></i>*/}
                                                    {/*</button>*/}
                                                    {/*<button className="btn btn-outline-dark w-100 mb-2 text-danger"*/}
                                                    {/*        data-toggle="button">*/}
                                                    {/*    Wishlist <i className="fa fa-heart text-success ms-2"></i>*/}
                                                    {/*</button>*/}
                                                    {wishListButton}
                                                </div>

                                                { (item?.groups?.groups?.length ?? 0 > 1) ? <GroupsBlock groups={item?.groups?.groups} /> : null}

                                                {/*<p>*/}
                                                {/*    <span*/}
                                                {/*        className="text-gray-500">Is your size/color sold out?</span>*/}
                                                {/*    <a className="text-reset text-decoration-underline"*/}
                                                {/*       data-bs-toggle="modal" href="#modalWaitList">Join the Wait*/}
                                                {/*        List!</a>*/}
                                                {/*</p>*/}
                                            </div>
                                        </form>
                                        <div className="list-group list-group-sm list-group-flush-x"
                                             id="productCollapseParent">
                                            <div className="list-group-item">
                                                <a className="d-block dropdown-toggle text-body"
                                                   data-bs-toggle="collapse" href="#productCollapseDetails">
                                                    <strong>{text[lang].product.product_details}</strong>
                                                </a>
                                                <div className="collapse show" id="productCollapseDetails"
                                                     data-bs-parent="#productCollapseParent">
                                                    <div className="pt-5 fs-sm text-gray-500">
                                                        <p dangerouslySetInnerHTML={{__html: item?.texts[lang] ?? item.texts['en'] ?? item?.text}}>
                                                        </p>
                                                        <ul className="list-unstyled mb-0">
                                                            {filterData}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<SizeFitBlock />*/}
                                            {/*<ShippingBlock />*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SuggestItemList count={4} id={itemId} />
            <ReviewBlock itemId={itemId} />
            <InfoBlock />
        </div>
        <Footer />
    </>;
}

export default ProductPage;