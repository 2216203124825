import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import React, {Fragment} from "react";
import {Placeholder} from "react-bootstrap";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {
    changeFilterListValue, clearFilters,
    getCategoryStatus,
    getFilters, toggleMobileFilterList,
    updateFilterUpdateIndex
} from "../../../redusers/ItemsListReducer";
import {getLang} from "../../../redusers/InterfaceReducer";
import PriceFilter from "./Filters/PriceFilter";
import FilterType from "../../../helpers/FilterType";
import ListFilter from "./Filters/ListFilter";
import ColorFilter from "./Filters/ColorFilter";
import {text} from "../../../config/i18n/text";

export const FiltersList = () => {

    const filters = useItemListSelector(getFilters);
    const categoryStatus = useItemListSelector(getCategoryStatus);
    const dispatch = useAppDispatch();
    const lang = useAppSelector(getLang);
    const filtersData = useItemListSelector(getFilters);

    if(categoryStatus !== NetworkStatus.idle || filters.length === 0){
        return <Fragment>
            <section className="widget widget-categories mb-5">
                <Placeholder as={'h3'} animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder animation="glow">
                    <Placeholder xs={3} /><br/>
                    <Placeholder xs={2} /><br/>
                    <Placeholder xs={1} /><br/>
                    <Placeholder xs={4} /><br/>
                    <Placeholder xs={3} /><br/>
                    <Placeholder xs={4} /><br/>
                    <Placeholder xs={3} /><br/>
                </Placeholder>
            </section>
        </Fragment>
    }

    let displayFilters = [
            <PriceFilter key={'filter-price'} />
    ];

    Object.keys(filters).forEach(function(key) {
        switch (filters[key].type){
            case FilterType.LIST:
                displayFilters.push(<ListFilter
                    key={'list-filter-' + filters[key].id + '-id-' + key}
                    id={filters[key].id}
                    title={filters[key].title}
                    titles={filters[key].titles}
                    name={filters[key].name}
                    values={filters[key].values}
                    onChange={(filterId, valueId) => { dispatch(changeFilterListValue({filterId, valueId}));}}
                />);
                break;
            case FilterType.COLOR_LIST:
                displayFilters.push(<ColorFilter
                    key={'list-color-filter-' + filters[key].id + '-id-' + key}
                    id={filters[key].id}
                    title={filters[key].title}
                    titles={filters[key].titles}
                    name={filters[key].name}
                    values={filters[key].values}
                    onChange={(filterId, valueId) => { dispatch(changeFilterListValue({filterId, valueId}));}}
                />);
                break;
            default:
                displayFilters.push(<div key={'list-default-filter-' + filters[key].id + '-id-' + key}>DEFAULT</div>);
                break;
        }
    });
    console.log('filters is', filtersData);
    displayFilters.push(<button onClick={() => { dispatch(clearFilters()); }}
        key={'filter-clear-button'}
        type="button"
        className="btn btn-danger btn-small hvr-grow mb-2"
    >{text[lang].category.filters.clear}</button>);

    displayFilters.push(
        <button onClick={() => {
            dispatch(toggleMobileFilterList());
            dispatch(updateFilterUpdateIndex())
        }
        } key={'filter-button'} type="button" className="btn btn-dark btn-small hvr-grow">{text[lang].category.filters.filter}</button>
    );

    return displayFilters;
}
export default FiltersList;