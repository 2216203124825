import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {clearCart, getCart, getTotal} from "../../../redusers/CartReducer";
import {text} from "../../../config/i18n/text";
import Money from "../money/Money";
import CartItemBlock from "./CartItemBlock";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import {getUserToken} from "../../../redusers/UserReducer";
import {makeCheckout} from "../../../routes/routes";
import React, {useState} from "react";

export const CartBlock = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(getLang);
    const cartSize = useAppSelector(getTotal);
    const cart = useAppSelector(getCart);

    const server = useAppSelector(getServer);
    const token = useAppSelector(getUserToken);

    const navigate = useNavigate();

    const [state, setState] = useState(NetworkStatus.idle);

    //empty cart
    if(cartSize < 1){
        return <div className="offcanvas offcanvas-end" id="modalShoppingCart" tabIndex="-1" role="dialog" aria-hidden="true" >
            <div className="">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i className="fe fe-x" aria-hidden="true"></i>
                </button>
                <div className="offcanvas-header lh-fixed fs-lg">
                    <strong className="mx-auto">{text[lang].cart.title} ( <span className='text-warning'>0</span> )</strong>
                </div>
                <div className="offcanvas-body flex-grow-0 my-auto">
                    <h6 className="mb-7 text-center">{text[lang].cart.empty.text}</h6>
                    <Button
                        type='button'
                        className="btn w-100 btn-outline-dark"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        {text[lang].cart.empty.button_title_continue}
                    </Button>
                </div>
            </div>
        </div>;
    }

    const items = cart.items.map((item, index) => {
        return <CartItemBlock item={item} key={'cart-item-' + index} itemOrderId={index} />;
    });

    const CheckoutClickAction = () => {
        const data = {
            items: cart.items,
        };
        MakeRequest(getUrl(apiRoutes.orderCreate, server), 'post', data, token, (response) => {
            dispatch(clearCart());
            navigate(makeCheckout(response.data.id));

        }, (e) => {
            if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                setState(NetworkStatus.idle);
            } else {
                console.error('response error is', e);
                setState(NetworkStatus.fail);
            }
        });
        //navigate(routers.checkout);
    }

    return <div className="offcanvas offcanvas-end" id="modalShoppingCart" tabIndex="-1" role="dialog" aria-hidden="true">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="fe fe-x" aria-hidden="true"></i>
        </button>
        <div className="offcanvas-header lh-fixed fs-lg">
            <strong className="mx-auto">{text[lang].cart.title} ( <span className='text-warning'>{cartSize}</span> )</strong>
        </div>
        <ul className="list-group list-group-lg list-group-flush">
            {items}
        </ul>

        <div className="offcanvas-footer justify-between lh-fixed fs-sm bg-light mt-auto">
            <strong>{text[lang].cart.subtotal}</strong> <strong className="ms-auto"><Money amount={cart.total}/></strong>
        </div>

        <div className="offcanvas-body">
            <button
                type='button'
                className="btn w-100 btn-dark"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={CheckoutClickAction}
                disabled={state === NetworkStatus.loading}
            >{text[lang].cart.button_title_continue_to_checkout}
                {state === NetworkStatus.loading && <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>}
            </button>
            <button
                type='button'
                className="btn w-100 btn-outline-dark mt-2"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                disabled={state === NetworkStatus.loading}
            >{text[lang].cart.button_title_back_to_shopping}</button>
        </div>
    </div>;
}

export default CartBlock;