import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";
import {Link} from "react-router-dom";
import {makeItem} from "../../../../routes/routes";
import React from "react";

export const GroupDisplayInColor = (data) => {
    const lang = useAppSelector(getLang);

    return data.connections.map(function (listItem, listIndex) {
        const style = listItem.value === ':mixed:'
            ? {background: "conic-gradient(from 315deg, rgba(255,0,0,1), rgba(9,121,12,1), rgba(6,43,148,1), rgba(239,255,0,1), rgba(189,3,177,1))"}
            : { backgroundColor: listItem.value}
        ;
        return <div
            className="form-check form-check-inline form-check-size mb-2 color-block"
            key={'group-' + data.index + '-list-item-' + listIndex}
            style={style}
        >
            <Link
                title={listItem.titles[lang] ?? listItem.titles['en'] ?? listItem.title}
                to={makeItem(listItem.itemId)}
                className={['form-check-label', 'group-color-element', (data.current === listItem.value) && 'active'].join(' ')}
            >&nbsp;&nbsp;&nbsp;</Link>
        </div>;
    });
}

export default GroupDisplayInColor;