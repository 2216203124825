import {text} from "../../config/i18n/text";

export function validateEmail(email, lang)
{
    let valid = true;
    let error = '';

    if(email.length < 1){
        valid = false;
        error = text[lang].login.errors.email.short;
    }

    if(email.length > 255){
        valid = false;
        error = text[lang].login.errors.email.long;
    }

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( !re.test(email) ) {
        valid = false;
        error = text[lang].login.errors.email.invalid;
    }

    return {
        valid: valid,
        error: error,
    };
}

export default validateEmail;