
export function MakeGetParams(params){
    let paramNodes = [];
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            paramNodes.push(key + '=' + params[key]);
        }
    }
    return '?' + paramNodes.join('&');
}

export default MakeGetParams;