import AccountMenu from "../../parts/account/AccountMenu";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../parts/Header";
import Footer from "../../parts/Footer";
import {text} from "../../../config/i18n/text";
import {useAppDispatch, useAppSelector, useItemListSelector} from "../../../app/hooks";
import {getInitServer, getInitUser, getLang, getServer} from "../../../redusers/InterfaceReducer";
import buildForm,
    {makeFieldBlur, makeFieldFocus, makeValueChanged, triggerAllValidators} from "../../../helpers/form/FormBuild";
import fields from "../../models/checkout/AddressFormModel";
import {getAddressForm, setFormValue, setFormFocus, setFormBlur, loadAddress} from "../../../redusers/AddressReducer";
import {NetworkStatus} from "../../../helpers/network/NetworkStatus";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken} from "../../../redusers/UserReducer";
import {useLocation, useNavigate} from "react-router-dom";
import FullPageLoader from "../../parts/FulPageLoader";
import {setStatus} from "../../../redusers/ProductReducer";
import * as routers from "../../../routes/routes";

export const PersonalAddressPage = () => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const lang = useAppSelector(getLang);

    const form = useAppSelector(getAddressForm);

    const server = useAppSelector(getServer);
    const token = useAppSelector(getUserToken);

    const valueChanged = makeValueChanged(dispatch, setFormValue);
    const fieldFocus = makeFieldFocus(dispatch, setFormFocus);
    const fieldBlur = makeFieldBlur(dispatch, setFormBlur, lang, form);

    let location = useLocation();
    const addressId = location.pathname.replace('/account/address/','');

    const [state, setState] = useState(NetworkStatus.idle);
    const [loadingState, setLoadingState] = useState(NetworkStatus.loading);

    const initServer = useItemListSelector(getInitServer);
    const initUser = useItemListSelector(getInitUser);

    const displayForm = buildForm(fields, form, lang, valueChanged, fieldFocus, fieldBlur, state === NetworkStatus.loading);

    const saveAddressAction = () => {

        const formValid = triggerAllValidators(dispatch, setFormBlur, lang, fields, form);

        if(true === formValid) {
            setState(NetworkStatus.loading);
            const data = {
                id: addressId,
                name: form.values.name,
                company: form.values.company,
                country: form.values.country,
                line1: form.values.address1,
                line2: form.values.address2,
                city: form.values.city,
                zip: form.values.zip,
                defaultShipping: form.values.defaultShipping,
                defaultBilling: form.values.defaultBilling,
            };
            MakeRequest(getUrl(apiRoutes.addressUpdate, server), 'post', data, token, (response) => {
                console.info('address update - data from server', response.data.data);
                setState(NetworkStatus.idle);
                navigate(routers.accountAddresses);

            }, (e) => {
                if(400 === e.response.data.code && 'validation_fields' === e.response.data.type) {
                    setState(NetworkStatus.idle);
                } else {
                    console.error('address update response error is', e);
                    setState(NetworkStatus.fail);
                }
            });
        }

    }

    useEffect(() => {
        setLoadingState(NetworkStatus.loading);
        if(true === initUser && true === initServer){
            MakeRequest(
                getUrl(apiRoutes.addressRead, server) + '?id='+addressId,
                'GET',
                {},
                token,
                (response) => {
                    setLoadingState(NetworkStatus.idle);
                    dispatch(loadAddress(response.data.data));
                },
                (e) => {
                    setLoadingState(NetworkStatus.fail);
                    console.error('response error is', e);
                });
        }
        return () => {
            dispatch(setStatus(NetworkStatus.loading));
        }
    }, [addressId, token, initUser, initServer]);

    useEffect(() => {
        document.body.scrollTo({ top: 0 });
    }, [addressId]);

    if(loadingState === NetworkStatus.loading){
        return <>
            <Helmet>
                <title>{text[lang].seo.account.address_form.title}</title>
                <meta name="description" content={text[lang].seo.account.address_form.description} />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Header/>
            <FullPageLoader />
            <Footer />
        </>;
    }

    return <>
        <Helmet>
            <title>{text[lang].seo.account.address_form.title}</title>
            <meta name="description" content={text[lang].seo.account.address_form.description} />
            <meta name="robots" content="noindex" />
        </Helmet>
        <Header/>
        <section className="pt-7 pb-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mb-10">{text[lang].account.address_form.title}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <AccountMenu active={3} />
                    </div>
                    <div className="col-12 col-md-9 col-lg-8 offset-lg-1">
                        <form>
                            <div className="row">
                                {displayForm}
                            </div>
                            <button
                                className="btn btn-dark"
                                type="button"
                                disabled={state === NetworkStatus.loading}
                                onClick={saveAddressAction}>
                                {text[lang].account.address_form.button_title}
                                { state === NetworkStatus.loading && <span
                                    className="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span> }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>;
}

export default PersonalAddressPage;