import React from "react";
import {useAppSelector} from "../../../../app/hooks";
import {getLang} from "../../../../redusers/InterfaceReducer";

export const ListFilter = (data) => {
    const lang = useAppSelector(getLang);

    const values = Object.keys(data.values).map((e, i) => {
        return <div className="form-check mb-3" key={'item-list-filter-and-' + i}>
            <input
                className="form-check-input"
                id={'variant-' + data.values[e].id}
                type="checkbox"
                checked={data.values[e].value}
                onChange={() => {
                    data.onChange(data.id, e);
                }}
            />
            <label
                className="form-check-label"
                htmlFor={'variant-' + data.values[e].id}
            >
                {data.values[e].titles[lang] ?? data.values[e].titles['en'] ?? data.values[e].title }
            </label>
        </div>;
    });
    return <li className="nav-item">
        <a className="nav-link dropdown-toggle fs-lg text-reset border-bottom mb-6"
           data-bs-toggle="collapse" href={'#filter' + data.id}>
            {data?.titles[lang] ?? data?.titles['en'] ?? data.title}
        </a>
        <div className="collapse" id={'filter' + data.id} data-simplebar-collapse={'#filter' + data.id}>
            <div className="form-group form-group-overflow mb-6" id={'filter' + data.id}>
                {values}
            </div>
        </div>
    </li>
}

export default ListFilter;