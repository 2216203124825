export const serversEnvs = {
    local: 'local',
    dev: 'dev',
    prod: 'prod',
}

export const servers = {
    'local' : {
        host: 'localhost',
        port: '50070',
        schema: 'http',
    },
    'dev' : {
        host: 'api.demo.dekbed.u1tr0n.nl',
        port: 0,
        schema: 'https',
    },
    'prod' : {
        host: 'api.demo.dekbed.u1tr0n.nl',
        port: 0,
        schema: 'https',
    }
};

export const reviewsPageSize = 5;

export const showHeaderSearchButton = process.env.REACT_APP_SHOW_HEADER_SEARCH_BUTTON === 'true';
export const showHeaderAccountButton = process.env.REACT_APP_SHOW_HEADER_ACCOUNT_BUTTON === 'true';
export const showHeaderWishListButton = process.env.REACT_APP_SHOW_HEADER_WISHLIST_BUTTON === 'true';
export const showHeaderCartButton = process.env.REACT_APP_SHOW_HEADER_CART_BUTTON === 'true';
export const showHeaderPromo = process.env.REACT_APP_SHOW_HEADER_PROMO === 'true';

export const availableSelectLang = {
    en: process.env.REACT_APP_AVAILABLE_SELECT_LANG_EN === 'true',
    fr: process.env.REACT_APP_AVAILABLE_SELECT_LANG_FR === 'true',
    nl: process.env.REACT_APP_AVAILABLE_SELECT_LANG_NL === 'true',
    de: process.env.REACT_APP_AVAILABLE_SELECT_LANG_DE === 'true',
}



export const defaultServer = process.env.REACT_APP_DEFAULT_SERVER;
export const authKeyName = 'dbb_';
export const environment = process.env.REACT_APP_ENVIRONMENT;
export const sentryDNS = process.env.REACT_APP_ENVIRONMENT;
export const showFakeImages = process.env.REACT_APP_SHOW_FAKE_IMAGES;

export const texts = {
    name: 'Happy Wardrobe',
    logoAlt: 'Happy Wardrobe Logo',
    page: 'Page',
    goMainPage: 'Go main page',
    sellNow: 'Sell now',
    login: 'Sign Up / Log In',
    seo: {
        main: {
            title : 'Verkoop, ruil en koop fashion outfits, vintage kleding en accessoires',
            description : 'Verkoop, ruil en koop fashion outfits, vintage kleding en accessoires'
        }
    }
};

export const contacts = [
    {
        type: 'phone',
        title: '!+316 1300 7768',
        value: '+31613007768',
    },
    // {
    //     type: 'phone',
    //     title: '!1-202-555-0106',
    //     value: '12025550106',
    // },
    {
        type: 'email',
        title: '!dekbedbazaar@gmail.com',
        value: 'dekbedbazaar@gmail.com',
    }
];

export const socialLinksTypes = {
    link: 'link',
}
export const socialLinks = [
    {
        type: socialLinksTypes.link,
        name: 'Facebook',
        url: '#facebook',
        className: 'fab fa-facebook-f',
    },
    {
        type: socialLinksTypes.link,
        name: 'Youtube',
        url: '#youtube',
        className: 'fab fa-youtube',
    },
    {
        type: socialLinksTypes.link,
        name: 'Tweeter',
        url: '#tweeter',
        className: 'fab fa-twitter',
    },
    {
        type: socialLinksTypes.link,
        name: 'Instagram',
        url: '#instagram',
        className: 'fab fa-instagram',
    },
];
export function getUrl(path, server = null)
{
    if(null === server) {
        server = defaultServer;
    }

    let url = servers[server].schema + '://' + servers[server].host;
    if(servers[server].port > 0){
        url += ':' + servers[server].port;
    }
    url += path;

    return url;
}

export const promoInfo = {
    free_shipping_from: 49.95,
    return_period: 1,
    items_amount: '10,000',
};