import React from "react";
import {Link} from "react-router-dom";

export const HeaderPromoBlock = (data) => {
    return <div className="py-3 bg-dark bg-pattern ">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <Link to={data.to} className="text-center text-white">
                        <span className="heading-xxs letter-spacing-xl">
                            {data.text}️
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>;
}

export default HeaderPromoBlock;