import { createSlice } from '@reduxjs/toolkit';
import {authKeyName} from "../config/config";


const initialState = {
    items: [],
    status: 'new',
    total: 0,
    discount: null,
};

export const userSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, payload) => {
            state.items.push(payload.payload);
            state.total += payload.payload.total;
            let items = [];
            for(const i in state.items){
                items.push(state.items[i]);
            }
            const serializedState = JSON.stringify({
                items: items,
                status: state.status,
                total: state.total,
                discount: state.discount,
            });
            localStorage.setItem(authKeyName + 'cart', serializedState);
        },
        changeItemsCount: (state, payload) => {
            const id = payload.payload.itemOrderId
            if (state.items.hasOwnProperty(id)) {
                state.items[id].count = payload.payload.count;
                state.items[id].total = state.items[id].count * state.items[id].item.cost;
            }
            let total = 0;
            for (let i in state.items) {
                total += state.items[i].total;
            }
            state.total = total;

            const serializedState = JSON.stringify({
                items: state.items,
                status: state.status,
                total: state.total,
                discount: state.discount,
            });
            localStorage.setItem(authKeyName + 'cart', serializedState);
        },
        removeItem: (state, payload) => {
            const id = payload.payload.itemOrderId;
            if (state.items.hasOwnProperty(id)) {
                const items =  [...state.items];
                items.splice(id,1);
                state.items = items;

                let total = 0;
                for (let i in state.items) {
                    total += state.items[i].total;
                }
                state.total = total;
            }
            const serializedState = JSON.stringify({
                items: state.items,
                status: state.status,
                total: state.total,
                discount: state.discount,
            });
            localStorage.setItem(authKeyName + 'cart', serializedState);
        },
        loadCart: (state, payload) => {
            state.items = payload.payload.items;
            state.total = payload.payload.total;
            state.status = payload.payload.status;
            state.discount = payload.payload.discount;

        },
        clearCart: (state) => {
            const newState = {
                items: [],
                status: 'new',
                total: 0,
                discount: 0.0,
            };
            state.items = newState.items;
            state.status = newState.status;
            state.total = newState.total;
            state.discount = newState.discount;
            localStorage.setItem(authKeyName + 'cart', JSON.stringify(newState));
        },
    },
});

export const {
    addToCart,
    changeItemsCount,
    removeItem,
    loadCart,
    clearCart,
} = userSlice.actions;

export const getItems = (state) => state.cart.items;
export const getTotal = (state) => state.cart.items.length;

export const getCart = (state) => state.cart;

export default userSlice.reducer;