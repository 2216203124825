import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLang, getServer} from "../../../redusers/InterfaceReducer";
import {text} from "../../../config/i18n/text";
import {
    getPromoCode,
    promoCodeChange,
    setLoadStatus,
    setPromoActivate, setWrongPromo
} from "../../../redusers/CheckoutReducer";
import MakeRequest from "../../../helpers/network/MakeRequest";
import {getUrl} from "../../../config/config";
import {apiRoutes} from "../../../config/apiRoutes";
import {getUserToken} from "../../../redusers/UserReducer";
import {useLocation} from "react-router-dom";

export const PromoCodeBlock = () => {

    const dispatch = useAppDispatch();

    const server = useAppSelector(getServer);
    const token = useAppSelector(getUserToken);

    const lang = useAppSelector(getLang);
    const promo = useAppSelector(getPromoCode);

    const location = useLocation();
    const orderId = location.pathname.replace('/checkout/','');

    const errorBlock = promo.error === '' ? null : <div className='text-danger'>{promo.error}</div>

    const promoCodeFieldChange = (e) => {
        if(promo.status === 'not_loaded'){
            dispatch(promoCodeChange(e.target.value))
        }
    }
    const promoCodeBlur = () => {
        if (promo.status === 'not_loaded') {
            dispatch(setLoadStatus('loading'));
            const data = {
                orderId: orderId,
                promoCode: promo.code,
            };

            MakeRequest(getUrl(apiRoutes.orderPromoActivate, server),
            'post', data, token, (response) => {
                dispatch(setPromoActivate({
                    code: response.data.data.code,
                    id: response.data.data.id,
                    discount: response.data.data.discount,
                }));
            }, (e) => {
                if(400 === e.response.data.code) {
                    dispatch(setWrongPromo(e.response.data.message));
                } else {
                    console.error('response error is', e);
                    //setState(NetworkStatus.fail);
                    dispatch(setLoadStatus('loaded'));
                }
            });
        }
    }

    let button = null;

    if (promo.status === 'loading') {
        button = <div className="spinner-border spinner-border-sm text-success my-1" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    }

    if (promo.status === 'loaded') {
        button = <i className="fe fe-check text-success"></i>;
    }

    return <div className='mb-5'>
        <label
            className="form-label visually-hidden"
            htmlFor="Promocode"
        >{text[lang].checkout.promo_code_title}
        </label>
        <div className="input-group input-group-merge">
            <input
                className="form-control"
                id="Promocode"
                type="text"
                value={promo.code}
                onChange={promoCodeFieldChange}
                onBlur={promoCodeBlur}
                disabled={false}
                placeholder={text[lang].checkout.promo_code_placeholder}
            />
                <div className="input-group-append">
                    <div className="btn btn-outline-border">
                        {button}
                        {/*<i className="fe fe-check text-success"></i>*/}
                        {/*<i className="fe fe-search"></i>*/}
                        {/*<div className="spinner-border spinner-border-sm text-success my-1" role="status">*/}
                        {/*    <span className="visually-hidden">Loading...</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
        </div>

        {/*<input*/}
        {/*    className="form-control form-control-sm"*/}
        {/*    id="Promocode"*/}
        {/*    type="text"*/}
        {/*    value={promo.code}*/}
        {/*    onChange={promoCodeFieldChange}*/}
        {/*    onBlur={promoCodeBlur}*/}
        {/*    disabled={fieldDisabled}*/}
        {/*    placeholder={text[lang].checkout.promo_code_placeholder}*/}
        {/*/>*/}
        {errorBlock}
    </div>;
}

export default PromoCodeBlock;