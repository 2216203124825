import axios from "axios";



export function MakeRequest(url, method, data, token, success, fail, headers = {}){

    const config = {
        method: method,
        url: url,
        data: data,
        headers: headers
    };

    if(token !== '' && token !== null){
        config.headers = {
            Authorization: `Bearer ${token}`
        }
    }

    axios.request(config)
        .then(async function (response) {
            success(response);

        })
        .catch(function (error) {
            console.error(error);
            fail(error);
        });
}

export default MakeRequest;